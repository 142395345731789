import * as React from 'react'
import styled from 'theme/styled-components'

import Icon from 'components/icons/Icon'
import useI18n from 'i18n/useI18n'

interface Props {
  nameIcon: IconName
  sizeIcon: number
  onClick: () => void
}

const CloseModalButton = ({ nameIcon, sizeIcon, onClick }: Props) => {
  const i18n = useI18n()
  return (
    <StickToEnd>
      <CrossButton onClick={onClick} aria-label={i18n.t(`accessibility.ariaLabels.closeModal`)} tabIndex={0}>
        <Icon name={nameIcon} size={sizeIcon} cursor="pointer" />
      </CrossButton>
    </StickToEnd>
  )
}

export default CloseModalButton

const StickToEnd = styled('div')`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const CrossButton = styled('button')`
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
`
