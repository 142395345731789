import React from 'react'
import styled from 'theme/styled-components'
import Provider from 'theme/Provider'

import Loader from 'components/status/Loader'

import MainLayout from './layout/MainLayout'
import OfflineScreen from 'offline/OfflineScreen'

import { persist } from 'store/store'
import useOffline from 'offline/useOffline'

const App = () => {
  const [rehydrated, setRehydrated] = React.useState(false)

  const status = useOffline()

  React.useEffect(() => {
    persist().then(() => setRehydrated(true))
  }, [])

  if (!rehydrated) {
    return (
      <Provider>
        <CenterContainer>
          <Loader />
        </CenterContainer>
      </Provider>
    )
  }

  return <Provider>{status === 'online' ? <MainLayout /> : <OfflineScreen />}</Provider>
}

export default App

const CenterContainer = styled('div')`
  flex: 1;
  align-items: center;
  justify-content: center;
`
