import * as React from 'react'

import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

import * as SiteStore from 'site/store'
import useReducer from 'store/useReducer'

import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main, { HEADER_HEIGHT } from 'components/main/Main'
import Animator from 'components/animation/Animator'

import screens from 'core/src/layout/Screens'
import useNavigation from 'core/src/layout/useNavigation'

import { breakpoints } from 'utils/breakpoints'

import { Redirect } from 'react-router-dom'

const MeetingIntermediateScreen = () => {
  const i18n = useI18n()
  const currentSite = useReducer(SiteStore.store, (s) => s.site)
  const navigation = useNavigation()

  const isBooking = currentSite?.functionalities.find((f) => f.type === 'BOOKING' && f.activated)
  const isCatering = currentSite?.functionalities.find((f) => f.type === 'CATERING' && f.activated)

  if (!isBooking && isCatering) {
    return <Redirect to={screens['catering'].path} />
  }
  if (isBooking && !isCatering) {
    return <Redirect to={screens['booking'].path} />
  }

  const renderItem = (item: ScreenDefinition) => (
    <ItemContainer key={item.name} onClick={() => navigation.push(item.path)}>
      <ItemTitle>{i18n.t(`screens.meeting.${item.name}.title`)}</ItemTitle>
      <Image src={require(`core/src/assets/meeting_${item.name}.jpeg`).default} />
    </ItemContainer>
  )

  return (
    <ScreenContainer>
      <TitleHelmet title={i18n.t('screens.meeting.catering.title')} />
      <Main>
        <MainContainer>
          <Animator>
            <Title>{i18n.t('screens.meeting.title')}</Title>
            <OptionsContainer>
              {renderItem(screens['booking'])}
              {renderItem(screens['catering'])}
            </OptionsContainer>
          </Animator>
        </MainContainer>
      </Main>
    </ScreenContainer>
  )
}

export default MeetingIntermediateScreen

const ScreenContainer = styled.div`
  background-color: ${(props) => props.theme.colors.contentBackground};
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow: auto;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    overflow-x: hidden;
    position: relative;
    width: 100vw;
  }
`

const MainContainer = styled.div`
  padding: 50px 165px;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 50px 90px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const Title = styled.h1`
  ${(props) => props.theme.fonts.h2Bold};
`

const OptionsContainer = styled.div`
  display: grid;
  list-style: none;
  padding: 16px 0px;
  margin: 0;
  grid-template-columns: repeat(auto-fit, 330px);
  gap: 20px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`

const ItemContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  max-height: 220px;
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 1px 15px 0px ${(props) => props.theme.colors.transparentGrey};

  :hover {
    cursor: pointer;
  }
`

const ItemTitle = styled('p')`
  ${(props) => props.theme.fonts.h2};
  text-align: center;
  margin: 12px 0px;
`

const Image = styled('img')`
  height: 155px;
  width: 300px;
  object-fit: cover;
`
