import * as React from 'react'
import styled from 'styled-components'

import DatePickerModal from 'components/picker/DatePickerModal'
import TimePickerModal from 'components/picker/TimePickerModal'

import useI18n from 'i18n/useI18n'

import * as GuestStore from './store'
import useReducer from 'store/useReducer'

import { breakpoints } from 'utils/breakpoints'
import { notWeekend } from 'meeting/booking/utils'

import {
  addDays,
  addMinutes,
  differenceInMinutes,
  setHours,
  setMinutes,
  isAfter,
  startOfDay,
  endOfDay,
  min,
  max,
  subMinutes,
} from 'date-fns'

import { Values, SetFieldValueType, HandleChangeType } from './GuestScreen'

interface Props {
  values: Values
  setFieldValue: SetFieldValueType
  handleChange: HandleChangeType
}

const GuestForm = ({ values, setFieldValue, handleChange }: Props) => {
  const i18n = useI18n()
  const now = React.useMemo(() => new Date(), [])

  const subjectRef = React.useRef<HTMLTextAreaElement>(null)

  const duration = React.useMemo(() => differenceInMinutes(values.end, values.start), [values])

  const userDetails = useReducer(GuestStore.store, (s) => s.userDetail)

  const minRange = notWeekend(now)
  const maxRange =
    userDetails && userDetails.visitScheduledLimit ? addDays(now, userDetails.visitScheduledLimit) : undefined

  React.useEffect(() => {
    /* Resize subject input height according to content */
    if (subjectRef && subjectRef.current) {
      subjectRef.current.style.height = '0px'
      const scrollHeight = subjectRef.current.scrollHeight
      subjectRef.current.style.height = scrollHeight + 'px'
    }
  }, [values.subject])

  const openStartDatePicker = () => {
    DatePickerModal.open({
      date: values.start,
      onDateSelected: (next) => {
        setFieldValue('start', setMinutes(setHours(next, values.start.getHours()), values.start.getMinutes()))
        if (isAfter(next, values.end)) {
          setFieldValue('end', new Date(next.setHours(values.end.getHours(), values.end.getMinutes())))
        }
      },
      min: minRange,
      max: maxRange,
      title: i18n.t('screens.guest.visitInfo.startDate.label'),
    })
  }

  const openEndDatePicker = () => {
    DatePickerModal.open({
      date: values.end,
      onDateSelected: (next) => {
        setFieldValue('end', setMinutes(setHours(next, values.end.getHours()), values.end.getMinutes()))
        if (isAfter(values.start, next)) {
          setFieldValue('start', new Date(next.setHours(values.start.getHours(), values.start.getMinutes())))
        }
      },
      min: minRange,
      max: maxRange,
      title: i18n.t('screens.guest.visitInfo.endDate.label'),
    })
  }

  const openStartTimePicker = () =>
    TimePickerModal.open({
      time: values.start,
      onTimeSelected: (next: Date) => {
        // if next start is after end, we change end
        if (isAfter(next, values.end)) {
          setFieldValue('end', min([endOfDay(next), addMinutes(next, duration)]))
        }

        setFieldValue('start', next)
      },
      title: i18n.t('screens.guest.visitInfo.startTime.label'),
    })

  const openEndTimePicker = () =>
    TimePickerModal.open({
      time: values.end,
      onTimeSelected: (next: Date) => {
        // next is a before start, we change start
        if (isAfter(values.start, next)) {
          setFieldValue('start', max([startOfDay(values.end), subMinutes(next, duration)]))
        }

        setFieldValue('end', next)
      },
      title: i18n.t('screens.guest.visitInfo.endTime.label'),
    })

  const renderInput = (valueName: string, displayedValue: string, onClick: () => void) => (
    <InputButton onClick={onClick}>
      <InputLabel>{i18n.t(`screens.guest.visitInfo.${valueName}.label`)}</InputLabel>
      <InputValue>{displayedValue}</InputValue>
    </InputButton>
  )

  return (
    <MainContainer>
      <Title>{i18n.t('screens.guest.visitInfo.title')}</Title>

      <FormTopContainer>
        {/* Date d'arrivée */}
        <InputDateContainer>
          {renderInput(
            'startDate',
            i18n.t(`screens.guest.visitInfo.date`, { date: values.start }),
            openStartDatePicker
          )}
        </InputDateContainer>

        {/* Heure d'arrivée */}
        <InputTimeContainer>
          {renderInput(
            'startTime',
            i18n.t(`screens.guest.visitInfo.time`, { time: values.start }),
            openStartTimePicker
          )}
        </InputTimeContainer>
      </FormTopContainer>

      <FormTopContainer>
        {/* Date de départ */}
        <InputDateContainer>
          {renderInput('endDate', i18n.t(`screens.guest.visitInfo.date`, { date: values.end }), openEndDatePicker)}
        </InputDateContainer>

        {/* Heure de départ */}
        <InputTimeContainer>
          {renderInput('endTime', i18n.t(`screens.guest.visitInfo.time`, { time: values.end }), openEndTimePicker)}
        </InputTimeContainer>
      </FormTopContainer>

      {/* Motif */}
      <InputLabel>
        <label htmlFor="idMeetingNameInput">{i18n.t(`screens.guest.visitInfo.meetingName`)}</label>
      </InputLabel>
      <SubjectInput
        id="idMeetingNameInput"
        value={values.subject}
        onChange={handleChange}
        placeholder={i18n.t('screens.guest.visitInfo.meetingNamePlaceholder')}
        required
        name="subject"
        ref={subjectRef}
        spellCheck={false}
        autoCorrect="off"
        autoCapitalize="off"
        title={i18n.t('accessibility.titleInputText.purposeVisit')}
        aria-describedby={i18n.t('common.accessibility.purposeVisit')}
      />
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  max-width: 385px;
  padding: 0px 0px 15px 0px;
`
const InputDateContainer = styled('div')`
  flex-direction: row;
  margin: 0px 15px 20px 0px;
`
const InputTimeContainer = styled('div')`
  flex-direction: row;
  width: fit-content;
  margin: 0px 15px 20px 0px;
  @media only screen and (max-width: ${breakpoints.phone}px) {
    margin-bottom: 20px;
  }
`
const FormTopContainer = styled('div')`
  flex-direction: row;
  @media only screen and (max-width: ${breakpoints.phone}px) {
    flex-direction: column;
  }
`

// TEXTES

const Title = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px 0px 20px 0px;
`
const InputLabel = styled('h3')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.secondary};
  margin: 0px 0px 10px 0px;
  display: flex;
  flex-direction: flex-start;
`
const InputValue = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
  display: flex;
  text-transform: capitalize;
`

// BUTTONS

const InputButton = styled('button')`
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
  padding: unset;
`

// AUTRES

const SubjectInput = styled('textarea')`
  border: 0;
  outline: 0;

  ${(props) => props.theme.fonts.body};
  resize: none;
  height: 22px;
  min-height: 22px;
`

export default GuestForm
