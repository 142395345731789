import * as React from 'react'
import styled from 'theme'
import { keyframes } from 'styled-components'
import { breakpoints } from 'utils/breakpoints'

interface Props {
  image: string
  minWidth: number
  maxWidth: number
}

const ImageItem = ({ image, minWidth, maxWidth }: Props) => {
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    const img = new window.Image()
    img.src = image
    img.onload = () => setTimeout(() => setLoaded(true), 0)
  }, [])

  return (
    <ImageContainer>
      {loaded ? (
        <ItemImage src={image} alt="" min_width={minWidth} max_width={maxWidth} />
      ) : (
        <Skeleton min_width={minWidth} max_width={maxWidth} />
      )}
    </ImageContainer>
  )
}

export default ImageItem

const ImageContainer = styled.div``

const loadingAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`

const Skeleton = styled.div<{ min_width: number; max_width: number }>`
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: ${loadingAnimation} 1.5s infinite;
  min-width: ${(props) => props.min_width}px;
  max-width: ${(props) => props.max_width}px;

  height: auto;
  aspect-ratio: 4/3;
  object-fit: cover;
  border-radius: 12px;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: 100%;
  }
`

const ItemImage = styled('img')<{ min_width: number; max_width: number }>`
  min-width: ${(props) => props.min_width}px;
  max-width: ${(props) => props.max_width}px;

  height: auto;
  aspect-ratio: 4/3;
  object-fit: cover;
  border-radius: 12px;
  margin-bottom: 16px;

  :hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: 100%;
  }
`
