import ws from 'utils/Webservice'

const api = {
  all: (lang: string) => {
    return ws<SitesV5>(
      '/sites',
      'GET',
      {},
      {
        'Accept-Language': lang,
      }
    )
  },
}

export default api
