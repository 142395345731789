import * as React from 'react'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'

import toast, { Toaster } from 'react-hot-toast'

interface ToastProps {
  label: string
  icon?: IconName
}

export const showToast = ({ label, icon }: ToastProps) => {
  toast(label, { icon: <Icon name="pin" size={32} /> })
}

const Toast = () => {
  const [theme] = useTheme()

  return (
    <Toaster
      toastOptions={{
        duration: 5000,
        style: {
          background: theme.colors.background,
          alignSelf: 'center',
          flexDirection: 'row',
          marginTop: 64,
          minWidth: 360,
          ...theme.fonts.content,
        },
      }}
    />
  )
}

export default Toast
