import * as React from 'react'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import { clearHtmlStyle, getHtmlStyle } from 'utils/stringUtils'
import { getDateLabel } from './utils'

import ListItemRework from 'components/list/ListItemRework'

interface Props {
  article: ArticleV4
}

const NewsItem = ({ article }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const dateLabel = getDateLabel(article, i18n)
  const locationLabel = !!article.location ? ` - ${article.location}` : ''

  const photo = article.photo && article.photo.sort((a, b) => a.position - b.position)[0]

  // Suppression du style présent dans le HTML
  const resume = React.useMemo(() => getHtmlStyle(Theme.fonts.bodyBold) + clearHtmlStyle(article.resume), [Theme])

  return (
    <ListItemRework
      key={article.id}
      link={`/news/${article.id}`}
      title={article.title}
      ariaLabel={i18n.t('accessibility.ariaLabels.news.goToNews', { newsTitle: article.title })}
      dateLabel={dateLabel}
      description={resume}
      imageUrl={photo?.url}
      locationLabel={locationLabel}
      tag={i18n.t(`tags.${article.tag}`)}
      themeColor={Theme.colors.functionalities.news}
    />
  )
}

export default NewsItem
