import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'

interface Props {
  label: string
  icon: IconName
  color?: string
  iconColor?: string
  onClick?: () => void
  disabled?: boolean
  focusable?: boolean
  ariaLabel?: string
  fontSize?: number
  margin?: number
}

const IconLabel = ({
  label,
  icon,
  color,
  iconColor,
  onClick,
  disabled,
  focusable = false,
  ariaLabel,
  fontSize,
  margin,
}: Props) => {
  const [Theme] = useTheme()

  return (
    <Container
      disabled={disabled}
      active={!disabled && !!onClick}
      onClick={disabled ? undefined : onClick}
      tabIndex={focusable ? 0 : -1}
      aria-label={ariaLabel}>
      <Icon name={icon} color={iconColor || Theme.colors.primary} size={16} />
      <Label color={color || Theme.colors.primaryText} fontSize={fontSize} margin={margin}>
        {label}
      </Label>
    </Container>
  )
}

export default IconLabel

const Container = styled('button')<{ active: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  cursor: ${(props) => (props.active ? 'pointer' : 'default')};
  background-color: transparent;
  border: 0px;
`

const Label = styled('p')<{ color: string; fontSize?: number; margin?: number }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize || 16}px;
  margin: 0px 0px 0px ${(props) => props.margin || 12}px;
  text-align: left;
`
