import { createEvent, createStore } from 'effector'

export const actions = {
  setTheme: createEvent<'classic' | 'accessible'>('setTheme'),
  setDrawer: createEvent<DrawerBehavior>('setDrawer'),
  reset: createEvent('reset'),
}

interface ThemeState {
  theme: 'classic' | 'accessible'
  drawer: DrawerBehavior
}

const initialState: ThemeState = {
  theme: 'classic',
  drawer: 'default',
}

export const store = createStore<ThemeState>(initialState, { name: 'theme' })
  .on(actions.setTheme, (s, theme) => ({ ...s, theme }))
  .on(actions.setDrawer, (s, drawer) => ({ ...s, drawer }))
  .reset(actions.reset)
