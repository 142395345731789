import { createEvent, createStore } from 'effector'

export interface OfficeState {
  reservations: Schedules['schedules']
  search?: {
    start: string
    end: string
  }
}

export const actions = {
  setReservations: createEvent<Schedules['schedules']>('setReservations'),
  resetReservatons: createEvent('resetReservations'),
  setSearch: createEvent<{ start: string; end: string }>('setSearch'),
  resetSearch: createEvent('resetSearch'),
}

const initialState: OfficeState = {
  reservations: [],
  search: undefined,
}

export const store = createStore<OfficeState>(initialState, { name: 'booking' })
  .on(actions.setReservations, (s, reservations) => ({ ...s, reservations }))
  .on(actions.resetReservatons, (s) => ({ ...s, reservations: initialState.reservations }))
  .on(actions.setSearch, (s, search) => ({ ...s, search }))
  .on(actions.resetSearch, (s) => ({ ...s, search: initialState.search }))
