import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icons from 'components/icons/Icon'
import Switch from 'components/button/Switch'
import Modal from 'components/modal/Modal'
import Picker from 'components/picker/PickerModal'
import CloseModalButton from 'components/button/CloseModalButton'

import useI18n from 'i18n/useI18n'

import { breakpoints } from 'utils/breakpoints'
import Icon from 'components/icons/Icon'

interface Props {
  modes: InterCampusRoute[]
  mode?: InterCampusRoute
  onMode: (mode: InterCampusRoute) => void
}

const VELO = ['VELO', 'VELO_ELECTRIQUE']
const VOITURE = ['VOITURE_DIESEL', 'VOITURE_ESSENCE', 'VOITURE_ELECTRIQUE', 'VOITURE_HYBRIDE']

const MobilitySelect = ({ modes, mode, onMode }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const HTML_STYLE = React.useMemo(
    () => `
<style>
  p {
    display: inline;
  }
  .bold {
    font-family: ${theme.fonts.bodyBold.fontFamily};
    font-weight: ${theme.fonts.bodyBold.fontWeight};
  }
</style>`,
    [theme]
  )

  const HTML_TEXT = React.useMemo(
    () =>
      i18n.lang === 'fr'
        ? `<p>La différence entre les émissions de la voiture électrique et de la voiture hybride <p style="text-decoration: underline">rechargeable</p> : l’ADEME définit un facteur d’émission de <p class="bold">0,0733 kgCO<sub>2</sub>e/km</p> pour la voiture hybride rechargeable de type « Véhicule compact/Cœur de gamme » et déclare que pour « une utilisation en "cycle urbain", il est considéré que l’ensemble des trajets est réalisé uniquement en mode électrique (combustion = 0 kgCO<sub>2</sub>e) ». La batterie des véhicules hybrides rechargeables étant plus légère que celle des véhicules électriques, ce mode consomme moins pour une utilisation en milieu urbain. En comparaison, le facteur d’émission de la voiture électrique est de <p class="bold">0,103 kgCO<sub>2</sub>e/km</p>.</p>`
        : `<p>Difference between the emissions of electric and <p style="text-decoration: underline">rechargeable</p> hybrid cars: ADEME defines an emission factor of <p class="bold">0,0733 kgCO<sub>2</sub>e/km</p> for a rechargeable hybrid car of the “Compact/Mid-range vehicle” type and states that, “for ‘urban cycle’ use, it is assumed that all journeys are completed in electric mode only (combustion = 0 kgCO<sub>2</sub>e)”. Because rechargeable hybrid vehicle batteries are lighter than those of electric vehicles, this mode consumes less when driving in an urban environment. In comparison, the emission factor of an electric car is <p class="bold">0,103 kgCO<sub>2</sub>e/km</p>.</p>`,
    [i18n.lang]
  )

  const openTypePicker = (category: 'bike' | 'car', data: InterCampusRoute[]) => {
    Picker.open({
      title: i18n.t(`screens.intercampus.types.${category}`),
      data: data
        .sort((a, b) => a.co2Emission - b.co2Emission)
        .map((m) => ({
          label: i18n.t(`screens.intercampus.modes.${m.transportMode}`),
          value: m.transportMode,
        })),
      renderAdditionalData: (value: string) => {
        const co2 = data.find((d) => d.transportMode === value)?.co2Emission
        if (co2 !== undefined) {
          return (
            <Line>
              <Icons name="mobility_co2" size={12} color={theme.colors.co2} />
              <Co2>{i18n.t('screens.intercampus.carbone', { count: co2.toFixed(0) })}</Co2>
            </Line>
          )
        }
        return <></>
      },
      onItemSelected: (val) => {
        const selected = modes.find((m) => m.transportMode === val)

        if (selected) {
          onMode(selected)
        }
      },
    })
  }

  const openInfo = () => {
    Modal.open({
      Content: () => (
        <InfoContainer>
          <CloseModalButton nameIcon="cross" sizeIcon={30} onClick={Modal.close} />
          <InfoText dangerouslySetInnerHTML={{ __html: HTML_STYLE + HTML_TEXT }} />
        </InfoContainer>
      ),
    })
  }

  const renderSelect = (selected: InterCampusRoute) => {
    if (!mode) {
      return null
    }

    if (mode.transportMode === 'PIETON' || mode.transportMode === 'PIETON_PMR') {
      // select is PMR switch
      const itemPietonPMR = modes.find((m) => m.transportMode === 'PIETON_PMR')
      const itemPieton = modes.find((m) => m.transportMode === 'PIETON')

      if (itemPieton && itemPietonPMR) {
        const active = selected.transportMode === 'PIETON_PMR'

        return (
          <SelectContainer>
            <Select onClick={() => (active ? onMode(itemPieton) : onMode(itemPietonPMR))}>
              <PmrContainer>
                <Icon name="pmr" size={20} color={theme.colors.primaryText} />
                <SelectInfo>{i18n.t(`screens.intercampus.pmr`)}</SelectInfo>
              </PmrContainer>

              <Switch active={active} onClick={() => (active ? onMode(itemPieton) : onMode(itemPietonPMR))} />
            </Select>
          </SelectContainer>
        )
      }
    }

    if (VELO.includes(mode.transportMode)) {
      // select is a dropdown

      return (
        <SelectContainer>
          <Select
            onClick={() =>
              openTypePicker(
                'bike',
                modes.filter((m) => VELO.includes(m.transportMode))
              )
            }>
            <SelectInfo>
              {i18n.t(`screens.intercampus.modes.${mode?.transportMode || modes[0].transportMode}`)}
            </SelectInfo>
            <Icons name="chevron_bottom" size={17} />
          </Select>
        </SelectContainer>
      )
    }

    if (VOITURE.includes(mode.transportMode)) {
      // select is a dropdown

      return (
        <SelectContainer>
          <Select
            onClick={() =>
              openTypePicker(
                'car',
                modes.filter((m) => VOITURE.includes(m.transportMode))
              )
            }>
            <SelectInfo>
              {i18n.t(`screens.intercampus.modes.${mode?.transportMode || modes[0].transportMode}`)}
            </SelectInfo>
            <Icons name="chevron_bottom" size={17} />
          </Select>

          {!!mode && ['VOITURE_ELECTRIQUE', 'VOITURE_HYBRIDE'].includes(mode.transportMode) && (
            <IconContainer onClick={openInfo}>
              <Icons name="info" size={24} color={theme.colors.primaryDark} />
            </IconContainer>
          )}
        </SelectContainer>
      )
    }

    return null
  }

  if (!!mode && modes.length > 0) {
    return renderSelect(mode)
  }

  return null
}

export default MobilitySelect

const SelectContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.background};
  margin: 10px 0;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const Select = styled('div')`
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  padding: 8px 12px;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  :hover {
    cursor: pointer;
  }
`

const SelectInfo = styled('div')`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.primaryText};
  margin-right: 10px;
`

const Line = styled('div')`
  flex-direction: row;
  align-items: center;
  margin-left: 12px;
`

const Co2 = styled('div')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.iconicGrey};
  margin-left: 3px;
`

const IconContainer = styled('div')`
  margin-left: 20px;
`

// INFO MODAL

const InfoContainer = styled('div')`
  width: 400px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  padding: 40px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    width: 240px;
  }
`

const InfoText = styled('div')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryText};
  text-align: center;
  margin-top: 20px;
  display: inline-block;
`

const PmrContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;
`
