import * as React from 'react'

import { motion } from 'framer-motion'
import styled from 'styled-components'

import { breakpoints } from 'utils/breakpoints'

interface Props {
  children?: (JSX.Element | undefined | null | boolean)[]
  animation?: 'fade_bottom' | 'fade_right' | 'fade_top' | 'fade'
  isList?: boolean
  gap?: number
}

const Animators = ({ children = [], animation = 'fade_bottom', isList = false, gap }: Props) => {
  const items = children.filter(Boolean)

  return (
    <motion.div key={items.length} variants={Overlay} initial="hidden" animate="show">
      {isList ? (
        <StyledList gap={gap}>
          {items.map((child, idx) => (
            <li key={`anim_${idx}`}>
              <Animation animation={animation}>{child}</Animation>
            </li>
          ))}
        </StyledList>
      ) : (
        <>
          {items.map((child, idx) => (
            <Animation key={`anim_${idx}`} animation={animation}>
              {child}
            </Animation>
          ))}
        </>
      )}
    </motion.div>
  )
}

export default Animators

const Overlay = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const ItemFade = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
}

const ItemFadeBottom = {
  hidden: { opacity: 0, y: 100 },
  show: { opacity: 1, y: 0 },
}

const ItemFadeRight = {
  hidden: { opacity: 0, x: 100 },
  show: { opacity: 1, x: 0 },
}

const ItemFadeTop = {
  hidden: { opacity: 0, y: -100 },
  show: { opacity: 1, y: 0 },
}

// Affichage sur 2 colonnes si possible
const StyledList = styled('ul')<{ gap?: number }>`
  display: grid;
  list-style: none;
  margin: 0px -24px 0px 0px;
  padding: 0;
  grid-template-columns: repeat(2, 1fr);
  ${(props) => props.gap && `gap: ${props.gap}px`};
  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    display: flex;
    flex-direction: column;
  }
`

interface AnimationProps {
  children?: any
  animation?: 'fade_bottom' | 'fade_right' | 'fade_top' | 'fade'
}

export const Animation = ({ animation = 'fade_bottom', children }: AnimationProps) => {
  switch (animation) {
    case 'fade':
      return <motion.div variants={ItemFade}>{children}</motion.div>
    case 'fade_bottom':
      return <motion.div variants={ItemFadeBottom}>{children}</motion.div>
    case 'fade_top':
      return <motion.div variants={ItemFadeTop}>{children}</motion.div>
    case 'fade_right':
      return <motion.div variants={ItemFadeRight}>{children}</motion.div>
  }
}

export const SimpleAnimation = ({ animation = 'fade_bottom', children }: AnimationProps) => (
  <motion.div variants={Overlay} initial="hidden" animate="show">
    <Animation animation={animation}>{children}</Animation>
  </motion.div>
)
