import * as React from 'react'
import styled from 'theme/styled-components'

import MapSelector from './MapSelector'
import MapModal from './MapModal'
import Alert from 'components/alert/Alert'
import Main from 'components/main/Main'

import useMap from './useMap'

import useI18n from 'i18n/useI18n'

import { getFloorFromFloorId, sortFloorById } from './utils'
import { PoiMap, RouteAction } from './types'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

const DESK_LABELS_NOT_DISPLAYED = [
  'bureau flex',
  'bureau non flex ouvert',
  'bureau non flex fermé',
  'bureau fermé',
  'bureau préventeur',
  'bureau non flex',
]

interface Props {
  hash: string
  token: string
  pois: Referentiel[]
  occupancies: OccupancyList
  newOccupancies: OccupancyList
  location?: string
}

const Map = ({ hash, token, pois, occupancies, newOccupancies, location }: Props) => {
  const i18n = useI18n()

  const [ref, status, building, floor, place, buildings, nav, actions] = useMap(hash, i18n.lang, token, location)

  const [mapIsInitialized, setMapIsInitialized] = React.useState(false)

  const currentBuilding = React.useMemo(() => buildings.find((b) => b.id === building), [building, buildings])
  const currentFloor = React.useMemo(() => currentBuilding && currentBuilding.floors.find((f) => f.id === floor), [
    currentBuilding,
    floor,
  ])

  const filteredPois = React.useMemo(() => pois.filter((p) => p.searchable), [pois])

  React.useEffect(() => {
    if (status === 'initialized') {
      setTimeout(() => setMapIsInitialized(true), 200)
    }
  }, [status])

  // Liste des POIs filtrés selon l'étage sélectionné
  const floorPois = React.useMemo(() => {
    if (!!floor) {
      const floorString = getFloorFromFloorId(floor)
      return pois.filter((p) => p.floor?.toUpperCase() === floorString)
    }
    return []
  }, [pois, floor])

  const poi = React.useMemo(() => pois.find((p) => place && p.reference.includes(place)), [place, pois])

  const computeRoute = (action: RouteAction) => {
    if (action.action === 'start') {
      actions.computeRoute(action.from, action.to, action.pmr, i18n.lang).catch(() => {
        Alert.open({
          title: i18n.t('screens.map.route.noRouteTitle'),
          description: i18n.t('screens.map.route.noRoute'),
        })
      })
    } else if (action.action === 'previous') {
      actions.previousRoute()
    } else if (action.action === 'next') {
      actions.nextRoute()
    } else {
      actions.stopRoute()
    }

    return Promise.resolve()
  }

  React.useEffect(() => {
    actions.setAvailable(newOccupancies)
  }, [newOccupancies])

  React.useEffect(() => {
    const poisMap = floorPois.reduce((acc, cur) => {
      const rt = cur.referencialType
      if (!!rt && !DESK_LABELS_NOT_DISPLAYED.includes(rt.toLowerCase())) {
        const name = (cur.data && cur.data[`libelle_${i18n.lang}`]) || cur.room

        if (name) {
          acc[cur.reference] = name
        }
      }
      return acc
    }, {} as PoiMap)

    actions.updatePOIs(poisMap)
  }, [floorPois, status])

  const floors = React.useMemo(() => (currentBuilding ? currentBuilding.floors.sort(sortFloorById) : []), [
    currentBuilding,
  ])

  const onPlaceSelected = (item?: Referentiel) => {
    if (!!item) {
      analytics.event({
        event_feature: values.eventName.map,
        event_action: values.actions.clickFromList,
        event_object_id: item.reference,
      })
      actions.goTo(item.building || '', item.floor, item.reference)
    } else {
      actions.resetSelection()
    }
  }

  return (
    <Main>
      <ScreenContainer>
        <MapContainer ref={ref} />
        {mapIsInitialized && (
          <Selectors>
            <MapSelector
              items={buildings}
              onItemSelected={(it) => actions.goTo(it.id)}
              title={currentBuilding ? currentBuilding.name : i18n.t('screens.map.buildings')}
              isBuildingSelector
            />
            {!!currentBuilding && (
              <MapSelector
                items={floors}
                onItemSelected={(it) => actions.goTo(currentBuilding.id, it.id)}
                title={currentFloor ? currentFloor.name : i18n.t('screens.map.floors')}
              />
            )}
          </Selectors>
        )}

        <MapModal
          pois={filteredPois}
          occupancies={occupancies}
          poi={poi}
          nav={nav}
          onItemSelected={onPlaceSelected}
          onRoute={computeRoute}
        />
      </ScreenContainer>
    </Main>
  )
}

export default Map

const ScreenContainer = styled('div')`
  flex: 1;
  position: relative;
`

const MapContainer = styled('div')`
  flex: 1;
`

const Selectors = styled('div')`
  position: absolute;
  z-index: 1;
  bottom: 40px;
  left: 40px;
  flex-direction: row;
  align-items: flex-end;
`
