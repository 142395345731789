import * as React from 'react'
import styled from 'theme/styled-components'
import Provider from 'theme/Provider'

import Button from 'components/button/Button'
import CloseModalButton from 'components/button/CloseModalButton'
import TimePicker from 'react-time-picker'

import useI18n from 'i18n/useI18n'

import { breakpoints } from 'utils/breakpoints'
import useEscKeyPressed from 'utils/useEscKeyPressed'

import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'

import './react-date-picker.css'

import { setHours, setMinutes, format, isBefore } from 'date-fns'

interface Props {
  title: string
  time?: Date
  min?: Date
  max?: Date
  onTimeSelected: (time: Date, timeEnd?: Date) => void
  timeEnd?: Date
  withEnd?: boolean
  buttonColor?: string
}

const TimePickerModal = ({ title, time, onTimeSelected, max, min, timeEnd, withEnd, buttonColor }: Props) => {
  const i18n = useI18n()

  const [current, setCurrent] = React.useState(time ? format(time, 'HH:mm') : undefined)
  const [currentEnd, setCurrentEnd] = React.useState(timeEnd && format(timeEnd, 'HH:mm'))

  const currentDate = React.useMemo(() => {
    if (!current) {
      return
    }
    const values = current.split(':')
    const hours = values[0] ? parseInt(values[0], 10) : undefined
    const minutes = values[1] ? parseInt(values[1], 10) : undefined

    if (hours === undefined || minutes === undefined) {
      return
    }

    return setHours(setMinutes(time || new Date(), minutes), hours)
  }, [current])

  const currentEndDate = React.useMemo(() => {
    if (!currentEnd) {
      return
    }
    const values = currentEnd.split(':')
    const hours = values[0] ? parseInt(values[0], 10) : undefined
    const minutes = values[1] ? parseInt(values[1], 10) : undefined

    if (hours === undefined || minutes === undefined) {
      return
    }

    return setHours(setMinutes(time || new Date(), minutes), hours)
  }, [currentEnd])

  useEscKeyPressed(TimePickerPortal.close)

  const save = () => {
    if (!currentDate) {
      return
    }

    onTimeSelected(currentDate, withEnd ? currentEndDate : undefined)
    TimePickerPortal.close()
  }

  const intervalIsValid = !!currentDate && !!currentEndDate && isBefore(currentDate, currentEndDate)

  return (
    <MainContainer>
      <Back />

      <FocusLock autoFocus={true} returnFocus>
        <Container
          id="TimePickerModalDialog"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modalTimePickerHeading"
          tabIndex={-1}>
          <HeaderContainer>
            <Title id="modalTimePickerHeading">{title}</Title>
            <CloseModalButton nameIcon="cross" sizeIcon={30} onClick={TimePickerPortal.close} />
          </HeaderContainer>

          <TimePicker
            value={current}
            onChange={setCurrent}
            maxTime={max}
            minTime={min}
            locale={i18n.langCode || 'fr-FR'}
            disableClock
            clearAriaLabel={i18n.t('accessibility.ariaLabels.booking.clearSelection')}
          />

          {withEnd && (
            <TimePicker
              value={currentEnd}
              onChange={setCurrentEnd}
              maxTime={max}
              minTime={current}
              locale={i18n.langCode || 'fr-FR'}
              disableClock
              clearAriaLabel={i18n.t('accessibility.ariaLabels.booking.clearSelection')}
            />
          )}

          <ButtonContainer>
            <Button
              color={buttonColor}
              label={i18n.t('common.validate')}
              onClick={save}
              disabled={!currentDate || (withEnd && !intervalIsValid)}
            />
          </ButtonContainer>
        </Container>
      </FocusLock>
    </MainContainer>
  )
}

const MainContainer = styled('div')`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  opacity: 1;

  -webkit-animation: fadeIn 0.3s linear;
  animation: fadeIn 0.3s linear;
  z-index: 1;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Back = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.backgroundModal};
  border: 0px;
`

const Container = styled('div')`
  width: 520px;
  margin: 20px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  padding: 40px;
  border-radius: 15px;
  gap: 30px;
  @media only screen and (max-width: ${breakpoints.small}px) {
    width: calc(100vw - 80px);
    border-radius: 10px;
    gap: 20px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
`

const ButtonContainer = styled('div')`
  align-items: center;
`

let modalRoot: HTMLElement | null

const TimePickerPortal = {
  open: (props: Props) => {
    modalRoot = document.getElementById('portal_root')

    if (modalRoot) {
      ReactDOM.render(
        <Provider>
          <TimePickerModal {...props} />
        </Provider>,
        modalRoot
      )
    }
  },
  close: () => {
    if (modalRoot) {
      ReactDOM.unmountComponentAtNode(modalRoot)
    }
  },
}

export default TimePickerPortal
