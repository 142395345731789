import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'

import useBreakpoints from 'utils/useBreakpoints'
import { breakpoints } from 'utils/breakpoints'
import useI18n from 'i18n/useI18n'

interface Props {
  icon: IconName
  children?: JSX.Element
  closingWidth?: number
  onToggle?: (isOpen: boolean) => void
}

export const DRAWER_WIDTH = 370

const Drawer = ({ icon, children, closingWidth = 0, onToggle }: Props) => {
  const [opened, setOpened] = React.useState(false)

  const [size, currentBreakpoint] = useBreakpoints()
  const [Theme] = useTheme()
  const i18n = useI18n()

  const open = (open: boolean) => {
    setOpened(open)
    if (!!onToggle) {
      onToggle(open)
    }
  }

  React.useEffect(() => {
    open(size.width > closingWidth && (currentBreakpoint === 'mediumBig' || currentBreakpoint === 'big'))
  }, [size.width, currentBreakpoint])

  return (
    <Panel
      opened={opened}
      height={size.height}
      width={size.width}
      role="navigation"
      aria-label={i18n.t('accessibility.ariaLabels.navigation.secondaryDrawer')}>
      <Notch
        onClick={() => open(!opened)}
        aria-label={i18n.t(`accessibility.ariaLabels.drawer.${opened ? 'close' : 'open'}`)}>
        <Icon name={icon} size={34} color={Theme.colors.accent} cursor="pointer" />
      </Notch>
      {opened && (
        <PanelContent>
          <CloseContainer>
            <CloseButton onClick={() => open(false)} aria-label={i18n.t('accessibility.ariaLabels.drawer.close')}>
              <Icon name="cross" size={22} color={Theme.colors.accent} cursor="pointer" />
            </CloseButton>
          </CloseContainer>
          {children}
        </PanelContent>
      )}
    </Panel>
  )
}

export default Drawer

const Panel = styled('nav')<{ opened: boolean; height: number; width: number }>`
  width: ${DRAWER_WIDTH}px;
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.theme.colors.background};
  position: fixed;
  bottom: 0px;
  right: ${(props) => (props.opened ? '0' : '-370')}px;

  box-shadow: -5px 0px 5px ${(props) => `rgba(0, 0, 0, ${props.opened ? '0.1' : '0'})`};
  z-index: 2;
  display: inherit;
  flex-direction: inherit;

  transition: right 0.5s ease-in-out, box-shadow 0.2s ease-in-out;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    height: ${(props) => props.height - props.theme.constants.menuButtonSize}px;
    width: ${(props) => props.width}px;
    right: ${(props) => (props.opened ? '0' : `-${props.width}`)}px;
  }
`
const Notch = styled('button')`
  cursor: pointer;
  height: 74px;
  width: 64px;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 15px 0px 0px 15px;
  z-index: -1;
  box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.1);

  border: 0px;

  position: absolute;
  left: -64px;
  top: 82px;
  @media only screen and (max-width: ${breakpoints.phone}px) {
    top: 42px;
  }
  @media only screen and (max-width: ${breakpoints.phone}px) {
    bottom: 24px;
  }

  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const PanelContent = styled('div')`
  position: relative;
  overflow-y: auto;
`

const CloseContainer = styled('div')`
  flex-direction: row;
  justify-content: flex-end;
`

const CloseButton = styled('button')`
  padding: 20px 25px;
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
`
