import ws from 'utils/Webservice'

const api = {
  getUserDetail: () => {
    return ws<User>(`/account/details`, 'GET')
  },
  searchCompanion: (search: string) => {
    return ws<CompanionList>(`/visits/companions/search?filter=${search}`, 'GET')
  },
  createVisit: (body: VisitCreationForm) => {
    return ws<VisitResponse>(`/visits`, 'POST', body)
  },
  getListVisits: () => {
    return ws<Visits>(`/visits`, 'GET')
  },

  getVisitDetail: (id: number) => {
    return ws<VisitDetailed>(`/visits/${id}`, 'GET')
  },

  updateVisit: (id: number, body: VisitCreationForm) => {
    return ws<VisitResponse>(`/visits/${id}`, 'PUT', body)
  },

  deleteVisit: (id: number) => {
    return ws(`/visits/${id}`, 'DELETE')
  },
}

export default api
