import * as React from 'react'

import { breakpoints } from './breakpoints'
import useWindowSize from './useWindowSize'

type KeyBreakpoint = keyof typeof breakpoints

const useBreakpoint = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = React.useState<KeyBreakpoint>()

  const size = useWindowSize()

  React.useEffect(() => {
    if (size.width < breakpoints.phone) {
      setCurrentBreakpoint('phone')
    } else if (size.width > breakpoints.big) {
      setCurrentBreakpoint('big')
    } else {
      const keys = Object.keys(breakpoints) as KeyBreakpoint[]
      const currentKey = keys.find(
        (b, i) => i > 0 && breakpoints[keys[i - 1]] < size.width && breakpoints[keys[i]] >= size.width
      )
      setCurrentBreakpoint(currentKey || 'phone')
    }
  }, [size])

  return [size, currentBreakpoint, breakpoints] as const
}
export default useBreakpoint
