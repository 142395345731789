import * as React from 'react'
import styled, { keyframes, css } from 'theme/styled-components'
import useTheme from 'theme/useTheme'

interface Props {
  color?: string
  duration?: number
  size?: number
}

const Loader = ({ color, duration = 2, size = 90 }: Props) => {
  const [Theme] = useTheme()

  return <CometSpin duration={duration} color={color || Theme.colors.primary} size={size} />
}

export default Loader

const CometSpin = styled('div')<{ duration: number; size: number; color: string }>`
  animation: ${(props) =>
    css`
      ${loading} ${props.duration}s infinite ease, ${round} ${props.duration}s infinite ease
    `};
  border-radius: 50%;
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.size}px`};
  height: 1em;
  margin: 72px auto;
  overflow: hidden;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  width: 1em;
`

const loading = keyframes`
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
`

const round = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
