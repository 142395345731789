// SITE FUNCTIONALITIES

import { drawer, mapping } from './common'
import Screens from './Screens'

const clearPath = (path: string, lang: string) => path.replace(`/${lang}`, '')

const mapFunctionality = (f: FunctionalityV3): FunctionalityV3 => {
  switch (f.type) {
    case 'SAFE_AT_WORK':
      return { ...f, mobileLibelle: 'safeAtWork' }
    case 'ANIMATION':
      return { ...f, mobileLibelle: 'register' }
    case 'INTER_CAMPUS':
      // On remplace les trajets inter-campus avec l'accès général "Se déplacer"
      return { ...f, type: 'MOVE', mobileLibelle: 'move' }
    case 'BOOKING':
    case 'CATERING':
      // On remplace la réservation de salles ou d'instants avec l'accès général "En réunion"
      return { ...f, type: 'MEETING', mobileLibelle: 'meeting' }
    default:
      return f
  }
}

const getFilteredFunctionalities = (functionalities: FunctionalityV3[], isDrawer?: boolean): FunctionalityV3[] => {
  const fncArray = [
    ...functionalities
      .filter(
        (f) =>
          f.activated &&
          Object.values(mapping).some((m) => f.type === m) &&
          (!isDrawer || (!!f.mobileLibelle && drawer.includes(f.mobileLibelle) && f.type !== 'WEBPAGE')) &&
          (f.type !== 'TRANSPORT' || !functionalities.some((f) => f.type === 'INTER_CAMPUS' && f.activated))
      )
      .map(mapFunctionality),
  ]

  // Pour supprimer les doublons
  return fncArray.filter((f1, i) => fncArray.findIndex((f2) => f2.type === f1.type) === i)
}

// DEEPLINKS

const REGEX_PATH_ID = new RegExp(/:[a-zA-Z]+[?]*/g)
const REGEX_CLEAN_ID = new RegExp(/[:?]+/g)

const DEEPLINK_MAP: { [name: string]: ScreenName } = { actuDetail: 'newsDetail' }

/**
 * Resolve params inside query url
 * @param search search part from url
 */
const extractSearch = (search: string) => {
  if (!search) {
    return {}
  }

  const clean = search.replace('?', '')

  return clean.split('&').reduce((acc, cur) => {
    const param = cur.split('=')
    return { ...acc, [param[0]]: param[1] }
  }, {} as { [key: string]: string })
}

/**
 * extract search from deeplink to return next url to follow
 * @param search search part from url
 */
const extractDeeplink = (search: string) => {
  const params = extractSearch(search)

  if (!params.page) {
    return '/'
  }

  const pageName = DEEPLINK_MAP[params.page] || params.page
  const mappedScreen = Object.values(Screens).find((s) => s.name === pageName)

  if (!!mappedScreen) {
    // On remplace chaque id du path par la valeur de l'id du lien
    return mappedScreen.path.replaceAll(REGEX_PATH_ID, (key) => params[key.replace(REGEX_CLEAN_ID, '')] || '')
  }

  return '/'
}

export { clearPath, mapFunctionality, getFilteredFunctionalities, extractSearch, extractDeeplink }
