import React from 'react'
import styled from 'theme'

import { Link } from 'react-router-dom'

interface Props {
  imageSource: string
  title: string
  description?: string
  isInternalLink?: boolean
  link?: string
  isImageIcon?: boolean
  onClick?: () => void
}

const IMAGE_CONTAINER_SIZE = 92
const IMAGE_SIZE = 52

type ContainerType = {
  isInternalLink?: boolean
  link?: string
  onClick?: () => void
  isImageIcon?: boolean
}

const Container = ({ link, isInternalLink, onClick, isImageIcon, children }: React.PropsWithChildren<ContainerType>) =>
  isInternalLink ? (
    <LinkContainer to={link || ''} onClick={onClick}>
      {children}
    </LinkContainer>
  ) : (
    <AContainer href={link} target="_blank" rel="noreferrer" onClick={onClick}>
      {children}
    </AContainer>
  )

const ListItemLink = ({ description, imageSource, link, isInternalLink, title, isImageIcon, onClick }: Props) => {
  return (
    <Container isInternalLink={isInternalLink} link={link} onClick={onClick}>
      <ImageContainer>
        <ItemImage src={imageSource} />
      </ImageContainer>
      <ItemInfos>
        <ItemTitle>{title}</ItemTitle>
        {!!description && <ItemResume>{description}</ItemResume>}
      </ItemInfos>
    </Container>
  )
}

export default ListItemLink

const LinkContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  :hover {
    transform: scale(1.02);
  }
`

const AContainer = styled('a')`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  text-decoration: none;

  transition: all 0.1s ease-in-out;

  :hover {
    transform: scale(1.02);
  }
`

const ImageContainer = styled('div')`
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: ${IMAGE_CONTAINER_SIZE}px;
  width: ${IMAGE_CONTAINER_SIZE}px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.functionalities.practicalinformation}0D;
`

const ItemImage = styled('img')`
  object-fit: cover;
  user-select: none;
  aspect-ratio: 4/3;
  height: ${IMAGE_SIZE}px;
  width: ${IMAGE_SIZE}px;
  border-radius: 12px;
`

const ItemInfos = styled('div')`
  padding: 10px 24px;
  flex: 1;
  flex-direction: column;
`

const ItemTitle = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-wrap: break-word;
`

const ItemResume = styled('p')`
  ${(props) => props.theme.fonts.body};
  padding-top: 8px;
  margin: 0;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-wrap: break-word;
`
