import * as React from 'react'

declare type WSSStatus = 'starting' | 'connected' | 'disconnected' | 'error'

declare interface WSSEvent {
  data: string
}

/* TypeScript ne reconnait pas WebSocket donc on déclare sommairement son type ici */
declare class WebSocket {
  constructor(url: string, empty?: string)
  onopen: () => void
  onclose: () => void
  onerror: () => void
  onmessage: (evt: WSSEvent) => void
  close: () => void
}

const useWebsocket = <T>(path: string, listener: (data: { type: string; data: T; delta?: boolean }) => void) => {
  const [wss, setWss] = React.useState<WebSocket>()
  const [errorCount, setErrorCount] = React.useState(0)
  const [status, setStatus] = React.useState<WSSStatus>('starting')
  const retry = React.useRef<NodeJS.Timeout>()

  const connect = () => {
    if (!path || !path.startsWith('wss')) {
      return
    }

    if (!wss) {
      setStatus('starting')

      if (retry.current) {
        clearTimeout(retry.current)
        retry.current = undefined
      }

      setWss(new WebSocket(path))
    }
  }

  const disconnect = () => {
    if (wss) {
      wss.close()
    }
  }

  React.useEffect(() => {
    if (wss) {
      // set listeners
      wss.onopen = () => {
        setErrorCount(0)
        setStatus('connected')
      }

      wss.onclose = () => {
        setWss(undefined)

        if (retry.current) {
          clearTimeout(retry.current)
          retry.current = undefined
        }

        setStatus('disconnected')
      }

      wss.onerror = () => {
        setErrorCount((prev) => prev + 1)
        setWss(undefined)

        const wait = Math.min(Math.pow(2, errorCount), 32)
        retry.current = setTimeout(connect, wait * 1000)

        setStatus('error')
      }

      wss.onmessage = (e: any) => {
        if (e && e.data) {
          const resp = JSON.parse(e.data)

          listener(resp)
        }
      }
    }

    return () => {
      try {
        // close websocket on quit
        if (wss) {
          wss.close()
        }
      } catch (err) {
        /* Il semble qu'il y ait un problème sur react 0.55 sur la fermeture des websocket qui déclenche une erreur. */
      }
    }
  }, [wss])

  return [wss, status, connect, disconnect] as const
}

export default useWebsocket
