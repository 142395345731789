import i18next from 'i18next'
import dateFormat from 'date-fns/format'
import LngDetector from 'i18next-browser-languagedetector'

import { createEvent, createStore } from 'effector'
import { fr, enUS as en } from 'date-fns/locale'

import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'

const PERSIST_KEY = 'persist:I18N_LANGUAGE'
const persistedLang = localStorage.getItem(PERSIST_KEY)

type Language = 'fr' | 'en'

const supportedLang: Language[] = ['fr', 'en']
const fallbackLang: Language = (persistedLang as Language) || 'en'

export const numbroLang: Record<Language, string> = {
  fr: 'fr-FR',
  en: 'en-US',
}

Object.values(numbroLang)
  .filter((l) => l !== 'en-US') // Pas besoin d'en faire un register vu qu'elle est par défaut
  .forEach((lang) => {
    numbro.registerLanguage(languages[lang])
  })

const resources = {
  fr: {
    translation: {
      ...require('./locales/fr.json'),
    },
  },
  en: {
    translation: {
      ...require('./locales/en.json'),
    },
  },
}

const locales: { [key: string]: Locale } = {
  fr,
  en,
}

const langCodes: { [key: string]: string } = {
  fr: 'fr-FR',
  en: 'en-EN',
}

i18next.use(LngDetector).init({
  // debug: true,
  whitelist: supportedLang,
  fallbackLng: fallbackLang,
  interpolation: {
    escapeValue: false,
    format: (value, f, lng) => {
      let format = f

      const formatKey = 'formats.' + format
      const i18nforma = i18next.t(formatKey)
      if (i18nforma !== formatKey) {
        format = i18nforma
      }

      if (format === 'currency' && typeof value === 'number') {
        return numbro(value).formatCurrency({
          mantissa: 2,
          currencySymbol: '€',
        })
      }

      // You can extends functionality here by providing custom format function based on value and format
      if (format && value instanceof Date) {
        const locale = locales[lng || fallbackLang]
        const s = dateFormat(value, format, { locale })
        return s
      }
      return value
    },
  },
  resources,
})

export interface I18n {
  readonly lang: 'fr' | 'en'
  readonly langCode: string
  readonly t: (key: string, params?: { [key: string]: any }) => string
  readonly supportedLang: string[]
  readonly fallbackLang: string
  readonly locales: { [key: string]: any }
}

const i18n = (lng?: string | null) => {
  if (lng) {
    const lang = supportedLang.find((l) => l === lng) || supportedLang.find((l) => lng.indexOf(l) > -1) || fallbackLang
    i18next.changeLanguage(lang)
    numbro.setLanguage(numbroLang[lang])
  }

  return {
    // eslint-disable-next-line prefer-spread
    t: (...args: any) => i18next.t.apply(i18next, args),
    lang: i18next.language,
    langCode: langCodes[i18next.language],
    supportedLang,
    fallbackLang,
    locales,
  } as I18n
}

export const actions = {
  setLang: createEvent<string>('setLang'),
}

export const store = createStore<I18n>(i18n(persistedLang)).on(actions.setLang, (_, lang) => {
  // persist new language
  localStorage.setItem(PERSIST_KEY, lang)

  return i18n(lang)
})
