import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Button from 'components/button/Button'
import Icon from 'components/icons/Icon'
import Alert from 'components/alert/Alert'
import Modal from 'components/modal/Modal'

import useI18n from 'i18n/useI18n'
import { I18n } from 'i18n/i18n'

import api from './api'
import directoryApi from 'directory365/api'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import { getUserName } from './utils'
import Logger from 'utils/Logger'

interface Props {
  booking: Booking
}

const BUTTON_WIDTH = 155

const validationSchema = (i18n: I18n) =>
  Yup.object().shape({
    email: Yup.string().email(i18n.t('errors.form.format')),
  })

const RegisterDrawerContent = ({ booking }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const [status, setStatus] = React.useState<'input' | 'loading' | 'succeed'>('input')
  const [collaboratorName, setCollaboratorName] = React.useState<string>()

  const onSubmit = () => {
    if (!!values.email) {
      setStatus('loading')
      api
        .share(booking.animationId, booking.slotId, booking.id, values.email)
        .then(() =>
          directoryApi
            .searchByEmail(values.email)
            .then(({ value }) => setCollaboratorName(getUserName(value[0])))
            .finally(() => setStatus('succeed'))
        )
        .catch((err) => {
          Logger.error(err)
          Alert.open({
            title: i18n.t('common.error'),
            description: i18n.t('common.errorDescription'),
          })
          setValues({ email: '' })
          setStatus('input')
        })
    }
  }

  const { handleChange, handleBlur, handleSubmit, setValues, values, isValid } = useFormik({
    initialValues: { email: '' },
    onSubmit,
    validateOnChange: true,
    validationSchema: validationSchema(i18n),
  })

  if (status === 'succeed') {
    return (
      <>
        <DrawerTitleContainer>
          <Icon name="check_circle" size={36} color={theme.colors.available} />
          <DrawerTitle>{i18n.t('screens.register.detail.sharing.bookingSharedTo')}</DrawerTitle>
        </DrawerTitleContainer>

        <CollaboratorContainer>
          {!!collaboratorName && <CollaboratorName>{collaboratorName}</CollaboratorName>}
          <CollaboratorEmail>{values.email}</CollaboratorEmail>
        </CollaboratorContainer>

        <ButtonContainer>
          <Button label={i18n.t('common.close')} onClick={Modal.close} />
        </ButtonContainer>
      </>
    )
  }

  return (
    <>
      <DrawerTitleContainer>
        <DrawerTitle>{i18n.t('screens.register.detail.sharing.who')}</DrawerTitle>
      </DrawerTitleContainer>

      <EmailTitle>{i18n.t('screens.register.detail.sharing.emailTitle')}</EmailTitle>

      <EmailInput
        id="collaboratorEmailInput"
        name="email"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        placeholder={i18n.t('screens.register.detail.sharing.emailPlaceholder')}
        type="email"
        required
      />

      <DrawerButtonsContainer>
        <ButtonContainer>
          <Button label={i18n.t('common.cancel')} onClick={Modal.close} style="secondary" width={BUTTON_WIDTH} />
        </ButtonContainer>

        <ButtonContainer>
          <Button
            label={i18n.t('common.share')}
            onClick={handleSubmit}
            disabled={!values.email || !isValid || status !== 'input'}
            width={BUTTON_WIDTH}
          />
        </ButtonContainer>
      </DrawerButtonsContainer>
    </>
  )
}

export default RegisterDrawerContent

const DrawerTitleContainer = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  gap: 16px;
`

const DrawerTitle = styled.p`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.primaryDark};
`

// Input content

const EmailTitle = styled.p`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.primaryDark};
  margin-bottom: 8px;
`

const EmailInput = styled.input`
  flex: 1;
  align-items: center;
  padding: 14px;
  border-radius: 8px;
  outline: none;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  background-color: ${(props) => props.theme.colors.contentBackground};
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.primaryDark};
`

// Success content

const CollaboratorContainer = styled.div`
  padding: 8px;
  gap: 8px;
  border-radius: 8px;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.contentBackground};
`

const CollaboratorName = styled.p`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.primaryDark};
`

const CollaboratorEmail = styled.p`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.iconicGrey};
`

const DrawerButtonsContainer = styled.div`
  flex-direction: row;
  align-self: center;
  gap: 16px;
`

const ButtonContainer = styled.div`
  flex: 1;
  align-items: center;
  margin-top: 52px;
`
