const ACCENT_MAP: { [key: string]: string } = {
  á: 'a',
  à: 'a',
  â: 'a',
  ä: 'a',
  å: 'a',
  ã: 'a',
  æ: 'ae',

  ç: 'c',

  é: 'e',
  è: 'e',
  ê: 'e',
  ë: 'e',

  í: 'i',
  ì: 'i',
  î: 'i',
  ï: 'i',

  ñ: 'n',

  ó: 'o',
  ò: 'o',
  ô: 'o',
  ö: 'o',
  õ: 'o',
  ø: 'o',
  œ: 'oe',

  š: 's',

  ú: 'u',
  ù: 'u',
  û: 'u',
  ü: 'u',

  ÿ: 'u',
  ý: 'u',

  ž: 'z',

  '&': 'et',
}

const sanitize = (text?: string) => {
  if (!text) {
    return ''
  } else {
    return text
      .toLowerCase()
      .split('')
      .map((char) => (ACCENT_MAP.hasOwnProperty(char) ? ACCENT_MAP[char] : char))
      .join('')
  }
}

export default sanitize
