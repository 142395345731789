import * as React from 'react'
import styled from 'theme/styled-components'

import { Link } from 'react-router-dom'

import Button, { ButtonProps } from './Button'

import useI18n from 'i18n/useI18n'

interface Props extends ButtonProps {
  path: string
  isFullPath?: boolean
}

const ButtonLink = (props: Props) => {
  const i18n = useI18n()

  const { isFullPath, path, ariaLabel } = props

  return (
    <StyledLink to={isFullPath ? `/${i18n.lang}` : `${path}`} aria-label={ariaLabel}>
      <Button {...props} />
    </StyledLink>
  )
}

export default ButtonLink

const StyledLink = styled(Link)`
  text-decoration: none;
`
