import * as React from 'react'
import styled from 'theme/styled-components'
import theme from 'theme/Theme'

import { CategoryIcon, LineIcon } from './svg/TransportIcon'
import Icon from 'components/icons/Icon'
import Tag from 'components/label/Tag'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as TransportStore from './store'
import * as SitesStore from 'site/store'

import { getDistances, getDistancesInMinutes, getLineName } from './utils'

interface Props {
  lineDetails: TransportStation
  index: number
}

const DirectionItem = ({ lineDetails, index }: Props) => {
  const i18n = useI18n()

  const site = useReducer(SitesStore.store, (s) => s.site)
  const favorites = useReducer(TransportStore.store, (s) => s.favorites)

  const setFav = (already: boolean, favoriteLine: TransportStation) => {
    const { name, line, schedules, category } = favoriteLine

    if (already) {
      TransportStore.actions.setFav(
        favorites.filter((f) => f.station !== name || f.line !== line || f.direction !== schedules[0].direction)
      )
    } else if (!!site) {
      const fav = {
        station: name,
        line: line,
        direction: schedules[0].direction,
        category: category,
        siteId: site.id,
      }
      TransportStore.actions.setFav([...favorites, fav])
    }
  }

  const checkTrainSchedule = (schedule: string) => {
    if (schedule === i18n.t('screens.transport.now')) {
      return theme.colors.open
    }
    if (schedule === i18n.t('screens.transport.next')) {
      return theme.colors.near
    }
    return theme.colors.secondaryLightGrey
  }

  const renderTag = (schedule: string, index: number) => {
    const tagColor = checkTrainSchedule(schedule)
    return !!schedule ? (
      <TagContainer key={index}>
        <Tag
          label={schedule}
          backgroundColor={tagColor}
          color={tagColor === theme.colors.secondaryLightGrey ? theme.colors.primaryText : theme.colors.white}
          horizontalPadding={5}
        />
      </TagContainer>
    ) : null
  }

  const renderNoSchedule = () => <NoScheduleText>{i18n.t('screens.transport.errors.noSchedule')}</NoScheduleText>

  if (!lineDetails) {
    return null
  }

  const { name, line, category, schedules } = lineDetails
  const direction = !!schedules ? schedules[0].direction : ''

  const schedulesList = getDistances(i18n, new Date(), lineDetails).filter((s) => s !== '')
  const schedulesInMinutes = getDistancesInMinutes(new Date(), lineDetails)

  const isFav = !!favorites.find(
    (f) => f.station === name && f.line === line && f.direction === schedules[0].direction && f.siteId === site?.id
  )

  return (
    <Line
      key={`${category || 'UNKNOWN'} - ${name} - ${direction}`}
      aria-label={i18n.t(`screens.transport.accessibility.hintTransport`, {
        line: i18n.t(`screens.transport.accessibility.lines.${getLineName(line)}`),
        station: name,
        direction: direction,
        schedules:
          schedulesInMinutes[0] !== undefined
            ? `${i18n.t('screens.transport.accessibility.hintNextSchedule', { minutes: schedulesInMinutes[0] })}${
                schedulesInMinutes[1] !== undefined
                  ? i18n.t('screens.transport.accessibility.hintFollowingSchedule', {
                      minutes: schedulesInMinutes[1],
                    })
                  : ''
              }`
            : i18n.t('screens.transport.accessibility.hintNoSchedules'),
      })}>
      <LineNameContainer>
        <LeftContainer>
          <LogoContainer>
            {!!category && (
              <Logo>
                <CategoryIcon name={category as CategoryTransportIconName} size={30} />
              </Logo>
            )}
            <Logo>
              <LineIcon name={line as LineTransportIconName} size={30} />
            </Logo>
          </LogoContainer>

          <Separator />

          <DirectionTagContainer>
            <DirectionTitle>{direction}</DirectionTitle>
            <TagsContainer>{!!schedulesList ? schedulesList.map(renderTag) : renderNoSchedule()}</TagsContainer>
          </DirectionTagContainer>
        </LeftContainer>
        <FavoriteContainer onClick={() => setFav(isFav, lineDetails)}>
          <Icon name={isFav ? 'heart_filled' : 'heart'} size={22} />
        </FavoriteContainer>
      </LineNameContainer>
    </Line>
  )
}

export default DirectionItem

// CONTAINERS

const LineNameContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Line = styled('div')`
  padding: 12px;
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.secondaryLightGrey};
`

const LogoContainer = styled('div')`
  gap: 4px;
`

const Logo = styled('div')`
  padding: 2px;
`

const DirectionTagContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 4px;
`

const TagsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

const TagContainer = styled(TagsContainer)`
  margin: 0;
  padding: 0;
`

const LeftContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: 100%;
`

const FavoriteContainer = styled('div')`
  padding: 0 12px;
`

// SEPARATORS

const Separator = styled('div')`
  align-self: center;
  height: 100%;
  margin: 0 10px;
  width: 1px;
  background-color: ${(props) => props.theme.colors.disable};
`

// TEXTS

const DirectionTitle = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryDark};
  padding: 0 5px 5px;
  height: 22px;
  flex: 1;
  max-width: 230px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const NoScheduleText = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.primary};
  padding: 10px 0px 0px 12px;
`
