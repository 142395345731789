import * as React from 'react'
import styled from 'theme/styled-components'
import Theme from 'theme/Theme'
import Provider from 'theme/Provider'

import Loader from './Loader'

import ReactDOM from 'react-dom'

const ModalLoader = () => (
  <MainContainer>
    <Back />

    <Loader color={Theme.colors.white} />
  </MainContainer>
)

const MainContainer = styled('div')`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  opacity: 1;

  -webkit-animation: fadeIn 0.3s linear;
  animation: fadeIn 0.3s linear;
  z-index: 1;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Back = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.backgroundModal};
`

let modalRoot: HTMLElement | null

const LoaderPortal = {
  open: () => {
    modalRoot = document.getElementById('portal_root')

    if (modalRoot) {
      ReactDOM.render(
        <Provider>
          <ModalLoader />
        </Provider>,
        modalRoot
      )
    }
  },
  close: () => {
    if (modalRoot) {
      ReactDOM.unmountComponentAtNode(modalRoot)
    }
  },
}

export default LoaderPortal
