import * as React from 'react'

import useTheme from './useTheme'
import { ThemeProvider } from './styled-components'

interface Props {
  children: any
}

const Provider = ({ children }: Props) => {
  const [theme] = useTheme()

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default Provider
