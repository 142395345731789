import axios from 'axios'
import config from 'core/src/config'

import * as i18n from 'i18n/i18n'

import auth from 'core/src/utils/auth'

const MAX_TIMEOUT = 20000

class ApiError extends Error {
  code: number
  data: any

  constructor(message: string, code: number, data?: any) {
    super(message)
    this.code = code
    this.data = data
  }
}

const execute = <T>(
  path: string,
  method: 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE',
  body?: any,
  heads?: { [key: string]: string }
): Promise<T> => {
  const lng = i18n.store.getState().lang

  // cancel Token used for Timeout
  const cancelToken = axios.CancelToken.source()
  setTimeout(cancelToken.cancel, MAX_TIMEOUT)

  return auth.assureToken('server').then((token) => {
    const tokenHead = !!token ? { Authorization: `Bearer ${token}` } : {}
    return axios
      .request<T>({
        cancelToken: cancelToken.token,
        url: config.API_VERSION + path,
        method: method || (body ? 'POST' : 'GET'),
        headers: {
          'X-Platform': 'WEB',
          'X-Api-Key': config.API_KEY,
          'content-type': 'application/json',
          'Accept-Language': lng,
          ...tokenHead,
          ...(heads || {}),
        },
        data: body ? JSON.stringify(body) : undefined,
        timeout: MAX_TIMEOUT,
      })
      .then((res) => res.data)
      .catch((err) => {
        if (err.response) {
          const { status, data, statusText } = err.response

          if (status === 401 || status === 403) {
            // if error with Authorization
            auth.logout()
            throw new ApiError(statusText, status, data)
          }

          throw new ApiError(statusText, status, data)
        }

        throw err
      })
  })
}

export default execute
