import { I18n } from 'i18n/i18n'

import { isSameDay } from 'date-fns'

const parseTime = (t: string) => {
  const h = t.substring(0, 2)
  const m = t.substring(3, 5)
  return h + 'h' + (m === '00' ? '' : m)
}

const computeTimeLabel = (item: ArticleDetailV4, i18n: I18n) => {
  if (!item.startTime || !item.endTime) {
    return undefined
  }

  return i18n.t('screens.news.timeRange', { start: parseTime(item.startTime), end: parseTime(item.endTime) })
}

const getDateLabel = (item: ArticleV4 | ArticleDetailV4, i18n: I18n) => {
  const start = !!item.startDate ? new Date(item.startDate) : undefined
  const end = !!item.endDate ? new Date(item.endDate) : undefined

  if (!!start) {
    if (!!end && !isSameDay(start, end)) {
      return i18n.t('screens.news.period', { start, end })
    }
    // Si la date de début et de fin est la même, on ne l'affiche qu'une seule fois
    return i18n.t('screens.news.at', { date: start })
  }
  return i18n.t('screens.news.date', { date: new Date(item.publicationDate) })
}

export { computeTimeLabel, getDateLabel }
