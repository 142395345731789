export const htmlAccessibilityFR = `
<!DOCTYPE html>
<html lang="fr" dir="LTR">
  <head>
    <meta http-equiv="CONTENT-TYPE" content="text/html; charset=utf-8" />
    <title>Accessibility French</title>
  </head>

  <body>
    <div>
      <p>
        <span style="font-size: 12pt; font-family: CenturyGothic">
          Cette page n'est pas une page d'aide.<br />Elle vise à présenter le niveau d'accessibilité général constaté du
          site "L'Oréal Smart Campus".
        </span>
      </p>
      <h2 style="font-size: 18pt; font-family: CenturyGothic; font-weight: 700; color: rgb(64, 64, 64)">
        Qu'est-ce que l'accessibilité numérique ?
      </h2>
      <p>
        <span style="font-size: 12pt; font-family: CenturyGothic">
          L'accessibilité numérique est un ensemble de règles et de bonnes pratiques qui couvrent notamment les aspects
          fonctionnels, graphiques, techniques et éditoriaux.
        </span>
        <br />
        <span style="font-size: 12pt; font-family: CenturyGothic">
          Le suivi de ces règles et bonnes pratiques permet de s'assurer que les supports numériques (sites web,
          applications mobiles, documents PDF, etc.) sont accessibles aux personnes en situation de handicap.</span
        >
      </p>
      <p><span style="font-size: 12pt; font-family: CenturyGothic">Un site accessible permet par exemple de :</span></p>
      <ul>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">
            Personnaliser son affichage via le système d'exploitation et/ou le navigateur (agrandissement ou
            rétrécissement des caractères, changement de la typographie, modification des couleurs, arrêt des
            animations, etc.).
          </span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">
            Naviguer à l'aide de technologies d'assistance comme une synthèse vocale ou une plage braille.
          </span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">
            Naviguer sans utiliser la souris, avec le clavier uniquement, des contacteurs ou via un écran tactile.
          </span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">
            Consulter les vidéos et les contenus audio à l'aide de sous-titres et/ou de transcriptions.
          </span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Etc.</span>
        </li>
      </ul>
      <h2 style="font-size: 18pt; font-family: CenturyGothic; font-weight: 700; color: rgb(64, 64, 64)">
        Déclaration d'accessibilité
      </h2>
      <p>
        <span style="font-size: 12pt; font-family: CenturyGothic">
          L'Oréal s'engage à rendre accessibles ses sites web (internet, intranet et extranet), ses applications
          mobiles, ses progiciels et son mobilier urbain numérique conformément à l'article 47 de la loi n°2005-102 du
          11 février 2005.
        </span>
        <br />
        <span style="font-size: 12pt; font-family: CenturyGothic">
          Cette déclaration d'accessibilité s'applique au site "Smart Campus".
        </span>
      </p>
      <h3 style="font-size: 15pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        État de conformité
      </h3>
      <p>
        <span style="font-size: 12pt; font-family: CenturyGothic">
          Le site "Smart Campus" est partiellement conforme avec le
          <a href="https://www.numerique.gouv.fr/publications/rgaa-accessibilite/">RGAA</a> (Référentiel Général
          d'Amélioration de l'Accessibilité) (version 4.1) en raison de certaines non-conformités.
        </span>
      </p>
      <h3 style="font-size: 15pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Résultats des tests
      </h3>
      <p>
        <span style="font-size: 12pt; font-family: CenturyGothic">
          Un audit macroscopique d'accessibilité a été réalisé en décembre 2021 puis en avril 2022 par la société
          <a href="https://ideance.net/fr/">Ideance</a>.
        </span>
        <br />
        <span style="font-size: 12pt; font-family: CenturyGothic">
          Cet audit a révélé que le site est partiellement conforme avec le RGAA (version 4.1).
        </span>
      </p>
      <h3 style="font-size: 15pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Établissement de cette déclaration d'accessibilité
      </h3>
      <p>
        <span style="font-size: 12pt; font-family: CenturyGothic">Cette déclaration a été établie le 28/12/2021 puis mise à jour le 19/04/2022.</span>
      </p>
      <h4 style="font-size: 12pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Technologies utilisées pour la réalisation du site
      </h4>
      <ul>
        <li>
          <span style="font-size: 12pt; font-family: CenturyGothic">HTML5<br /></span>
        </li>
        <li>
          <span style="font-size: 12pt; font-family: CenturyGothic">CSS<br /></span>
        </li>
        <li><span style="font-size: 12pt; font-family: CenturyGothic">JavaScript</span></li>
      </ul>
      <h4 style="font-size: 12pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Environnement de test
      </h4>
      <p>
        <span style="font-size: 12pt; font-family: CenturyGothic">
          Les tests ont été effectués avec les combinaisons de navigateur web et lecteur d'écran suivantes :</span
        >
      </p>
      <ul>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Firefox 94.0.2 et NVDA 2021.2.</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Firefox 94.0.2 et JAWS 2021.</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Safari et VoiceOver (iOS 15.0.1).</span>
        </li>
      </ul>

      <h4 style="font-size: 12pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Outils pour évaluer l'accessibilité
      </h4>
      <ul>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Colour Contrast Analyser</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Contrast Finder</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Outils de développement Firefox</span>
        </li>
        <li>
          <span style="font-size: 12pt; font-family: CenturyGothic">Web Developer (extension Firefox)</span>
        </li>
      </ul>
      <h4 style="font-size: 12pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Pages du site ayant fait l'objet de la vérification de conformité
      </h4>
      <ul>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Accueil d'un campus</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Actualités</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Restaurants</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">La cantine</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Annuaire</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Se repérer</span>
        </li>
      </ul>
    </div>
    <div>
      <h3 style="font-size: 15pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Retour d'information et contact
      </h3>
      <span style="font-size: 12pt; font-family: CenturyGothic">
        Si vous n'arrivez pas à accéder à un contenu ou à un service de ce site, vous pouvez nous contacter via un des
        moyens ci-dessous pour être orienté vers une alternative accessible ou obtenir le contenu sous une autre
        forme.</span
      >
      <ul>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Écrivez-nous à l'adresse email :&nbsp;</span>
          <a href="mailto:i-services@loreal.com">i-services@loreal.com</a>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Appelez-nous au 01 85 14 95 18.</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">
            Écrivez-nous par voie postale à l'adresse suivante :
            <br />
            Monsieur Frédéric DEXMIER
            <br />
            41 Rue Martre
            <br />
            92117 Clichy
          </span>
        </li>
      </ul>
      <h3 style="font-size: 15pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Voies de recours
      </h3>
      <span style="font-size: 12pt; font-family: CenturyGothic">
        Cette procédure est à utiliser dans le cas suivant.
      </span>
      <br />
      <span style="font-size: 12pt; font-family: CenturyGothic">
        Vous avez signalé au responsable du site internet un défaut d'accessibilité qui vous empêche d'accéder à un
        contenu ou à un des services et vous n'avez pas obtenu de réponse satisfaisante.
      </span>
      <ul>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <a href="https://formulaire.defenseurdesdroits.fr/">Écrire un message au Défenseur des droits.</a>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span>
            <a href="https://www.defenseurdesdroits.fr/saisir/delegues">
              Contacter le délégué du Défenseur des droits dans votre région.
            </a>
          </span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">
            Envoyer un courrier par la poste (gratuit, ne pas mettre de timbre) à l'adresse suivante :<br />Défenseur
            des droits <br />
            Libre réponse 71120 <br />
            75342 Paris CEDEX 07
          </span>
        </li>
      </ul>
    </div>
  </body>
</html>
`
