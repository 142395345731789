import ws from 'utils/Webservice'

const api = {
  all: (siteId: number) => ws<ReferentielList>(`/contrib/referentiels?siteId=${siteId}`, 'GET'),

  categories: (siteId: number) => ws<TypeList>(`/contrib/referentiels/types?siteId=${siteId}`, 'GET'),

  getTokenVisioglobe: () => ws<VisioglobeToken>(`/visioglobe/auth/token`, 'GET'),
}

export default api
