import * as React from 'react'
import styled from 'theme/styled-components'

import Icon from 'components/icons/Icon'

import useI18n from 'i18n/useI18n'

interface Props {
  status: NonNullable<OccupancyStatus['status']>
  color?: string
}

const FREE = ['free', 'unbooked-free', 'booked-free']

const Occupancy = ({ status, color }: Props) => {
  const i18n = useI18n()

  if (status === 'empty-default') {
    return <></>
  }

  return (
    <Container>
      {FREE.includes(status) ? <Icon name="check" color={color} size={15} /> : <Point color={color} />}
      <Label color={color}>{i18n.t(`occupancy.${status}`)}</Label>
    </Container>
  )
}

export default Occupancy

const Container = styled('div')`
  flex-direction: row;
  align-items: center;
  margin: 5px;
`

const Point = styled('div')<{ color?: string }>`
  background-color: ${(props) => props.color || props.theme.colors.noInfo};
  height: 8px;
  width: 8px;
  border-radius: 25px;
`

const Label = styled('p')<{ color?: string }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.color || props.theme.colors.noInfo};
  margin: 0px 0px 0px 10px;
`
