import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

const OfflineScreen = () => {
  const i18n = useI18n()

  return (
    <CenteredContainer>
      <Message>{i18n.t('errors.offline')}</Message>
    </CenteredContainer>
  )
}

export default OfflineScreen

const CenteredContainer = styled('div')`
  align-items: center;
  justify-content: center;
`

const Message = styled('h1')`
  ${(props) => props.theme.fonts.h3};

  margin: 0px;
`
