import { differenceInMinutes, isAfter } from 'date-fns'
import { I18n } from 'i18n/i18n'

export const findFavoriteDirection = (fav: FavoriteDirection, traffic?: TransportTraffic) => {
  const station = traffic && traffic.stations.find((s) => s.name === fav.station)
  const line = station && station.lines.find((l) => l.name === fav.line)
  const direction =
    line &&
    line.directions &&
    line.directions.find((d) => d.name === fav.direction && (!(d.mission && fav.mission) || d.mission === fav.mission))

  return { station, line, direction }
}

const compareNumericLines = (category: string, lineA: string, lineB: string) => {
  if (lineA.includes(category) && lineB.includes(category)) {
    const intA = parseInt(lineA.replace(category, ''))
    const intB = parseInt(lineB.replace(category, ''))
    return intA - intB
  }
  return 0
}

export const compareSameCategoryLines = (lineA: string, lineB: string) => {
  // En théorie, les deux lignes ont la même catégorie de transports
  if (lineA.includes('Bus ')) {
    return compareNumericLines('Bus ', lineA, lineB)
  }
  if (lineA.includes('Metro ')) {
    return compareNumericLines('Metro ', lineA, lineB)
  }
  if (lineA.includes('Tram ')) {
    return compareNumericLines('Tram ', lineA, lineB)
  }
  return lineA.localeCompare(lineB)
}

export const getCategoryName = (name: string) => {
  if (!name) {
    return undefined
  }
  return name.toLowerCase() as CategoryTransportIconName
}

export const getLineName = (name: string) => {
  if (!name) {
    return undefined
  }
  return name
    .toUpperCase()
    .replace('BUS ', 'B')
    .replace('METRO ', 'M')
    .replace('TRAM ', 'T')
    .replace('LIGNE ', 'Ligne')
    .replace(' ', '') as LineTransportIconName
}

export const getDateDistance = (i18n: I18n, ref: Date, d: string) => {
  const date = new Date(d)
  const minuteDiff = differenceInMinutes(date, ref)

  if (Math.abs(minuteDiff) < 1) {
    return i18n.t('screens.transport.now')
  }

  if (Math.abs(minuteDiff) < 2 && minuteDiff > 0) {
    return i18n.t('screens.transport.next')
  }

  if (minuteDiff >= 2) {
    return i18n.t('screens.transport.duration', { count: minuteDiff })
  }

  return ''
}

export const getDistances = (i18n: I18n, current: Date, station?: TransportStation) =>
  station
    ? station.schedules
        .filter(Boolean)
        .slice(0, 4) // On garde seulement les 4 premiers passages du transport
        .map((schedule) => schedule.time.replace(' ', 'T'))
        .sort((a, b) => (isAfter(new Date(a), new Date(b)) ? 1 : -1))
        .map((s) => getDateDistance(i18n, current, s))
        .filter(Boolean)
    : []

export const getDateDistanceInMinutes = (ref: Date, d: string) => differenceInMinutes(new Date(d), ref)

export const getDistancesInMinutes = (current: Date, station?: TransportStation) =>
  station
    ? station.schedules
        .filter(Boolean)
        .slice(0, 5)
        .map((schedule) => schedule.time.replace(' ', 'T'))
        .sort((a, b) => (isAfter(new Date(a), new Date(b)) ? 1 : -1))
        .map((s) => getDateDistanceInMinutes(current, s))
        .filter((d) => d >= 0)
    : []

export const getExternalLinks = (site?: SiteV4) => {
  if (!!site && !!site.address && !!site.latitude && !!site.longitude) {
    return {
      citymapper: `https://citymapper.com/directions?endcoord=${site.latitude}%2C${
        site.longitude
      }&endname=${encodeURIComponent(site.name)}&endaddress=${encodeURIComponent(site.address)}`,
      waze: `https://www.waze.com/live-map/directions?q=${encodeURIComponent(site.address)}&ll=${site.latitude}%2C${
        site.longitude
      }&navigate=yes`,
    }
  }
  return {
    citymapper: `https://citymapper.com/paris?set_region=fr-paris`,
    waze: `https://www.waze.com/`,
  }
}
