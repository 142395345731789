import * as React from 'react'

const useOffline = () => {
  const [status, setStatus] = React.useState<'online' | 'offline'>(navigator.onLine ? 'online' : 'offline')

  const onOnlineStatusChanged = () => {
    setStatus(navigator.onLine ? 'online' : 'offline')
  }

  React.useEffect(() => {
    window.addEventListener('online', onOnlineStatusChanged)
    window.addEventListener('offline', onOnlineStatusChanged)

    return () => {
      window.removeEventListener('online', onOnlineStatusChanged)
      window.removeEventListener('offline', onOnlineStatusChanged)
    }
  }, [])

  return status
}

export default useOffline
