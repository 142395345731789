import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import IconButton from 'components/icons/IconButton'
import Icon from 'components/icons/Icon'

import analytics from 'utils/analytics'
import { breakpoints } from 'utils/breakpoints'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  value: string
  onChange: (search: string) => void
  idSearchInput?: string
  autofocus?: boolean
  placeholder?: string
  feature?: 'directory' | 'guest'
  borderRadius?: string
  iconPosition?: 'right' | 'left'
}

const SearchBar = ({
  value,
  onChange,
  idSearchInput,
  autofocus = false,
  placeholder,
  feature,
  borderRadius = '52px',
  iconPosition = 'right',
}: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const renderIcon = () => (
    <SearchIconContainer>
      <Icon name="search" size={20} color={Theme.colors.primaryDark} />
    </SearchIconContainer>
  )

  return (
    <SearchContainer role="search" borderRadius={borderRadius}>
      {iconPosition === 'left' && renderIcon()}

      <SearchBarInput
        value={value}
        id={idSearchInput}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder || i18n.t('screens.phonebook.searchPlaceholder')}
        autoFocus={autofocus}
        title={i18n.t('accessibility.titleInputText.searchBar')}
        type="text"
        onFocus={() => {
          if (!!feature) {
            analytics.event({ event_feature: values.eventName[feature], event_action: values.actions.search })
          }
        }}
      />

      {value.length > 0 && (
        <IconContainer>
          <IconButton
            name="cross"
            size={17}
            color={Theme.colors.iconicGrey}
            onClick={() => onChange('')}
            ariaLabel={i18n.t('accessibility.ariaLabels.deleteSearchInput')}
          />
        </IconContainer>
      )}

      {iconPosition === 'right' && renderIcon()}
    </SearchContainer>
  )
}

// CONTAINERS

const SearchContainer = styled('div')<{ borderRadius: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};
  height: 38px;
  border-radius: ${(props) => props.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.middleGrey};
  padding: 0px 12px;
  min-width: 600px;

  @media only screen and (max-width: ${breakpoints.big}px) {
    min-width: 450px;
  }

  @media only screen and (max-width: ${breakpoints.medium}px) {
    min-width: 300px;
  }
`
const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`
const SearchIconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
`

// AUTRES

const SearchBarInput = styled('input')`
  flex: 1;
  ${(props) => props.theme.fonts.subtitle};
  margin: 0 12px;
  height: 36px;
  border: 0;
  outline: 0;
  ::placeholder {
    color: ${(props) => props.theme.colors.middleGrey};
  }
`

export default SearchBar
