import * as React from 'react'
import styled from './styled-components'
import useTheme from './useTheme'

import useI18n from 'i18n/useI18n'

import IconButton from 'components/icons/IconButton'

import { breakpoints } from 'utils/breakpoints'

const ThemeSelector = () => {
  const i18n = useI18n()
  const [Theme, name, setTheme] = useTheme()

  const [visible, setVisible] = React.useState(false)

  const changeTheme = (next: typeof name) => {
    setTheme(next)
    setVisible(false)
  }

  return (
    <PositionContainer>
      <IconButton
        name="hide"
        onClick={() => setVisible(!visible)}
        ariaLabel={i18n.t(`accessibility.ariaLabels.highContrast.floatingButton`)}
        size={Theme.constants.menuButtonSize}
        isFloating={true}
      />

      {visible && (
        <SwitchContainer>
          <Title>{i18n.t(`theme.title`)}</Title>
          <Line>
            <Button
              active={name === 'classic'}
              onClick={() => changeTheme('classic')}
              aria-label={i18n.t(`accessibility.ariaLabels.highContrast.defaultTheme`)}>
              {i18n.t(`theme.classic`)}
            </Button>
            <Button
              active={name === 'accessible'}
              onClick={() => changeTheme('accessible')}
              aria-label={i18n.t(`accessibility.ariaLabels.highContrast.highContrastTheme`)}>
              {i18n.t(`theme.accessible`)}
            </Button>
          </Line>
        </SwitchContainer>
      )}
    </PositionContainer>
  )
}

export default ThemeSelector

const PositionContainer = styled('div')`
  position: fixed;
  top: 12px;
  right: 50px;
  align-items: flex-end;
  z-index: 1;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    right: 30px;
  }

  @media only screen and (max-width: ${breakpoints.phone}px) {
    top: 12px;
    right: 16px;
  }
`

const SwitchContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.background};
  margin-top: 10px;
  padding: 15px 40px;
  border-radius: 12px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
`

const Title = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
  text-align: center;
  margin: 0px 0px 10px;
`

const Line = styled('div')`
  flex-direction: row;
`

const Button = styled('button')<{ active: boolean }>`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => (props.active ? props.theme.colors.background : props.theme.colors.primary)};
  background-color: ${(props) => (props.active ? props.theme.colors.primary : props.theme.colors.background)};
  border-radius: 7px;
  border: 0px;
  padding: 10px;

  :hover {
    cursor: pointer;
  }
`
