import * as React from 'react'
import SVG from 'react-inlinesvg'
import { getCategoryName, getLineName } from '../utils'

interface Props {
  name: CategoryTransportIconName | LineTransportIconName
  line?: LineTransportIconName
  color?: string
  size?: number
  height?: number
  cursor?: 'default' | 'pointer'
}

const categoryTransportIcons: { [key in CategoryTransportIconName]: string } = {
  metro: require('./METRO/logo.svg').default,
  rer: require('./RER/logo.svg').default,
  tramway: require('./TRAMWAY/logo.svg').default,
  train: require('./TRANSILIEN/logo.svg').default,
  bus: require('./BUS/logo.svg').default,

  citymapper: require('./Citymapper.svg').default,
  waze: require('./Waze.svg').default,
}

const lineTransportIcons: { [key in LineTransportIconName]: string } = {
  BPC1: require('./BUS/pc.svg').default,
  B20: require('./BUS/20.svg').default,
  B54: require('./BUS/54.svg').default,
  B66: require('./BUS/66.svg').default,
  B84: require('./BUS/84.svg').default,
  B138: require('./BUS/138.svg').default,
  B165: require('./BUS/165.svg').default,
  B173: require('./BUS/173.svg').default,
  B174: require('./BUS/174.svg').default,
  B238: require('./BUS/238.svg').default,
  B274: require('./BUS/274.svg').default,
  B341: require('./BUS/341.svg').default,
  B613: require('./BUS/613.svg').default,

  M1: require('./METRO/1.svg').default,
  M2: require('./METRO/2.svg').default,
  M3: require('./METRO/3.svg').default,
  M4: require('./METRO/4.svg').default,
  M5: require('./METRO/5.svg').default,
  M6: require('./METRO/6.svg').default,
  M7: require('./METRO/7.svg').default,
  M8: require('./METRO/8.svg').default,
  M9: require('./METRO/9.svg').default,
  M10: require('./METRO/10.svg').default,
  M12: require('./METRO/12.svg').default,
  M13: require('./METRO/13.svg').default,
  M14: require('./METRO/14.svg').default,

  RERA: require('./RER/A.svg').default,
  RERB: require('./RER/B.svg').default,
  RERC: require('./RER/C.svg').default,
  RERD: require('./RER/D.svg').default,
  RERE: require('./RER/E.svg').default,

  T1: require('./TRAMWAY/1.svg').default,
  T2: require('./TRAMWAY/2.svg').default,
  T3A: require('./TRAMWAY/3a.svg').default,
  T3B: require('./TRAMWAY/3b.svg').default,
  T4: require('./TRAMWAY/4.svg').default,
  T5: require('./TRAMWAY/5.svg').default,
  T6: require('./TRAMWAY/6.svg').default,
  T7: require('./TRAMWAY/7.svg').default,
  T8: require('./TRAMWAY/8.svg').default,
  T11: require('./TRAMWAY/11.svg').default,

  LigneH: require('./TRANSILIEN/H.svg').default,
  LigneJ: require('./TRANSILIEN/J.svg').default,
  LigneK: require('./TRANSILIEN/K.svg').default,
  LigneL: require('./TRANSILIEN/L.svg').default,
  LigneN: require('./TRANSILIEN/N.svg').default,
  LigneP: require('./TRANSILIEN/P.svg').default,
  LigneR: require('./TRANSILIEN/R.svg').default,
  LigneU: require('./TRANSILIEN/U.svg').default,
}

const CategoryIcon = ({ name, line, size = 17 }: Props) => {
  const iconSource = categoryTransportIcons[getCategoryName(name) as CategoryTransportIconName]
  if (!iconSource) {
    return null
  }

  return <SVG aria-hidden focusable="false" src={iconSource} height={size} width={size} />
}

const LineIcon = ({ name, size = 17 }: Props) => {
  const iconSource = lineTransportIcons[getLineName(name) as LineTransportIconName]
  if (!iconSource) {
    return null
  }

  return <SVG aria-hidden focusable="false" src={iconSource} height={size} width={size} />
}

export { CategoryIcon, LineIcon }
