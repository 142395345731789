import { UserSelf } from 'store/user/user'

const OFFICE_MAP: { [code: string]: string } = {
  CCZ: '03 -Site de CCZ',
  CES: '01 -Site de CLICHY',
  CHE: '07 -Site de CHEVILLY LARUE',
  CAC: 'PMAUL-Site Aulnay Sous Bois',
  LOF: '10 -Site LEVALLOIS PERRET - Campus LOREAL France',
  S62: '06 -Site LEVALLOIS PERRET',
}

const extractSite = (user: UserSelf) => {
  if (!user) {
    return undefined
  }
  const { officeLocation } = user

  if (!officeLocation) {
    return undefined
  }

  return Object.keys(OFFICE_MAP).find((key) => officeLocation.includes(OFFICE_MAP[key]))
}

export { OFFICE_MAP, extractSite }
