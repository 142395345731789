import * as React from 'react'
import styled from 'styled-components'
import { Values, SetFieldValueType } from './GuestScreen'

import useI18n from 'i18n/useI18n'
import useTheme from 'theme/useTheme'
import { sortVisitorsByName } from './utils'
import { PotentialVisitor } from './types'
import { breakpoints } from 'utils/breakpoints'

import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'
import VisitorForm from './modal/VisitorForm'
import Icon from 'components/icons/Icon'

interface Props {
  values: Values
  setFieldValue: SetFieldValueType
  isEditMode?: boolean
}

const LIMIT = 30

const VisitorPicker = ({ values, setFieldValue, isEditMode = false }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const renderVisitor = (visitor: PotentialVisitor, index: number) => (
    <SlytedItemList key={visitor.id}>
      <React.Fragment>
        <VisitorContainer>
          <VisitorInfoContainer>
            <VisitorName>{`${visitor.firstName} ${visitor.lastName}`}</VisitorName>
            <IconButton
              onClick={() =>
                isEditMode
                  ? setFieldValue(
                      'visitors',
                      values.visitors.filter((_, i) => i !== index)
                    )
                  : Modal.open({
                      Content: () => (
                        <VisitorForm visitors={values.visitors} setFieldValue={setFieldValue} editedVisitor={visitor} />
                      ),
                    })
              }
              aria-label={i18n.t(
                `accessibility.ariaLabels.${isEditMode ? 'editVisit.deleteVisitor' : 'guest.editVisitorInfo'}`
              )}>
              <Icon name={isEditMode ? 'trash' : 'pencil'} size={20} color={Theme.colors.secondary} cursor="pointer" />
            </IconButton>
          </VisitorInfoContainer>
          {isEditMode && (
            <>
              <VisitorInfo>{visitor.email}</VisitorInfo>
              <VisitorInfo>
                {!!visitor.phoneNumber ? visitor.phoneNumber : i18n.t('screens.editVisit.numberUnknown')}
              </VisitorInfo>
              <VisitorInfo>
                {!!visitor.company ? visitor.company : i18n.t('screens.editVisit.companyUnknown')}
              </VisitorInfo>
            </>
          )}
        </VisitorContainer>
        <LineBreak />
      </React.Fragment>
    </SlytedItemList>
  )

  return (
    <MainContainer>
      <Title>
        {i18n.t(`screens.guest.visitors.${values.visitors.length === 0 ? 'title' : 'visitorCount'}`, {
          count: values.visitors.length,
        })}
      </Title>
      <Description>
        {i18n.t(`screens.guest.visitors.${values.visitors.length === 0 ? 'empty' : 'notEmpty'}`, { count: LIMIT })}
      </Description>

      {values.visitors.length > 0 && (
        <VisitorList>{sortVisitorsByName(values.visitors).map(renderVisitor)}</VisitorList>
      )}

      <ButtonContainer>
        <Button
          label={i18n.t('screens.guest.visitors.add')}
          onClick={() =>
            Modal.open({ Content: () => <VisitorForm visitors={values.visitors} setFieldValue={setFieldValue} /> })
          }
          color={Theme.colors.background}
          disabled={values.visitors.length >= LIMIT}
        />
      </ButtonContainer>
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  margin-top: 12px;
  @media only screen and (max-width: ${breakpoints.phone}px) {
    margin-top: 25px;
  }
`
const ButtonContainer = styled('div')`
  width: 327px;
`
const VisitorContainer = styled('div')`
  flex: 1;
  flex-direction: column;
  margin: 10px 0px;
`

const VisitorInfoContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

// TEXTES

const Title = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px 0px 10px 0px;
`
const Description = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.iconicGrey};

  margin: 0px 0px 10px 0px;
`
const VisitorName = styled('h3')`
  ${(props) => props.theme.fonts.tagTiroir};
  margin: 0px;
`
const VisitorInfo = styled('p')`
  display: flex;
  ${(props) => props.theme.fonts.body};
  margin: 0px;
`

// AUTRES

const VisitorList = styled('ul')`
  margin-bottom: 10px;
  width: 327px;
  list-style: none;
  padding: 0;
`
const SlytedItemList = styled('li')`
  display: flex;
  flex-direction: column;
`

const IconButton = styled('button')`
  cursor: pointer;
  margin-right: 14px;
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
`

const LineBreak = styled('div')`
  height: 1px;
  background-color: ${(props) => props.theme.colors.lightGrey};
`

export default VisitorPicker
