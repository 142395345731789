import * as React from 'react'
import styled from 'styled-components'
import { DirectoryUser } from './types'

import useReducer from 'store/useReducer'
import * as DirectoryStore from './store'

import useI18n from 'i18n/useI18n'
import { sortUsersByDisplayName } from './utils'

import api from './api'

import UserInfoCell from './UserInfoCell'
import UserDetails from './UserDetails'
import Modal from 'components/modal/Modal'
import Loader from 'components/status/Loader'

const MyFavorites = () => {
  const i18n = useI18n()

  const favoritesId = useReducer(DirectoryStore.store, (s) => s.favorites)

  const [favorites, setFavorites] = React.useState<DirectoryUser[]>([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(true)

    api
      .getUsers(favoritesId)
      .then((res) => setFavorites(sortUsersByDisplayName(res.value)))
      .finally(() => setLoading(false))
  }, [favoritesId])

  const renderFavoriteContact = (contact: DirectoryUser) => (
    <li key={contact.id}>
      <React.Fragment>
        <ContactButton
          onClick={() => Modal.open({ Content: () => <UserDetails user={contact} /> })}
          aria-label={!!contact.displayName ? contact.displayName : undefined}>
          <UserInfoCell user={contact} />
        </ContactButton>
      </React.Fragment>
    </li>
  )

  return (
    <MyFavoritesContainer>
      <DrawerTitle>
        <label htmlFor="idFavouriteDirectorySearch"> {i18n.t('screens.phonebook.myFavorites')}</label>
      </DrawerTitle>

      {loading ? (
        <Loader />
      ) : favorites.length > 0 ? (
        <ListContainer>{favorites.map(renderFavoriteContact)}</ListContainer>
      ) : (
        <MessageText>{i18n.t('screens.phonebook.noFavorites')}</MessageText>
      )}
    </MyFavoritesContainer>
  )
}

// CONTAINERS

const MyFavoritesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`

// TEXTES

const DrawerTitle = styled('h1')`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.darkGrey};
  margin-bottom: 0;
`

const MessageText = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
  margin: 0 22px;
  padding: 10px 0 30px;
`

// LISTS

const ListContainer = styled('ol')`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-bottom: 8px;
`

// BUTTON

const ContactButton = styled('button')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  text-align: start;
  border: 1px;
  background-color: ${(props) => props.theme.colors.background};
  cursor: pointer;
`

export default MyFavorites
