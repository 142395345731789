import * as React from 'react'
import styled from 'theme/styled-components'

import Icon from 'components/icons/Icon'
import BookModal from 'components/modal/BookModal'

import PoiDetail from './PoiDetail'
import Search from './Search'
import Route from './Route'

import useI18n from 'i18n/useI18n'

import { RouteNavigation } from './visioweb'
import { RouteAction } from './types'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  pois: Referentiel[]
  occupancies: OccupancyList
  poi?: Referentiel
  nav?: RouteNavigation
  onItemSelected: (item: Referentiel | undefined) => void
  onRoute: (action: RouteAction) => Promise<void>
}

const MapModal = ({ pois, occupancies, poi, nav, onItemSelected, onRoute }: Props) => {
  const i18n = useI18n()

  const [mode, setMode] = React.useState<'SEARCH' | 'PLACE' | 'ROUTE'>('SEARCH')

  React.useEffect(() => {
    if (poi) {
      setMode('PLACE')
    } else if (mode === 'PLACE') {
      setMode('SEARCH')
    }
  }, [poi])

  const onBook = (room: Referentiel) => {
    BookModal.open({
      room: room,
    })
  }

  return (
    <MainContainer aria-label={i18n.t('accessibility.ariaLabels.navigation.secondaryDrawer')}>
      <Content>
        {mode !== 'SEARCH' && (
          <Head>
            <ActionButton
              onClick={() => {
                onItemSelected(undefined)
                onRoute({ action: 'stop' })
                setMode('SEARCH')
              }}
              aria-label={i18n.t('accessibility.ariaLabels.mapDrawer.dropFocusOnDesk')}>
              <Icon name="circle_chevron_left" size={24} />
              {i18n.t('common.back')}
            </ActionButton>
          </Head>
        )}

        {poi && mode !== 'ROUTE' ? (
          <PoiDetail
            poi={poi}
            occupancy={occupancies[poi.reference]}
            onBook={() => onBook(poi)}
            onItinerary={() => {
              analytics.event({
                event_feature: values.eventName.map,
                event_action: values.actions.go,
                event_object_id: poi.reference,
              })
              setMode('ROUTE')
            }}
          />
        ) : mode === 'SEARCH' ? (
          <Search pois={pois} onItemSelected={onItemSelected} />
        ) : mode === 'ROUTE' ? (
          <Route pois={pois} poi={poi} nav={nav} onRoute={onRoute} />
        ) : null}
      </Content>
    </MainContainer>
  )
}

export default MapModal

const MainContainer = styled('div')`
  display: inherit;
  flex-direction: row;
  position: absolute;
  z-index: 1;
  top: 40px;
  right: 40px;

  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.08));
`

const Content = styled('div')`
  flex-direction: column;
  width: 375px;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  background-color: ${(props) => props.theme.colors.background};
`

const Head = styled('div')`
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
`

const ActionButton = styled('button')`
  padding: 10px;
  border: 0px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.background};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  ${(props) => props.theme.fonts.subtitleBold};
`
