import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

import Button from 'components/button/Button'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'
import FooterLinks from 'components/footerLinks/FooterLinks'

import * as UserStore from 'store/user/user'
import Storage from 'store/storage'

import auth from 'core/src/utils/auth'

import { breakpoints } from 'utils/breakpoints'

import useNavigation from 'core/src/layout/useNavigation'

const AUTH_KEY = 'AUTH_STATUS'

const LoginScreen = () => {
  const i18n = useI18n()
  const navigation = useNavigation()

  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    // get current connexion status
    Storage.getItem(AUTH_KEY)
      .then((status) => {
        // if status is connecting
        if (status === 'connnecting') {
          // try to get user then push to site select
          return auth
            .me()
            .then(UserStore.actions.setUser)
            .then(() => Storage.setItem(AUTH_KEY, 'off'))
            .then(() => navigation.push('/site'))
            .finally(() => setLoading(false))
        } else {
          // if not connecting just remove loading
          setLoading(false)
        }
      })
      .catch(() => setLoading(false))
  }, [])

  const login = () => Storage.setItem(AUTH_KEY, 'connnecting').then(() => auth.login('server'))

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={i18n.t('screens.login.title')} />
        <BackImage src={require('assets/back_login.jpg').default} alt="" />

        <Container>
          <Logo src={require('assets/logo_white.png').default} alt="" />

          <div>
            <WelcomeText>{i18n.t('screens.login.welcome')}</WelcomeText>
            <DescriptionText>{i18n.t('screens.login.description')}</DescriptionText>
          </div>

          <ButtonContainer>
            <Button label={i18n.t('common.login')} onClick={login} disabled={loading} />
          </ButtonContainer>

          <LinkContainer>
            <FooterLinks rowOrientation />
          </LinkContainer>
        </Container>
      </ScreenContainer>
    </Main>
  )
}

export default LoginScreen

const ScreenContainer = styled('div')`
  display: flex;
  overflow-y: auto;
  height: 100vh;
`

const BackImage = styled('img')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
`

const Logo = styled('img')`
  height: 200px;
  width: 200px;
  align-self: center;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    height: 150px;
    width: 150px;
  }
`

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px;
  z-index: 10;
  align-items: center;
  justify-content: space-evenly;
`

const WelcomeText = styled('h1')`
  ${(props) => props.theme.fonts.h1Bold};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 30px 0 15px;
  text-align: center;
`

const DescriptionText = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 0 0 40px;
  font-size: 18px;
  text-align: center;
`

const ButtonContainer = styled('div')`
  width: 340px;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: 220px;
  }
`

const LinkContainer = styled('div')`
  justify-content: center;
  text-align: center;
  color: ${(props) => props.theme.colors.primaryDark};
`
