import { compareAsc, isAfter } from 'date-fns'

const sortStringArray = (array: string[]) => array.sort((a, b) => a.localeCompare(b))

const areValidAnswers = (validAnswers: string[], userAnswers: string[]) => {
  // Pour chaque question, l'ordre des réponses n'est pas important, mais il faut avoir sélectionné
  // toutes les bonnes réponses existantes et aucune mauvaise réponse pour valider la question

  const validString = JSON.stringify(sortStringArray(validAnswers))
  const userString = JSON.stringify(sortStringArray(userAnswers))

  return validString === userString
}

const filterSurveys = (surveys: SurveyWS[]) =>
  surveys
    .filter((survey) => isAfter(new Date(survey.endDate), new Date()))
    .sort((a, b) => compareAsc(new Date(a.endDate), new Date(b.endDate)))

export { sortStringArray, areValidAnswers, filterSurveys }
