import { createEvent, createStore } from 'effector'

export const actions = {
  setNews: createEvent<ArticleV4[]>('setNews'),
  resetNews: createEvent('resetNews'),
  setLikes: createEvent<string[]>('setLikes'),
  resetLikes: createEvent('resetLikes'),
  reset: createEvent('reset'),
}

const initialState: NewsState = {
  news: [],
  likes: [],
}

export const store = createStore<NewsState>(initialState, { name: 'news' })
  .on(actions.setNews, (s, news) => ({ ...s, news }))
  .on(actions.resetNews, (s) => ({ ...s, news: initialState.news }))
  .on(actions.setLikes, (s, likes) => ({ ...s, likes }))
  .on(actions.resetLikes, (s) => ({ ...s, likes: initialState.likes }))
  .on(actions.reset, () => ({ ...initialState }))
