import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import RegisterList from './RegisterList'

import Main, { HEADER_HEIGHT } from 'components/main/Main'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import FAB from 'components/button/FAB'
import Tree from 'components/button/Tree'
import SectionsList, { Section } from 'components/list/SectionsList'
import MyReservationsList, { BookingStatus } from './MyReservationsList'

import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'

import { addDays, isSameDay } from 'date-fns'
import { capitalize } from 'utils/stringUtils'
import { breakpoints } from 'utils/breakpoints'
import { DEFAULT_WIDTH, fetchAnimations, fetchReservations } from './utils'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

const TODAY = new Date()
TODAY.setHours(0, 0, 0, 0)

const CURRENT_WEEK = addDays(TODAY, 1 - TODAY.getDay())

interface Props {
  sectionIndex?: string
}

const RegisterScreen = ({ sectionIndex = '0' }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const SECTIONS: Section[] = [
    { label: i18n.t('screens.register.sections.ALL_EVENTS'), id: 'ALL_EVENTS' },
    { label: i18n.t('screens.register.sections.MY_BOOKINGS'), id: 'MY_BOOKINGS' },
  ]

  const site = useReducer(SiteStore.store, (s) => s.site)

  const [animationsStatus, setAnimationsStatus] = React.useState<ScreenStatus>('loading')
  const [reservationsStatus, setReservationsStatus] = React.useState<ScreenStatus>('loading')
  const [selectedWeek, setSelectedWeek] = React.useState<Date>(CURRENT_WEEK)
  const [selectedSection, setSelectedSection] = React.useState<Section>(SECTIONS[parseInt(sectionIndex)])
  const [bookingsStatus, setBookingsStatus] = React.useState<BookingStatus>('future')

  const isCurrentWeek = React.useMemo(() => isSameDay(selectedWeek, CURRENT_WEEK), [selectedWeek])

  React.useEffect(() => {
    if (selectedSection.id === 'ALL_EVENTS') {
      analytics.screen({ screen_feature: values.screens.register, screen_name: values.screens.registerList })
    } else {
      analytics.screen({ screen_feature: values.screens.register, screen_name: values.screens.registerReservations })
    }
  }, [selectedSection])

  React.useEffect(() => {
    fetchAnimations(site?.id).then(setAnimationsStatus)
    fetchReservations().then(setReservationsStatus)
  }, [site])

  return (
    <ScreenContainer>
      <TitleHelmet title={i18n.t('screens.register.title')} />

      <Main>
        <>
          {bookingsStatus === 'past' && selectedSection.id === 'MY_BOOKINGS' && (
            <HeaderContainer>
              <Tree
                previousPages={[{ url: 'register', title: 'screens.register.title' }]}
                currentPageTitle={i18n.t('screens.register.history')}
              />
              <Title>{i18n.t('screens.register.history')}</Title>
            </HeaderContainer>
          )}
          {bookingsStatus !== 'past' && (
            <Header>
              <SectionsList sections={SECTIONS} selectedSection={selectedSection} onSectionClick={setSelectedSection} />

              {selectedSection.id === 'ALL_EVENTS' && (
                <DaySelector>
                  <FAB
                    icon="chevron_left"
                    onClick={() => setSelectedWeek(addDays(selectedWeek, -7))}
                    color={theme.colors.primary}
                    backgroundColor={theme.colors.white}
                    iconColor={theme.colors.primary}
                    size={50}
                    iconSize={20}
                    disabled={isCurrentWeek}
                  />

                  <DateLabel>
                    {capitalize(
                      i18n.t('screens.register.week', { start: selectedWeek, end: addDays(selectedWeek, 6) })
                    )}
                  </DateLabel>

                  <FAB
                    icon="chevron_right"
                    onClick={() => setSelectedWeek(addDays(selectedWeek, 7))}
                    color={theme.colors.primary}
                    backgroundColor={theme.colors.white}
                    iconColor={theme.colors.primary}
                    size={50}
                    iconSize={20}
                  />
                </DaySelector>
              )}
            </Header>
          )}

          {selectedSection.id === 'ALL_EVENTS' ? (
            <ListContainer>
              <RegisterList selectedWeek={selectedWeek} screenStatus={animationsStatus} />
            </ListContainer>
          ) : (
            <ListContainer>
              <MyReservationsList
                screenStatus={reservationsStatus}
                bookingsStatus={bookingsStatus}
                onBookingsStatusChange={setBookingsStatus}
              />
            </ListContainer>
          )}
        </>
      </Main>
    </ScreenContainer>
  )
}

export default RegisterScreen

const ScreenContainer = styled('div')`
  display: flex;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
`

const Header = styled('div')`
  padding-top: 52px;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 50px 90px 25px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px 25px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px 25px;
  }
`

const DaySelector = styled('div')`
  padding-top: 28px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: ${DEFAULT_WIDTH}px;
`

const DateLabel = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  font-size: 18px;
  padding: 0px 28px;
  text-align: center;
`

const ListContainer = styled('div')`
  flex: 1;
  padding: 35px 165px;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 35px 90px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 35px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 35px 35px;
  }
`

const HeaderContainer = styled('div')`
  padding: 40px 70px 0px;
`

const Title = styled('p')`
  padding-top: 24px;
  ${(props) => props.theme.fonts.h3Bold};
`
