import * as React from 'react'
import styled from 'theme/styled-components'

import Map from './Map'
import Loader from 'components/status/Loader'

import api from './api'

import config from 'core/src/config'
import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'
import * as UserStore from 'store/user/user'
import * as ReferentielStore from 'store/referentiel/referentiel'

import useWebsocket from 'utils/useWebsocket'

import TitleHelmet from 'components/titleHelmet/TitleHelmet'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  location?: string
}

const MapScreen = ({ location }: Props) => {
  const i18n = useI18n()

  const tokenVisioglobe = useReducer(UserStore.store, (s) => s.tokenVisioglobe)
  const site = useReducer(SiteStore.store, (s) => s.site!)
  const pois = useReducer(ReferentielStore.referentielStore, (s) => s.referentiels)
  const occupancies = useReducer(ReferentielStore.occupancyStore, (s) => s.occupancies)

  const [newOccupancies, setNewOccupancies] = React.useState<OccupancyList>({})

  const onOccupancy = (d: { type: string; data: OccupancyStatus[]; delta?: boolean }) => {
    if (d.type === 'occupancy') {
      // On ne traite que les changements d'occupation du site (si précisé)
      const siteData = !!site.name ? d.data.filter((occ) => occ.siteName === site.name) : d.data

      if (siteData.length > 0) {
        // delta=true : on ajoute les nouvelles données sans écraser les anciennes
        // delta=false : on écrase les anciennes données avec les nouvelles
        const lastOccupancies = d.delta ? ReferentielStore.occupancyStore.getState().occupancies : {}
        const newOccupancies = siteData.reduce((acc, cur) => ({ ...acc, [cur.visioglobeId]: cur }), {} as OccupancyList)

        ReferentielStore.actions.setOccupancies({ ...lastOccupancies, ...newOccupancies })
        setNewOccupancies(newOccupancies)
      }
    }
  }

  const [, , connect, disconnect] = useWebsocket<OccupancyStatus[]>(config.WSS_URL, onOccupancy)

  const fetchTokenVisioglobe = () => api.getTokenVisioglobe().then(UserStore.actions.setTokenVisioglobe)

  const token = React.useMemo(() => {
    // On appelle le WS pour récupérer le token que si le token
    // n'existe pas ou si il est expiré
    if (!!tokenVisioglobe && !!tokenVisioglobe.expiration) {
      // Le timestamp d'expiration du token (en secondes)
      const expirationTimestamp = tokenVisioglobe.expiration

      // Le temps actuel en secondes
      const currentTimestamp = Math.floor(Date.now() / 1000)

      if (currentTimestamp > expirationTimestamp) {
        fetchTokenVisioglobe()
      } else {
        const tokenBearer = tokenVisioglobe?.token

        if (!!tokenBearer) {
          return tokenBearer.replace('Bearer ', '')
        }
      }
    } else {
      fetchTokenVisioglobe()
    }
    return undefined
  }, [tokenVisioglobe])

  React.useEffect(() => {
    // start websocket
    connect()

    analytics.screen({ screen_name: values.screens.map })

    // stop websocket
    return () => disconnect()
  }, [])

  React.useEffect(() => {
    // get pois
    api.all(site.id).then((l) => ReferentielStore.actions.setReferentiels(l.referentiels))
  }, [site])

  const mapFNC = site.functionalities.find((f) => f.type === 'MAP')

  if (!mapFNC || !mapFNC.param || !mapFNC.param.mapHash) {
    return null
  }

  return (
    <MainContainer>
      <TitleHelmet title={i18n.t('screens.map.title')} />
      {!token ? (
        <Loader />
      ) : (
        <Map
          key={site.id}
          hash={mapFNC.param.mapHash}
          token={token}
          pois={pois}
          occupancies={occupancies}
          newOccupancies={newOccupancies}
          location={location}
        />
      )}
    </MainContainer>
  )
}

export default MapScreen

const MainContainer = styled('div')`
  flex: 1;
  justify-content: center;
`
