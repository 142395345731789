import { createEvent, createStore } from 'effector'

export const actions = {
  setFav: createEvent<FavoriteDirection[]>('setTransportFavs'),
  reset: createEvent('resetTransportFavs'),
}

const initialState: TransportState = {
  favorites: [],
}

export const store = createStore<TransportState>(initialState)
  .on(actions.setFav, (s, favorites) => ({ ...s, favorites }))
  .on(actions.reset, () => initialState)
