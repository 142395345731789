import * as React from 'react'
import styled from 'theme/styled-components'

import Button from 'components/button/Button'
import Switch from 'components/button/Switch'
import DatePickerModal from 'components/picker/DatePickerModal'
import TimePickerModal from 'components/picker/TimePickerModal'
import PickerModal from 'components/picker/PickerModal'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as BookingStore from './store'
import * as SiteStore from 'site/store'

import { useFormik, FormikHelpers } from 'formik'
import { breakpoints } from 'utils/breakpoints'
import Alert from 'components/alert/Alert'

import {
  addMinutes,
  differenceInMinutes,
  setHours,
  setMinutes,
  isAfter,
  startOfDay,
  endOfDay,
  min,
  max,
  subMinutes,
  isWeekend,
} from 'date-fns'
import { I18n } from 'i18n/i18n'
import { notWeekend } from './utils'

interface Values {
  start: Date
  end: Date
  range: boolean
  period: 'morning' | 'afternoon' | 'allDay'
}

interface Props {
  initialValues: Values
  onFormSubmit: (values: Values) => Promise<any>
}

interface PropsFields {
  values: Values
  setFieldValue: (field: string, value: any) => void
}

const PERIODS = [
  { label: 'morning', start: 9, end: 13 },
  { label: 'afternoon', start: 13, end: 18 },
  { label: 'allDay', start: 9, end: 18 },
]

const errorWeekDays = (i18n: I18n) => {
  Alert.open({
    title: i18n.t('screens.meeting.booking.picker.error.weekday.title'),
    description: i18n.t('screens.meeting.booking.picker.error.weekday.content'),
    buttons: [
      {
        label: i18n.t('common.ok'),
        onClick: Alert.close,
      },
    ],
  })
}

export const SearchFormFields = ({ values, setFieldValue }: PropsFields) => {
  const i18n = useI18n()
  const search = useReducer(BookingStore.store, (s) => s.search)
  const site = useReducer(SiteStore.store, (s) => s.site!)

  const daysOfSearchOffice = React.useMemo(
    () => site.functionalities.find((f) => f.activated && f.type === 'BOOKING')?.param?.daysOfSearchOffice || 1,
    [site]
  )

  React.useEffect(() => {
    // restore search when range mode change
    if (values.range && search) {
      const start = new Date(search.start)
      const end = new Date(search.end)

      setFieldValue('start', setMinutes(setHours(values.start, start.getHours()), start.getMinutes()))
      setFieldValue('end', setMinutes(setHours(values.end, end.getHours()), end.getMinutes()))
    } else if (!values.range) {
      const per = PERIODS.find((p) => p.label === values.period)

      if (per) {
        setFieldValue('start', setHours(values.start, per.start))
        setFieldValue('end', setHours(values.start, per.end))
      }
    }
  }, [values.range])

  React.useEffect(() => {
    // save search when dates change
    if (values.range) {
      BookingStore.actions.setSearch({ start: values.start.toISOString(), end: values.end.toISOString() })
    }
  }, [values.start, values.end])

  const duration = React.useMemo(() => differenceInMinutes(values.end, values.start), [values])

  const openStartDatePicker = () => {
    const ONEDAY = 24 * 60 * 60 * 1000
    let min = new Date()
    let max = new Date(min.getTime() + Math.max(0, daysOfSearchOffice - 1) * ONEDAY)
    min = notWeekend(min)
    max = notWeekend(max)

    DatePickerModal.open({
      date: values.start,
      onDateSelected: (next) => {
        if (isWeekend(next)) {
          errorWeekDays(i18n)
        } else {
          setFieldValue('start', setMinutes(setHours(next, values.start.getHours()), values.start.getMinutes()))
          setFieldValue('end', setMinutes(setHours(next, values.end.getHours()), values.end.getMinutes()))
        }
      },
      min,
      max,
      title: i18n.t('screens.meeting.booking.picker.label.start'),
    })
  }

  const openPicker = () =>
    PickerModal.open({
      title: i18n.t('screens.meeting.booking.picker.label.period'),
      data: PERIODS.map((p) => ({ label: i18n.t(`screens.meeting.booking.range.${p.label}`), value: p.label })),
      onItemSelected: (value) => {
        const per = PERIODS.find((p) => p.label === value)

        if (per) {
          setFieldValue('start', setHours(values.start, per.start))
          setFieldValue('end', setHours(values.end, per.end))
          setFieldValue('period', value)
        }
      },
      selected: values.period,
    })

  const openStartTimePicker = () =>
    TimePickerModal.open({
      time: values.start,
      onTimeSelected: (next: Date) => {
        // if next start is after end, we change end
        if (isAfter(next, values.end)) {
          setFieldValue('end', min([endOfDay(next), addMinutes(next, duration)]))
        }

        setFieldValue('start', next)
      },
      title: i18n.t('screens.meeting.booking.picker.label.startTime'),
    })

  const openEndTimePicker = () =>
    TimePickerModal.open({
      time: values.end,
      onTimeSelected: (next: Date) => {
        // next is a before start, we change start
        if (isAfter(values.start, next)) {
          setFieldValue('start', max([startOfDay(values.end), subMinutes(next, duration)]))
        }

        setFieldValue('end', next)
      },
      title: i18n.t('screens.meeting.booking.picker.label.endTime'),
    })

  return (
    <>
      <PickerButton onClick={openStartDatePicker}>
        <PickerLabel>{i18n.t('screens.meeting.booking.picker.label.start')}</PickerLabel>
        <PickerValue>{i18n.t('screens.meeting.booking.picker.value.start', { date: values.start })}</PickerValue>
      </PickerButton>

      <PickerButton
        onClick={() => setFieldValue('range', !values.range)}
        aria-label={i18n.t(`accessibility.ariaLabels.booking.customPeriodSwitch${values.range ? 'On' : 'Off'}`)}>
        <PickerLabel>{i18n.t('screens.meeting.booking.picker.label.range')}</PickerLabel>
        <Switch active={values.range} onClick={() => null} />
      </PickerButton>

      {values.range ? (
        <>
          <PickerButton onClick={openStartTimePicker}>
            <PickerLabel>{i18n.t('screens.meeting.booking.picker.label.startTime')}</PickerLabel>
            <PickerValue>
              {i18n.t('screens.meeting.booking.picker.value.startTime', { date: values.start })}
            </PickerValue>
          </PickerButton>

          <PickerButton onClick={openEndTimePicker}>
            <PickerLabel>{i18n.t('screens.meeting.booking.picker.label.endTime')}</PickerLabel>
            <PickerValue>{i18n.t('screens.meeting.booking.picker.value.startTime', { date: values.end })}</PickerValue>
          </PickerButton>
        </>
      ) : (
        <PickerButton onClick={openPicker}>
          <PickerLabel>{i18n.t('screens.meeting.booking.picker.label.period')}</PickerLabel>
          <PickerValue>{i18n.t(`screens.meeting.booking.range.${values.period}`)}</PickerValue>
        </PickerButton>
      )}
    </>
  )
}

export const SearchForm = ({ initialValues, onFormSubmit }: Props) => {
  const i18n = useI18n()
  const site = useReducer(SiteStore.store, (s) => s.site!)

  const onSubmit = (vals: Values, { setSubmitting }: FormikHelpers<Values>) => {
    setSubmitting(true)
    onFormSubmit(vals).finally(() => setSubmitting(false))
  }

  const { handleSubmit, isSubmitting, setFieldValue, values } = useFormik({
    initialValues,
    onSubmit,
  })

  return (
    <MainContainer>
      <Title>
        {i18n.t(`screens.meeting.booking.title${site && site.alternativeFunctionalityDesign ? '_altHeader' : ''}`)}
      </Title>

      <PickerContainer>
        <SearchFormFields values={values} setFieldValue={setFieldValue} />
      </PickerContainer>

      <ButtonContainer>
        <Button label={i18n.t('screens.meeting.booking.search')} onClick={handleSubmit} disabled={isSubmitting} />
      </ButtonContainer>
    </MainContainer>
  )
}

export default SearchForm

const MainContainer = styled('div')<{ background?: boolean }>`
  background-color: ${(props) => props.theme.colors.background};
  padding: 50px 165px;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 50px 90px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
    ${(props) => props.background && `justify-content:center;`}
  }
`

const PickerContainer = styled('div')`
  flex-direction: row;
  flex-wrap: wrap;
  margin: -20px 0px 0px 0px;
  @media only screen and (max-width: ${breakpoints.small}px) {
    flex-direction: column;
    margin: -10px 0px 0px 0px;
  }
`

const PickerButton = styled('button')`
  flex-direction: column;
  display: -webkit-box;
  cursor: pointer;
  margin: 20px 60px 0px 0px;
  @media only screen and (max-width: ${breakpoints.small}px) {
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0px 0px 0px;
  }
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
`

const PickerLabel = styled('h2')`
  display: flex;
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.secondary};
  margin: 0px 0px 10px;
`

const PickerValue = styled('p')`
  display: flex;
  ${(props) => props.theme.fonts.body};
  @media only screen and (max-width: ${breakpoints.small}px) {
    text-align: right;
  }
  margin: 0px 0px 10px;
`

const ButtonContainer = styled('div')`
  @media only screen and (min-width: ${breakpoints.small}px) {
    width: 330px;
  }
  margin-top: 50px;
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 0px 0px 20px;
  padding: 0;
  flex: 1;
`
