import ws from 'utils/Webservice'

const api = {
  all: (siteId: number) => {
    return ws<RestaurantListV3>(`/restauration?siteId=${siteId}`, 'GET')
  },
  get: (siteId: number, restaurantId: string) => {
    return ws<RestaurantV3>(`/restauration/${restaurantId}?siteId=${siteId}`, 'GET')
  },
}

export default api
