import { createEvent, createStore } from 'effector'

export const actions = {
  setSurveys: createEvent<SurveyWS[]>('setSurveys'),
  resetSurveys: createEvent('resetSurveys'),
}

const initialState: SurveyState = {
  surveys: [],
}

export const store = createStore<SurveyState>(initialState, { name: 'survey' })
  .on(actions.setSurveys, (s, surveys) => ({ ...s, surveys }))
  .on(actions.resetSurveys, (s) => ({ ...s, surveys: initialState.surveys }))
