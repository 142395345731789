import ws from 'utils/Webservice'

const api = {
  schedules: () => {
    return ws<Schedules>(`/schedules`, 'GET')
  },
  schedule: (id: string) => {
    return ws<ScheduleData>(`/schedules/${id}`, 'GET')
  },
  search: (siteId: number, body: RoomPlanningSearchRequest) => {
    return ws<RoomPlanningSearchResponse>(`/search/office?siteId=${siteId}`, 'POST', body)
  },
  referentiels: (siteId: number) => {
    return ws<ReferentielList>(`/contrib/referentiels?siteId=${siteId}`, 'GET')
  },
  getReservations: () => {
    return ws<Schedules>(`/schedules`, 'GET')
  },
  deskBooking: {
    createReservation: (body: RoomReservation) => {
      return ws<OfficeReservationResponse>(`/reservation/office`, 'POST', body)
    },
    deleteReservation: (id: string, email: string) => {
      const body: RoomEmail = {
        email,
      }
      return ws(`/reservation/${id}`, 'DELETE', body)
    },
  },
}

export default api
