import * as React from 'react'

const DEBOUNCE_DELAY = 300

const useDebounce = (val: string) => {
  const [current, setCurrent] = React.useState(val)
  const [bounced, setBounced] = React.useState(val)

  React.useEffect(() => {
    const timer = setTimeout(() => setBounced(current), DEBOUNCE_DELAY)
    return () => clearTimeout(timer)
  }, [current])

  return [current, bounced, setCurrent] as const
}

export default useDebounce
