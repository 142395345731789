import React from 'react'
import styled from 'styled-components'

import useI18n from 'i18n/useI18n'
import useNavigation from 'core/src/layout/useNavigation'

import Animators from 'components/animation/Animator'
import Main from 'components/main/Main'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Button from 'components/button/Button'
import Loader from 'components/status/Loader'

import { breakpoints } from 'utils/breakpoints'

import { htmlAccessibilityFR } from './accessibility_fr.js'
import { htmlAccessibilityEN } from './accessibility_en.js'

const format = (htmlString: string) =>
  htmlString.replace(/<p><br>\n<\/p>/g, '').replace(/href/g, "target='_blank' href")

const AccessibilityScreen = () => {
  const i18n = useI18n()
  const navigation = useNavigation()
  const [accessibilityContent, setAccessibilityContent] = React.useState('')

  React.useEffect(() => {
    setAccessibilityContent(i18n.lang === 'fr' ? htmlAccessibilityFR : htmlAccessibilityEN)
  }, [i18n.lang])

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={i18n.t('screens.accessibility.title')} />

        {accessibilityContent === '' ? (
          <Loader />
        ) : (
          <Animators>
            <Title>{i18n.t('screens.accessibility.title')}</Title>
            <Content dangerouslySetInnerHTML={{ __html: format(accessibilityContent) }} />
            <ButtonContainer>
              <Button label={i18n.t('common.back')} onClick={navigation.goBack} />
            </ButtonContainer>
          </Animators>
        )}
      </ScreenContainer>
    </Main>
  )
}

export default AccessibilityScreen

const ScreenContainer = styled('div')`
  padding: 50px 100px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h1};
  margin: 0px 0px 30px;
`

const Content = styled('div')`
  font-family: 'Loreal Essentielle';
`

const ButtonContainer = styled('div')`
  margin: 40px;
  align-self: center;
  width: 400px;
  @media only screen and (max-width: ${breakpoints.medium}px) {
    width: 300px;
  }
`
