import * as React from 'react'
import styled from 'theme/styled-components'

import EditoItem from 'practicalinformation/EditoItem'
import ContentItem from 'webview/ContentItem'

import Animator from 'components/animation/Animator'
import Loader from 'components/status/Loader'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'
import EmptyLabel from 'components/label/EmptyLabel'
import Tree from 'components/button/Tree'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as EditoStore from 'practicalinformation/store'
import * as SiteStore from 'site/store'

import api from 'practicalinformation/api'

import { breakpoints } from 'utils/breakpoints'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

const EditosScreen = () => {
  const i18n = useI18n()

  const infos = useReducer(EditoStore.store, (s) => s.infos)
  const site = useReducer(SiteStore.store, (s) => s.site!)

  const [loading, setLoading] = React.useState(false)
  const displayedInfos = React.useMemo(() => infos.filter((n) => n.folder === 'mobility'), [infos])

  React.useEffect(() => {
    analytics.screen({ screen_feature: values.screens.move, screen_name: values.screens.mobility })
  }, [])

  React.useEffect(() => {
    setLoading(true)

    api
      .all(site.id)
      .then((l) => EditoStore.actions.setInfos(l.practicalInformations ? l.practicalInformations : []))
      .catch(EditoStore.actions.reset)
      .finally(() => setLoading(false))
  }, [site])

  if (loading) {
    return <Loader />
  }

  const renderItem = (item: PracticalInformationSimple) => {
    // Ici, item peut être une page éditoriale interne ou un lien vers une webview
    if (item.type === 'CONTENT') {
      return <EditoItem key={item.id} edito={item} />
    }
    return (
      <ContentItem key={item.id} content={item} feature="move" folder={item.folder as PracticalInformationFolder} />
    )
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={i18n.t('screens.mobility.title')} />
        <Tree
          previousPages={[
            {
              url: 'move',
              title: `screens.move.title${site.alternativeFunctionalityDesign ? '_alt' : ''}`,
            },
          ]}
          currentPageTitle={i18n.t(`screens.mobility.title${site.alternativeFunctionalityDesign ? '_alt' : ''}`)}
        />
        <Header>
          <Title>{i18n.t('screens.mobility.title')}</Title>
        </Header>

        <ContentContainer>
          {displayedInfos.length === 0 ? (
            <EmptyLabel label={i18n.t('screens.news.empty')} />
          ) : (
            <Animator isList gap={30}>
              {displayedInfos.map(renderItem)}
            </Animator>
          )}
        </ContentContainer>
      </ScreenContainer>
    </Main>
  )
}

export default EditosScreen

const ContentContainer = styled('div')`
  padding: 30px 100px;
`

const ScreenContainer = styled('div')`
  display: flex;
  padding: 50px 70px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const Header = styled('div')`
  flex-direction: row;
  margin: 20px 0;

  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    flex-direction: column;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.primaryDark};
  flex: 1;
  margin: 0px;
`
