export const htmlAccessibilityEN = `
<!DOCTYPE html>
<html lang="en" dir="LTR">
  <head>
    <meta http-equiv="CONTENT-TYPE" content="text/html; charset=utf-8" />
    <title>Accessibility English</title>
  </head>

  <body>
    <div>
      <p>
        <span style="font-size: 12pt; font-family: CenturyGothic">
          This page is not a help page.
          <br />
          It aims to present the general level of accessibility of the "L'Oréal Smart Campus" site.
        </span>
      </p>
      <h2 style="font-size: 18pt; font-family: CenturyGothic; font-weight: 700; color: rgb(64, 64, 64)">
        What is digital accessibility?
      </h2>
      <p>
        <span style="font-size: 12pt; font-family: CenturyGothic">
          Digital accessibility is a set of rules and good practices that cover functional, graphic, technical and
          editorial aspects.
        </span>
        <br />
        <span style="font-size: 12pt; font-family: CenturyGothic">
          Following these rules and best practices ensures that digital media (websites, mobile applications, PDF
          documents, etc.) are accessible to people with disabilities.
        </span>
      </p>
      <p>
        <span style="font-size: 12pt; font-family: CenturyGothic"> An accessible site allows for example to: </span>
      </p>
      <ul>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">
            Customize its display via the operating system and/or the browser (enlarge or shrink characters, change
            typography, change colors, stop animations, etc.).
          </span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">
            Navigate using assistive technologies such as text-to-speech or Braille.
          </span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">
            Navigate without using the mouse, with the keyboard only, switches or via a touch screen.
          </span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">
            View video and audio content with subtitles and/or transcripts.
          </span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic"> Etc. </span>
        </li>
      </ul>
      <h2 style="font-size: 18pt; font-family: CenturyGothic; font-weight: 700; color: rgb(64, 64, 64)">
        Accessibility statement
      </h2>
      <p>
        <span style="font-size: 12pt; font-family: CenturyGothic">
          L'Oréal is committed to making its websites (internet, intranet and extranet), mobile applications, software
          packages and digital street furniture accessible in accordance with article 47 of law n°2005-102 of February
          11, 2005.
        </span>
        <br />
        <span style="font-size: 12pt; font-family: CenturyGothic">
          This accessibility statement applies to the "Smart Campus" website.
        </span>
      </p>
      <h3 style="font-size: 15pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Compliance status
      </h3>
      <p>
        <span style="font-size: 12pt; font-family: CenturyGothic">
          The "Smart Campus" site is partially compliant with the
          <a href="https://www.numerique.gouv.fr/publications/rgaa-accessibilite/">RGAA</a
          ><span lang="fr"> (Référentiel Général d'Amélioration de l'Accessibilité) </span> (version 4.1) due to
          some non-conformities.
        </span>
      </p>
      <h3 style="font-size: 15pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Test results
      </h3>
      <p>
        <span style="font-size: 12pt; font-family: CenturyGothic">
          A macroscopic accessibility audit was conducted in December 2021 and again in April 2022 by
          <a href="https://ideance.net/fr/">Ideance</a> company.
        </span>
        <br />
        <span style="font-size: 12pt; font-family: CenturyGothic">
          This audit revealed that the site is partially compliant with the RGAA (version 4.1).
        </span>
      </p>
      <h3 style="font-size: 15pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Establishment of this accessibility statement
      </h3>
      <span style="font-size: 12pt; font-family: CenturyGothic"> This statement was established on 28/12/2021 and updated on 19/04/2022. </span>
      <p></p>
      <h4 style="font-size: 12pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Technologies used for the realization of the site
      </h4>
      <ul>
        <li>
          <span style="font-size: 12pt; font-family: CenturyGothic">HTML5<br /></span>
        </li>
        <li>
          <span style="font-size: 12pt; font-family: CenturyGothic">CSS<br /></span>
        </li>
        <li><span style="font-size: 12pt; font-family: CenturyGothic">JavaScript</span></li>
      </ul>
      <h4 style="font-size: 12pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Test environment
      </h4>
      <p>
        <span style="font-size: 12pt; font-family: CenturyGothic">
          The tests were performed with the following web browser and screen reader combinations:
        </span>
      </p>
      <ul>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Firefox 94.0.2 and NVDA 2021.2.</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Firefox 94.0.2 and JAWS 2021.</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Safari and VoiceOver (iOS 15.0.1).</span>
        </li>
      </ul>

      <h4 style="font-size: 12pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Tools to evaluate the accessibility
      </h4>
      <ul>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Colour Contrast Analyser</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Contrast Finder</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Firefox Development Tools</span>
        </li>
        <li>
          <span style="font-size: 12pt; font-family: CenturyGothic">Web Developer (Firefox extension)</span>
        </li>
      </ul>
      <h4 style="font-size: 12pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Website pages subject to the compliance audit
      </h4>

      <ul>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Campus Home Page</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">News</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Catering</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Restaurant "The Cantine"</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">L'Oréal Directory</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Map</span>
        </li>
      </ul>
    </div>
    <div>
      <h3 style="font-size: 15pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Feedback and contact
      </h3>
      <span style="font-size: 12pt; font-family: CenturyGothic">
        If you are unable to access any content or service on this site, you may contact us via one of the means below
        to be directed to an accessible alternative or obtain the content in in another form.
      </span>
      <ul>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Write to us at the following email address: </span>
          <a href="mailto:i-services@loreal.com">i-services@loreal.com</a>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">Call us on this number: 01 85 14 95 18.</span>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">
            Write to us by mail at the following address:
            <br />
            Monsieur Frédéric DEXMIER
            <br />
            41 Rue Martre
            <br />
            92117 Clichy
          </span>
        </li>
      </ul>
      <h3 style="font-size: 15pt; font-family: CenturyGothic; font-weight: 700; color: rgb(44, 62, 80)">
        Methods of appeal
      </h3>
      <span style="font-size: 12pt; font-family: CenturyGothic">
        This procedure is to be used in the following case.
      </span>
      <br />
      <span style="font-size: 12pt; font-family: CenturyGothic">
        You have notified the webmaster of a lack of accessibility that prevents you from accessing content or services
        and you have not received a satisfactory response.
      </span>
      <ul>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <a href="https://formulaire.defenseurdesdroits.fr/">Write a message to the Human Rights Defender.</a>
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <a href="https://www.defenseurdesdroits.fr/saisir/delegues">
            Contact the delegate of the Human Rights Defender in your region.</a
          >
        </li>
        <li style="font-size: 12pt; font-family: CenturyGothic">
          <span style="font-size: 12pt; font-family: CenturyGothic">
            Send a letter (free of charge, do not put a stamp) to the following address:
            <br />Défenseur des droits <br />
            Libre réponse 71120 <br />
            75342 Paris CEDEX 07
          </span>
        </li>
      </ul>
    </div>
  </body>
</html>
`
