import msal from 'authent365/azure/MSALAuth'
import { DirectoryUser, SearchUser } from './types'

const api = {
  search: (search: string) =>
    msal.executeGraphRequest<SearchUser>(
      `/users?$search="givenName:${search}" OR "surname:${search}" OR "mail:${search}" OR "displayName:${search}"&$filter=userType ne 'guest'&$select=id,surname,givenName,mail,userType,officeLocation,businessPhones,displayName,jobTitle,mobilePhone,companyName,department`,
      'GET'
    ),

  searchByEmail: (email: string) =>
    msal.executeGraphRequest<SearchUser>(
      `/users?$search="mail:${email}"&$select=id,surname,givenName,mail,displayName`,
      'GET'
    ),

  getUserFromId: (userId: string) => msal.executeGraphRequest<DirectoryUser>(`/users/${userId}`, 'GET'),

  getUsers: (ids: string[]) =>
    msal.executeGraphRequest<SearchUser>(
      '/directoryObjects/getByIds?$select=id,surname,givenName,mail,userType,officeLocation,businessPhones,displayName,jobTitle,mobilePhone,companyName,department',
      'POST',
      {
        ids,
      }
    ),
}

export default api
