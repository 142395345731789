import * as React from 'react'
import styled from 'theme/styled-components'

import Button from 'components/button/Button'
import Occupancy from 'components/label/Occupancy'
import ButtonIconLabel from 'components/button/ButtonIconLabel'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as UserStore from 'store/user/user'
import * as SiteStore from 'site/store'

import { userIsResident } from 'meeting/booking/utils'

interface Props {
  poi: Referentiel
  occupancy?: OccupancyStatus
  onItinerary: () => void
  onBook: () => void
}

const DESK_TYPES = ['Bureau flex', 'Bureau non flex ouvert', 'Bureau fermé', 'Bureau préventeur']
const UNBOOKABLE_TYPES = ['Salle de réunion']

const PoiDetail = ({ poi, onBook, onItinerary, occupancy }: Props) => {
  const i18n = useI18n()
  const user = useReducer(UserStore.store, (s) => s.user!)
  const site = useReducer(SiteStore.store, (s) => s.site!)

  const isResident = !!user.officeLocation ? userIsResident(user.officeLocation, site.code) : false
  const isBookable =
    !UNBOOKABLE_TYPES.includes(poi.referencialType || '') &&
    (!DESK_TYPES.includes(poi.referencialType || '') || isResident || poi.isVisitor)

  const { data, capacity } = poi

  const name = (data && data[`libelle_${i18n.lang}`]) || poi.room || poi.reference
  const desc = data && data[`description_${i18n.lang}`]
  const hours = data && data[`horaires_${i18n.lang}`]
  const equip = data && data[`equipements_${i18n.lang}`]
  const contactWording = data && data[`contact_libelle_${i18n.lang}`]
  const email = data && data[`contact_email_${i18n.lang}`]

  return (
    <MainContainer>
      <Name>{name}</Name>
      <>
        {occupancy?.status && <Occupancy status={occupancy.status} color={occupancy.color} />}
        {desc && <Info>{desc}</Info>}
        {hours && <Info>{hours}</Info>}
        {equip && <Info>{equip.split(' - ').join(', ')}</Info>}
        {capacity && <Info>{i18n.t('components.capacity.value', { count: capacity })}</Info>}
        {contactWording && email && (
          <>
            <Info>{contactWording}</Info>
            <EmailLink href={`mailto:${email}`} target="_blank">
              {email}
            </EmailLink>
          </>
        )}
      </>

      {!!poi.bookable && !!poi.email && isBookable && (
        <ButtonContainer>
          <Button label={i18n.t('screens.map.poi.book')} onClick={onBook} />
        </ButtonContainer>
      )}
      <ButtonContainer>
        <ButtonIconLabel label={i18n.t('screens.map.poi.goTo')} onClick={onItinerary} iconName="route" />
      </ButtonContainer>
    </MainContainer>
  )
}

export default PoiDetail

const MainContainer = styled('div')`
  padding: 0 20px 20px;
`

const Name = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px 0px 5px;
`

const Info = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.iconicGrey};
  margin: 0px 0px 5px;
`

const EmailLink = styled('a')`
  ${(props) => props.theme.fonts.subtitle};

  user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  -webkit-user-select: text;
  cursor: pointer;

  margin: 0px 0px 5px;
`

const ButtonContainer = styled('div')`
  margin-top: 25px;
  display: block;
`
