import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'
import IconButton from 'components/icons/IconButton'
import Button from 'components/button/Button'
import Checkbox from 'components/button/Checkbox'
import InfiniteList from 'components/list/InfiniteList'

import useI18n from 'i18n/useI18n'

import useDebounce from 'utils/useDebounce'
import sanitize from 'utils/Sanitizer'
import { RouteNavigation } from './visioweb'
import { RouteAction } from './types'
import { parseReference } from './utils'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

type OpenType = 'from' | 'to' | undefined

interface Props {
  pois: Referentiel[]
  poi?: Referentiel
  nav?: RouteNavigation
  onRoute: (action: RouteAction) => Promise<void>
}

const Route = ({ pois, poi, nav, onRoute }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const [searchFrom, bouncedFrom, setSearchFrom] = useDebounce('')
  const [searchTo, bouncedTo, setSearchTo] = useDebounce('')

  const [inputFromClicked, setInputFromClicked] = React.useState(false)
  const [inputToClicked, setInputToClicked] = React.useState(false)

  const [open, setOpen] = React.useState<OpenType>()

  const [from, setFrom] = React.useState<Referentiel | undefined>()
  const [to, setTo] = React.useState<Referentiel | undefined>(poi)
  const [pmr, setPmr] = React.useState(false)

  const [step, setStep] = React.useState(-1)

  const steps = React.useMemo(() => (nav ? nav.getNbInstructions() : 0), [nav])
  const instructions = React.useMemo(
    () => (nav ? Array.from(new Array(steps)).map((_, idx) => nav.getInstructionData(idx)) : []),
    [nav, steps]
  )

  const itemsFrom = React.useMemo(
    () =>
      pois.filter((p) => {
        const name = (p.data && p.data[`libelle_${i18n.lang}`]) || p.room || p.reference

        return sanitize(name).includes(sanitize(bouncedFrom))
      }),
    [pois, bouncedFrom]
  )
  const itemsTo = React.useMemo(
    () =>
      pois.filter((p) => {
        const name = (p.data && p.data[`libelle_${i18n.lang}`]) || p.room || p.reference

        return sanitize(name).includes(sanitize(bouncedTo))
      }),
    [pois, bouncedTo]
  )

  React.useEffect(() => {
    setSearchFrom('')
    setSearchTo('')
  }, [open])

  React.useEffect(() => {
    if (nav) {
      setStep(0)
    } else {
      setStep(-1)
    }
  }, [nav])

  const next = () => {
    if (nav && step < steps - 1) {
      nav.displayNextInstruction()
      setStep(step + 1)
    }
  }

  const previous = () => {
    if (nav && step > 0) {
      nav.displayPrevInstruction()
      setStep(step - 1)
    }
  }

  const onItemSelected = (item: Referentiel) => {
    if (open === 'from') {
      setFrom(item)
      setSearchFrom('')
      setInputFromClicked(false)
    } else if (open === 'to') {
      setTo(item)
      setSearchTo('')
      setInputToClicked(false)
    }

    setOpen(undefined)
  }

  const switchPositions = () => {
    setTo(from)
    setFrom(to)
  }

  const startRoute = () => {
    if (from && to) {
      analytics.event({
        event_feature: values.eventName.map,
        event_action: values.actions.trajet,
        event_object_id: from.reference,
      })
      onRoute({ action: 'start', from: parseReference(from), to: parseReference(to), pmr })
    }
  }

  const stopRoute = () => {
    onRoute({ action: 'stop' })
  }

  const renderItem = React.useCallback(
    (item: Referentiel) => {
      const name = (item.data && item.data[`libelle_${i18n.lang}`]) || item.room || item.reference

      return (
        <Item tabIndex={0} key={item.id} onClick={() => onItemSelected(item)}>
          <Icon name="pin" size={24} color={Theme.colors.iconicGrey} />

          {name}
        </Item>
      )
    },
    [open]
  )

  const renderSearch = (open: OpenType, inputClicked: boolean) => {
    const searchLength = open === 'from' ? searchFrom.length : searchTo.length
    const items = open === 'from' ? itemsFrom : itemsTo
    return (
      <>
        {items.length === 0 ? (
          <Error>{i18n.t('screens.map.search.noResult')}</Error>
        ) : (
          (searchLength > 0 || inputClicked) && (
            <Items>
              <InfiniteList
                data={items}
                renderItem={(item) => renderItem(item)}
                height={60}
                size={{ height: 250, width: 350 }}
              />
            </Items>
          )
        )}
      </>
    )
  }

  const handleInputClicked = (name: 'from' | 'to') => {
    setInputFromClicked(name === 'from')
    setInputToClicked(name === 'to')
  }

  const renderPosition = (name: 'from' | 'to', pos?: Referentiel) => {
    const poiName = (pos?.data && pos?.data[`libelle_${i18n.lang}`]) || pos?.room || pos?.reference || ''

    const [searchValue, setSearch, inputClicked] =
      name === 'from' ? [searchFrom, setSearchFrom, inputFromClicked] : [searchTo, setSearchTo, inputToClicked]

    return (
      <PositionItem
        onClick={() => setOpen(name)}
        aria-label={i18n.t(`accessibility.ariaLabels.mapDrawer.${name === 'to' ? 'end' : 'start'}DeskSelector`)}>
        <PositionInfos>
          <PositionTitle>
            <label htmlFor={`idSearchRoom${name === 'to' ? 'End' : 'Start'}`}>
              {i18n.t(`screens.map.route.${name}`)}
            </label>
          </PositionTitle>
          <SearchContainer role="search">
            <InputContainer search={searchValue} clicked={inputClicked}>
              <Input
                autoFocus
                tabIndex={0}
                id={`idSearchRoom${name === 'from' ? 'Start' : 'End'}`}
                value={searchValue || poiName}
                onChange={(evt) => setSearch(evt.target.value)}
                onClick={() => {
                  handleInputClicked(name)
                  setSearch(poiName)
                }}
                placeholder={i18n.t('screens.map.search.holder')}
                title={i18n.t('accessibility.titleInputText.searchBar')}
                type="text"
              />
              {searchValue.length > 0 && (
                <IconContainer>
                  <IconButton
                    name="cross"
                    size={20}
                    color={Theme.colors.iconicGrey}
                    onClick={() => {
                      setSearchFrom('')
                      setSearchTo('')
                      open === 'from' ? setFrom(undefined) : setTo(undefined)
                    }}
                    ariaLabel={i18n.t('accessibility.ariaLabels.deleteSearchInput')}
                  />
                </IconContainer>
              )}
            </InputContainer>
          </SearchContainer>

          <OpenContainer open={open === name && (searchValue.length > 0 || inputClicked)}>
            {renderSearch(open, inputClicked)}
          </OpenContainer>
        </PositionInfos>
      </PositionItem>
    )
  }

  const renderForm = () => (
    <>
      {renderPosition('from', from)}

      <Switch>
        <IconButton
          name="arrow_updown"
          size={24}
          onClick={switchPositions}
          color={Theme.colors.middleGrey}
          height={22}
          ariaLabel={i18n.t('accessibility.ariaLabels.mapDrawer.switchStartEndRoute')}
        />
      </Switch>

      {renderPosition('to', to)}

      <CheckContainer>
        <Checkbox
          active={pmr}
          onClick={() => setPmr(!pmr)}
          label={i18n.t('screens.map.route.pmr')}
          ariaLabel={i18n.t(`accessibility.ariaLabels.mapDrawer.checkBoxPMR${pmr ? '' : 'Not'}Checked`)}
        />
      </CheckContainer>

      <ButtonContainer>
        <Button label={i18n.t('screens.map.poi.goTo')} onClick={startRoute} disabled={!from || !to} />
      </ButtonContainer>
    </>
  )

  const renderNav = () => {
    if (!nav || step < 0) {
      return null
    }

    const current = instructions[step]

    if (!current) {
      return null
    }

    const isFirstStep = step === 0
    const isLastStep = step === steps - 1

    return (
      <>
        <Message>{current.detail}</Message>
        <PointList>
          {Array.from(new Array(steps)).map((p, i) => (
            <Point key={i} active={i === step} />
          ))}
        </PointList>

        <NavigationLine>
          <NavigationButton
            tabIndex={isFirstStep ? -1 : 0}
            prev
            disable={isFirstStep}
            onClick={previous}
            aria-label={i18n.t('accessibility.ariaLabels.mapDrawer.navigationPreviousStep')}>
            <Icon name="chevron_right" size={15} color={Theme.colors.primaryDark} />
          </NavigationButton>
          <NavigationButton
            tabIndex={isLastStep ? -1 : 0}
            disable={isLastStep}
            onClick={next}
            aria-label={i18n.t('accessibility.ariaLabels.mapDrawer.navigationNextStep')}>
            <Icon name="chevron_right" size={15} color={Theme.colors.primaryDark} />
          </NavigationButton>
        </NavigationLine>

        <ButtonContainer>
          <Button label={i18n.t('screens.map.route.stop')} onClick={stopRoute} />
        </ButtonContainer>
      </>
    )
  }

  return <MainContainer>{!!nav ? renderNav() : renderForm()}</MainContainer>
}

export default Route

const MainContainer = styled('div')`
  flex: 1;
  border-radius: 12px;
  padding: 20px;
`

const PositionItem = styled('button')`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border: 0px;
  background-color: ${(props) => props.theme.colors.background};
`

const PositionInfos = styled('div')`
  flex: 1;
  flex-direction: column;
  display: flex;
  align-self: flex-start;
`

const PositionTitle = styled('h2')`
  display: flex;
  align-self: flex-start;
  ${(props) => props.theme.fonts.body};
  margin: 0px;
`

const SearchContainer = styled('div')`
  margin: 10px 0px;
  padding: 10px 0;
  max-width: 325px;
`

const InputContainer = styled('div')<{ search: string; clicked: boolean }>`
  border: 1px solid
    ${(props) =>
      props.search.length > 0 || props.clicked ? props.theme.colors.primaryDark : props.theme.colors.lightGrey};
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  padding: 12px 18px;
`

const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`

const Input = styled('input')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.iconicGrey};
  flex: 1;
  margin: 0 10px;
  border: none;
  outline: none;
`

const Items = styled('div')`
  padding: 10px 0;
  flex: 1;
  max-height: -webkit-fill-available;

  display: block;
`

const Item = styled('button')`
  ${(props) => props.theme.fonts.body};
  padding: 10px 0px;
  margin: 0px 20px;
  border: 0px;
  border-top-width: 1px;
  background-color: ${(props) => props.theme.colors.background};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
`

const Switch = styled('div')`
  padding: 10px;
  align-items: center;
  border-radius: 50px;
  width: fit-content;
  align-self: center;
  border: 1px solid ${(props) => props.theme.colors.middleGrey};
`

const OpenContainer = styled('div')<{ open: boolean }>`
  height: ${(props) => (props.open ? 314 : 0)}px;
  transition: height 0.5s ease-in-out;
  overflow: hidden;
`

const Error = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 20px;
  text-align: center;
`

const ButtonContainer = styled('div')`
  margin: 20px;
`

const Message = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 20px;
`

const PointList = styled('ul')`
  display: flex;
  list-style: none;
  padding: 0;
  flex-direction: row;
  align-self: center;
  align-items: center;
  margin: 20px;
`

const Point = styled('li')<{ active: boolean }>`
  margin: 0px 10px;
  background-color: ${(props) => (props.active ? props.theme.colors.background : props.theme.colors.disable)};
  height: ${(props) => (props.active ? 15 : 10)}px;
  width: ${(props) => (props.active ? 15 : 10)}px;
  border-radius: 15px;
  border: ${(props) => (props.active ? 1 : 0)}px solid ${(props) => props.theme.colors.primaryDark};
`

const NavigationLine = styled('div')`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`

const NavigationButton = styled('button')<{ prev?: boolean; disable: boolean }>`
  height: 60px;
  width: 60px;
  border-radius: 60px;
  background-color: ${(props) => (props.disable ? props.theme.colors.disable : props.theme.colors.secondaryLighter)};
  transform: rotate(${(props) => (props.prev ? '180deg' : '0deg')});
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.17));

  border: 0px;

  :hover {
    cursor: pointer;
  }
`

const CheckContainer = styled('div')`
  margin: 20px;
`
