import axios from 'axios'

const api = {
  cgu: (lang: string) => {
    return axios
      .request<string>({ url: `/cgu/CGU_${lang}.html`, method: 'GET' })
      .then((res) => res.data)
  },
}

export default api
