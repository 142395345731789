import { Store, createEvent } from 'effector'
import Storage from './storage'

import * as UserStore from 'store/user/user'
import * as ReferentielStore from 'store/referentiel/referentiel'
import * as SiteStore from 'site/store'
import * as NewsStore from 'news/store'
import * as BookingStore from 'meeting/booking/store'
import * as CGUStore from 'cgu/store'
import * as HomeStore from 'home/store'
import * as DirectoryStore from 'directory365/store'
import * as TransportStore from 'transport/store'
import * as SurveyStore from 'survey/store'
import * as RegisterStore from 'register/store'

const stores: Store<any>[] = [
  // Mettre là les stores qui doivent être persistés
  // auth.store,
  SiteStore.store,
  UserStore.store,
  ReferentielStore.referentielStore,
  NewsStore.store,
  BookingStore.store,
  CGUStore.store,
  HomeStore.store,
  DirectoryStore.store,
  TransportStore.store,
  SurveyStore.store,
  RegisterStore.store,
]

export const persist = (): Promise<void> => {
  const VERSION = '3'
  const VERSION_KEY = 'persist:version'

  return Storage.getItem(VERSION_KEY).then((version) => {
    return (version === VERSION ? Promise.resolve(true) : Storage.setItem(VERSION_KEY, VERSION).then(() => false)).then(
      (reload) => {
        // inspiré de https://github.com/MrFranke/effector-persist/blob/master/src/index.ts
        const rehydrate = createEvent('REHYDRATE')

        const persistStore = <State>(store: Store<State>): Promise<void> => {
          const name = store.shortName
          const persistKey = `persist:${name}`

          return (reload
            ? Storage.getItem(persistKey).then((snapshot) => {
                if (snapshot) {
                  store.on(rehydrate, () => JSON.parse(snapshot))
                  rehydrate()
                }
              })
            : Promise.resolve()
          ).then(() => {
            store.watch((state) => {
              Storage.setItem(persistKey, JSON.stringify(state))
            })
          })
        }

        return Promise.all(stores.map(persistStore)).then()
      }
    )
  })
}

export const reset = () => {
  // auth.actions.resetAuth()
  SiteStore.actions.resetSite()
  UserStore.actions.resetUser()
  ReferentielStore.actions.reset()
}
