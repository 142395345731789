import { createEvent, createStore } from 'effector'

export const actions = {
  accept: createEvent<string>('setVersion'),
  reset: createEvent('reset'),
}

interface CGUState {
  accepted: string[]
}

const initialState: CGUState = {
  accepted: [],
}

export const store = createStore<CGUState>(initialState, { name: 'cgu' })
  .on(actions.accept, (s, user) => ({ ...s, accepted: [...s.accepted, user] }))
  .on(actions.reset, () => initialState)
