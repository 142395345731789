import * as React from 'react'
import styled from 'theme/styled-components'

import { CLOSED_WIDTH } from 'core/src/layout/Drawer'

import { breakpoints } from 'utils/breakpoints'
import useBreakpoint from 'utils/useBreakpoints'

export interface Section {
  id: string
  label: string
}

interface Props {
  sections: Section[]
  onSectionClick: (section: Section) => void
  selectedSection: Section
}

const SECTION_WIDTH = 155
const MARGIN = 70

const SectionsList = ({ sections, selectedSection, onSectionClick }: Props) => {
  const [size] = useBreakpoint()

  const maxWidth = size.width - MARGIN

  return (
    <MainContainer maxWidth={maxWidth}>
      {sections.map((section) => {
        const isSelected = selectedSection.id === section.id
        return (
          <SectionContainer
            key={section.id}
            isSelected={isSelected}
            onClick={() => onSectionClick(section)}
            maxWidth={maxWidth / sections.length}>
            <SectionTitle isSelected={isSelected}>{section.label}</SectionTitle>
          </SectionContainer>
        )
      })}
    </MainContainer>
  )
}

export default SectionsList

const MainContainer = styled('div')<{ maxWidth: number }>`
  background-color: ${(props) => props.theme.colors.contentBackground};
  padding: 4px;
  flex-direction: row;
  border-radius: 28px;
  max-width: ${(props) => props.maxWidth - CLOSED_WIDTH}px;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    max-width: ${(props) => props.maxWidth}px;
  }
`

const SectionContainer = styled('div')<{ isSelected: boolean; maxWidth: number }>`
  cursor: pointer;
  width: ${(props) => Math.min(SECTION_WIDTH, props.maxWidth)}px;
  border-radius: 28px;
  padding: 12px;
  align-items: center;
  justify-content: center;
  ${(props) => props.isSelected && 'border-radius: 28px;'}
  ${(props) => props.isSelected && `background-color: ${props.theme.colors.primaryDark};`}
  transition: all 600ms cubic-bezier(0.15, 0.3, 0.25, 1);
`

const SectionTitle = styled('p')<{ isSelected: boolean }>`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => (props.isSelected ? props.theme.colors.white : props.theme.colors.primary)};
  text-align: center;
`
