import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'
import { DirectoryUser } from './types'
import { getNameToDisplay } from './utils'

import useReducer from 'store/useReducer'
import * as DirectoryScreen from './store'
import useI18n from 'i18n/useI18n'

import UserPicture from 'authent365/UserPicture'
import IconButton from 'components/icons/IconButton'

interface Props {
  user: DirectoryUser
  isInFavoriteSection?: boolean
}

const UserInfoCell = ({ user, isInFavoriteSection = true }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const { officeLocation, id } = user

  const favorites = useReducer(DirectoryScreen.store, (s) => s.favorites)
  const isFavorite = favorites.includes(id)

  const toggleFavorite = () => {
    if (isFavorite) {
      const updatedFav = favorites.filter((f) => f !== id)
      DirectoryScreen.actions.setFav(updatedFav)
    } else {
      DirectoryScreen.actions.setFav([...favorites, id])
    }
  }

  return (
    <Container aria-label={!!user.displayName ? user.displayName : undefined}>
      <UserPicture size={70} user={user} />

      {!isInFavoriteSection && (
        <UserInfosContainer>
          <UserFullName>{getNameToDisplay(user)}</UserFullName>
          {officeLocation && <OfficeLocation>{officeLocation}</OfficeLocation>}
        </UserInfosContainer>
      )}

      {!isInFavoriteSection && (
        <div onClick={(evt) => evt.stopPropagation()} onKeyDown={(evt) => evt.stopPropagation()}>
          <StyledIcon
            onClick={toggleFavorite}
            name={isFavorite ? 'star_filled' : 'star'}
            size={20}
            color={Theme.colors.functionalities.phonebook}
            ariaLabel={i18n.t(`accessibility.ariaLabels.directory.favIcon${isFavorite ? '' : 'Not'}Selected`)}
          />
        </div>
      )}
    </Container>
  )
}

// CONTAINERS

const Container = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: start;
`
const UserInfosContainer = styled('div')`
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: flex-start;
  padding: 0 15px;
`
const StyledIcon = styled(IconButton)`
  padding: 6px;
  cursor: pointer;
`

// TEXTES

const UserFullName = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
  display: flex;
  margin: 0px;
`

const OfficeLocation = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
  flex: 1;
  color: ${(props) => props.theme.colors.iconicGrey};
  text-align: flex-start;
`

export default UserInfoCell
