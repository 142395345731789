import * as React from 'react'
import styled from 'styled-components'

import useReducer from 'store/useReducer'
import * as TransportStore from './store'
import * as SitesStore from 'site/store'
import DirectionItem from './DirectionItem'

import useI18n from 'i18n/useI18n'

import { breakpoints } from 'utils/breakpoints'

interface Props {
  displayedStations: TransportStation[] | undefined
}

const FavoriteTransports = ({ displayedStations }: Props) => {
  const i18n = useI18n()

  const site = useReducer(SitesStore.store, (s) => s.site)
  const favorites = useReducer(TransportStore.store, (s) => s.favorites).filter((fav) => fav.siteId === site?.id)

  const favoriteStations = Array.from(new Set(favorites.map((f) => f.station)))

  const isAvailableFavorite = React.useMemo(
    () =>
      favorites.filter(
        (fav) =>
          !!displayedStations?.find(
            (s) => s.name === fav.station && s.line === fav.line && s.schedules[0].direction === fav.direction
          )
      ),
    [favorites, displayedStations]
  )

  const renderFavoriteContent = () => {
    if (isAvailableFavorite.length < 1) {
      // Aucun horaire de transport favori n'est disponible
      return <MessageText>{i18n.t('screens.transport.errors.noFavoriteSchedule')}</MessageText>
    }
    const items = favorites.filter((fav) => fav.siteId === site?.id)
    const linesDetails = items.map((item) => getLineDetails(item))
    return <ListContainer>{linesDetails.map(renderFavoriteLine)}</ListContainer>
  }

  const getLineDetails = (fav: FavoriteDirection) => {
    // On retrouve les infos de la ligne dans la liste des stations disponibles
    const { station, line, direction, category } = fav
    return displayedStations?.find(
      (s) => s.name === station && s.line === line && s.category === category && s.schedules[0].direction == direction
    )
  }

  const renderFavoriteLine = (lineDetails: TransportStation | undefined, index: number) => {
    if (!!lineDetails) {
      return (
        <li key={index}>
          <FavoriteContainer>
            <DirectionItem lineDetails={lineDetails} index={index} />
          </FavoriteContainer>
        </li>
      )
    }
  }

  return (
    <>
      {!(!displayedStations || displayedStations?.length < 1 || favoriteStations.length < 1) && (
        <>
          <DrawerTitle>
            <label>{i18n.t('screens.transport.favorites')}</label>
          </DrawerTitle>
          {renderFavoriteContent()}
        </>
      )}
    </>
  )
}

export default FavoriteTransports

// CONTAINERS

const FavoriteContainer = styled('div')`
  padding: 5px;
  display: grid;
`

// LISTS

const ListContainer = styled('ol')`
  display: grid;
  width: 90%;
  gap: 10px;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  list-style: none;
  padding: 0 0 20px 20px;
`

// TEXTES

const DrawerTitle = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px 0px 12px 22px;
`

const MessageText = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
  margin: 0px 22px;
  padding: 10px 0px;
`
