import * as React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'theme/styled-components'
import icons from './icons'

interface Props {
  name: IconName
  color?: string
  size?: number
  height?: number
  width?: number
  cursor?: 'default' | 'pointer'
}

const Icon = ({ name, size = 20, color, height, width, cursor = 'default' }: Props) => {
  const iconSrc = icons[name || 'heart']

  return (
    <IconContainer color={color} cursor={cursor}>
      <SVG
        aria-hidden
        focusable="false"
        src={iconSrc}
        preProcessor={(code) =>
          code
            .replace(/ width=".*?"/g, ` width="${width || size}"`)
            .replace(/ height=".*?"/g, ` height="${height || size}"`)
        }
      />
    </IconContainer>
  )
}

export default Icon

const IconContainer = styled('div')<{ color?: string; cursor: string }>`
  ${(props) => !!props.color && `color: ${props.color};`}
  &:hover {
    cursor: ${(props) => props.cursor};
  }
`
