import * as React from 'react'

import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

import { Link } from 'react-router-dom'

interface PageLink {
  title: string
  url: string
}

interface Props {
  currentPageTitle: string
  previousPages: PageLink[]
}

const Tree = ({ currentPageTitle, previousPages }: Props) => {
  const i18n = useI18n()

  const renderPageLink = ({ url, title }: PageLink) => (
    <PreviousPageLink
      key={url}
      replace
      to={`/${url}`}
      aria-label={i18n.t('accessibility.ariaLabels.tree.goBackLink', {
        previousPage: i18n.t(title),
      })}>{`${i18n.t(title)} /`}</PreviousPageLink>
  )

  return (
    <Line>
      {previousPages.map(renderPageLink)}
      <CurrentPageText>{currentPageTitle}</CurrentPageText>
    </Line>
  )
}

export default Tree

const Line = styled('div')`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
`

const CurrentPageText = styled('span')`
  ${(props) => props.theme.fonts.body};
  margin: 0;
  color: ${(props) => props.theme.colors.primary};
`

const PreviousPageLink = styled(Link)`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primary};
  margin: 0 4px 0 0;
  text-decoration: none;
  :hover {
    opacity: 0.6;
  }
`
