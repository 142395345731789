import { createEvent, createStore } from 'effector'

export interface CateringState {
  instants: InstantSimplified[]
}

export const actions = {
  setInstants: createEvent<InstantSimplified[]>('setInstants'),
  resetInstants: createEvent('resetInstants'),
}

const initialState: CateringState = {
  instants: [],
}

export const store = createStore<CateringState>(initialState, { name: 'catering' })
  .on(actions.setInstants, (s, instants) => ({ ...s, instants }))
  .on(actions.resetInstants, (s) => ({ ...s, instants: initialState.instants }))
