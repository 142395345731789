import * as React from 'react'
import styled from 'theme/styled-components'

import { List } from 'react-virtualized'

interface Props<T> {
  data: T[]
  renderItem: (item: T, idx: number) => React.ReactElement
  height: number
  size?: { width: number; height: number }
}

const InfiniteList: <T>(props: Props<T>) => React.ReactElement = ({
  data,
  height,
  size = { width: 0, height: 0 },
  renderItem,
}) => {
  const container = React.useRef<HTMLDivElement>(null)
  const [containerSize, setContainerSize] = React.useState(size)

  const renderRow = ({ index, key, style }: { index: number; key: string; style: any }) => (
    <li key={key} style={style}>
      {renderItem(data[index], index)}
    </li>
  )

  React.useEffect(() => {
    if (container.current) {
      setContainerSize({
        height: size.height || container.current.clientHeight,
        width: size.width || container.current.clientWidth,
      })
    }
  }, [])

  if (containerSize.height === 0) {
    return <Empty ref={container} />
  }

  return (
    <StyledList>
      <List
        tabIndex={-1}
        width={containerSize.width}
        height={containerSize.height}
        rowRenderer={renderRow}
        rowCount={data.length}
        rowHeight={height}
      />
    </StyledList>
  )
}

export default InfiniteList

const Empty = styled('div')`
  height: 100%; // use percent because of display block
  width: 100%;
`

const StyledList = styled('ul')`
  list-style: none;
  padding: 0;
`
