import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'

interface Props {
  label: string
  icon?: IconName
}

const EmptyLabel = ({ label, icon }: Props) => {
  const [theme] = useTheme()

  return (
    <EmptyContainer>
      {!!icon && (
        <IconContainer>
          <Icon name={icon} size={52} color={theme.colors.primaryDark} />
        </IconContainer>
      )}
      <Empty>{label}</Empty>
    </EmptyContainer>
  )
}

export default EmptyLabel

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 52px 0px;
  align-items: center;
  justify-content: center;
`

const Empty = styled.p`
  ${(props) => props.theme.fonts.h3};
  font-size: 20px;
  text-align: center;
`

const IconContainer = styled.div`
  padding: 32px;
  margin-right: 24px;
  background-color: ${(props) => props.theme.colors.contentBackground};
  border-radius: 12px;
`
