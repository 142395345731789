import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'

interface Props {
  label: string
  icon: IconName
  font?: FontName
  iconSize?: number
  iconColor?: string
}

const Category = ({ label, icon, font = 'body', iconSize = 17, iconColor }: Props) => {
  const [theme] = useTheme()

  return (
    <Container>
      <Icon name={icon} color={iconColor || theme.colors.primaryDark} size={iconSize} />
      <Label font={font}>{label}</Label>
    </Container>
  )
}

export default Category

const Container = styled('div')`
  flex-direction: row;
  align-items: center;
`

const Label = styled('p')<{ font: FontName }>`
  ${(props) => props.theme.fonts[props.font]};
  color: ${(props) => props.theme.colors.primaryText};
  text-decoration: none;
  padding: 0px;
  margin: 0px 0px 0px 5px;
`
