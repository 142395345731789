import * as React from 'react'

import { Helmet } from 'react-helmet'
import useI18n from 'i18n/useI18n'

interface Props {
  title: string
}

const TitleHelmet = ({ title }: Props) => {
  const i18n = useI18n()
  return (
    <Helmet>
      <title>
        {title} - {i18n.t('app.name')}
      </title>
    </Helmet>
  )
}

export default TitleHelmet
