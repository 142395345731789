import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import Category from 'components/label/Category'
import EmptyLabel from 'components/label/EmptyLabel'
import SectionHeader from 'components/label/SectionHeader'
import Icon from 'components/icons/Icon'
import Loader from 'components/status/Loader'

import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as CateringStore from './store'
import * as SiteStore from 'site/store'

import { isBefore } from 'date-fns'

import { Link } from 'react-router-dom'

import Logger from 'utils/Logger'
import { capitalize } from 'utils/stringUtils'
import { formatDate } from './utils'

import api from './api'

interface Option {
  title: string
  data: InstantSimplified[]
}

interface Props {
  showPastInstants: boolean
}

const MyInstantsList = ({ showPastInstants }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const sites = useReducer(SiteStore.store, (s) => s.sites)
  const instants = useReducer(CateringStore.store, (s) => s.instants)

  const [status, setStatus] = React.useState<ScreenStatus>('loading')
  const [currentInstants, setCurrentInstants] = React.useState<Option[]>()
  const [pastInstants, setPastInstants] = React.useState<Option[]>()

  const displayedList = React.useMemo(() => {
    if (!showPastInstants && !!currentInstants && currentInstants.length > 0) {
      return currentInstants
    }
    if (showPastInstants && !!pastInstants && pastInstants.length > 0) {
      return pastInstants
    }
  }, [showPastInstants, currentInstants, pastInstants])

  React.useEffect(() => {
    api
      .getCateringInstants()
      .then(({ instants }) => {
        CateringStore.actions.setInstants(instants)
        setStatus('ok')
      })
      .catch((err) => {
        Logger.error(err)
        setStatus('error')
      })
  }, [])

  const groupInstantsByDate = (instants: InstantSimplified[], decreasing = false) => {
    const groups = Array.from(new Set(instants.map(({ date }) => date)))
    return groups.map((date) => ({
      title: capitalize(i18n.t('screens.meeting.catering.list.dayMonth', { date: new Date(date) })),
      data: instants
        .filter((instant) => instant.date === date)
        .sort((a, b) =>
          decreasing ? b.startingTime.localeCompare(a.startingTime) : a.startingTime.localeCompare(b.startingTime)
        ),
    }))
  }

  React.useEffect(() => {
    // On trie les instants par date et on sépare les instants passés de ceux à venir
    const sorted = instants
      .sort((a, b) => a.date.localeCompare(b.date))
      .reduce(
        (acc, cur) => {
          const now = new Date()
          const curEnd = formatDate(cur.date, cur.endingTime)
          if (isBefore(curEnd, now)) {
            // Réservation passée
            return { ...acc, past: [...acc.past, cur] }
          } else {
            // Réservation en cours ou à venir
            return { ...acc, current: [...acc.current, cur] }
          }
        },
        { current: [], past: [] } as { current: InstantSimplified[]; past: InstantSimplified[] }
      )
    setCurrentInstants(groupInstantsByDate(sorted.current))
    setPastInstants(groupInstantsByDate(sorted.past.reverse(), true))
  }, [instants])

  const renderItem = (item: InstantSimplified, index: number) => {
    const site = sites.find((s) => s.id.toString() === item.siteId)?.name

    const start = formatDate(item.date, item.startingTime)
    const end = formatDate(item.date, item.endingTime)

    const canceled = item.status === 'CANCELED'

    return (
      <li key={item.id}>
        <Item
          canceled={canceled}
          style={{ pointerEvents: 'auto', cursor: 'pointer' }}
          to={`/meeting/catering/${item.id}`}
          aria-label={i18n.t('accessibility.ariaLabels.register.goToReservation', { title: item.instantType.name })}>
          <Infos>
            <ItemDate>{i18n.t('screens.meeting.catering.list.timeSlot', { start, end })}</ItemDate>

            <ItemTitle canceled={canceled}>{item.instantType.name}</ItemTitle>
            {canceled && <ItemTitleStatus>{i18n.t('screens.meeting.catering.detail.canceled')}</ItemTitleStatus>}

            <DetailTitleContainer>
              <Category
                icon="pin_alt"
                label={
                  !!site
                    ? i18n.t('screens.meeting.catering.list.place', { site, place: item.referentiel })
                    : item.referentiel
                }
                font="body"
                iconSize={20}
                iconColor={theme.colors.iconicGrey}
              />
              <Category
                icon="groups2"
                label={item.numberGuests + ' ' + i18n.t('screens.meeting.catering.form.fieldTitle.guests')}
                font="body"
                iconSize={24}
                iconColor={theme.colors.iconicGrey}
              />
            </DetailTitleContainer>
          </Infos>
          <Icon name="chevron_right" size={20} color={theme.colors.functionalities.meeting} />
        </Item>
      </li>
    )
  }

  const renderSection = (section: { title: string; data: InstantSimplified[] }) => {
    if (!section || !section.data || section.data.length === 0) {
      return <></>
    }

    return (
      <SectionContainer key={section.title}>
        <SectionHeader
          aria-label={section.title}
          title={section.title}
          textColor={theme.colors.functionalities.meeting}
          borderColor={theme.colors.background}
        />
        <List>{section.data.map(renderItem)}</List>
      </SectionContainer>
    )
  }

  return (
    <div>
      {status === 'loading' ? (
        <Loader />
      ) : status === 'error' ? (
        <EmptyLabel label={i18n.t('screens.meeting.catering.list.error')} icon="desk_alt" />
      ) : !displayedList ? (
        <EmptyLabel label={i18n.t('screens.meeting.catering.list.empty')} icon="desk_alt" />
      ) : (
        <List>{displayedList.map(renderSection)}</List>
      )}
    </div>
  )
}

export default MyInstantsList

// SECTIONS LIST

const List = styled('ul')`
  list-style: none;
  padding: 0;
  margin: 0;
`

const SectionContainer = styled('li')`
  background-color: ${(props) => props.theme.colors.background};
  margin-bottom: 10px;
  padding: 0 20px;
`

// ITEMS LIST

const Item = styled(Link)<{ canceled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  box-shadow: 0px 2px 3px 0px rgba(146, 146, 146, 0.25);
  ${(props) => props.canceled && 'opacity: 0.8'};
`

const Infos = styled('div')`
  display: flex;
  flex: 1;
  text-align: start;
  flex-direction: column;
  justify-content: center;
`

const Text = styled('p')`
  margin: 0px;
  padding: 0px;
`

const ItemDate = styled(Text)`
  ${(props) => props.theme.fonts.body};
  background-color: ${(props) => props.theme.colors.contentBackground};
  font-size: 15px;
  padding: 8px 12px;
  border-radius: 4px;
  align-self: flex-start;
`

const ItemTitle = styled(Text)<{ canceled?: boolean }>`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => (props.canceled ? props.theme.colors.iconicGrey : props.theme.colors.primaryText)};
  margin: 20px 0px ${(props) => (props.canceled ? 0 : 16)}px;
  line-height: 19px;
  ${(props) => props.canceled && 'text-decoration-line: line-through'};
  text-decoration-thickness: 1px;
`

const ItemTitleStatus = styled(Text)`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0 0 12px;
  line-height: 19px;
`

const DetailTitleContainer = styled('div')`
  flex-direction: row;
  gap: 30px;
`
