import { createEvent, createStore } from 'effector'

export const actions = {
  setReferentiels: createEvent<Referentiel[]>('setReferentiels'),
  setOccupancies: createEvent<OccupancyList>('setOccupancies'),
  resetOccupancies: createEvent('resetOccupancies'),
  resetReferentiels: createEvent('resetReferentiels'),
  reset: createEvent('reset'),
}

const initialReferentielState: ReferentielState = {
  referentiels: [],
}

const initialOccupancyState: OccupancyState = {
  occupancies: {},
}

export const referentielStore = createStore<ReferentielState>(initialReferentielState, { name: 'referentiel' })
  .on(actions.setReferentiels, (s, referentiels) => ({ ...s, referentiels }))
  .on(actions.resetReferentiels, (s) => ({ ...s, referentiels: initialReferentielState.referentiels }))

export const occupancyStore = createStore<OccupancyState>(initialOccupancyState, { name: 'occupancy' })
  .on(actions.setOccupancies, (s, occupancies) => ({ ...s, occupancies: { ...s.occupancies, ...occupancies } }))
  .on(actions.resetOccupancies, (s) => ({ ...s, occupancies: initialOccupancyState.occupancies }))
