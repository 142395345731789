import { I18n } from 'i18n/i18n'

import { PotentialVisitor } from './types'
import Alert from 'components/alert/Alert'

import { isBefore } from 'date-fns'

export interface Values {
  start: Date
  end: Date
  subject: string
  visitors: PotentialVisitor[]
  companion?: Companion
  isSelfSelected: boolean
}

const sortVisitorsByName = (users: PotentialVisitor[]) =>
  users.sort((a, b) =>
    a.firstName !== b.firstName ? a.firstName.localeCompare(b.firstName) : a.lastName.localeCompare(b.lastName)
  )

const onSubmitUtils = (values: Values, i18n: I18n, onSuccessFormFunction: () => void, isCreating: boolean) => {
  if (values.visitors.length === 0) {
    Alert.open({ title: i18n.t('errors.form.title'), description: i18n.t('screens.guest.minVisitor') })
  } else if (isCreating && isBefore(values.start, new Date())) {
    Alert.open({ title: i18n.t('errors.form.title'), description: i18n.t('screens.guest.startDatePast') })
  } else if (!values.companion) {
    Alert.open({ title: i18n.t('errors.form.title'), description: i18n.t('screens.guest.companionNotSelected') })
  } else if (values.subject.trim() === '') {
    Alert.open({ title: i18n.t('errors.form.title'), description: i18n.t('screens.guest.meetingNameNotSelected') })
  } else if (values.companion) {
    onSuccessFormFunction()
  } else {
    Alert.open({ title: i18n.t('errors.form.title'), description: i18n.t('errors.form.unknown') })
  }
}

export { sortVisitorsByName, onSubmitUtils }
