import * as React from 'react'

import Screens from './Screens'

import { useHistory } from 'react-router-dom'
import { History } from 'history'

interface Navigation {
  push: (name: string, state?: { [key: string]: any }) => void
  goBack: () => void
  navigate: (name: string, state?: { [key: string]: any }) => void
  addListener: (listener: LocationListener) => UnregisterCallback
  screens: { [key: string]: ScreenDefinition }
}

const enhanceNavigator = (h: History<any>): Navigation => {
  return {
    push: h.push,
    goBack: h.goBack,
    navigate: h.push,
    addListener: h.listen,
    screens: Screens,
  }
}

const useNavigation = () => {
  const history = useHistory<any>()
  const nav = React.useMemo(() => enhanceNavigator(history), [history])

  return nav
}

export default useNavigation
