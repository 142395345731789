import { createEvent, createStore } from 'effector'
import { MyVisitsState } from './types'

export const actions = {
  setUserDetails: createEvent<User>('setUserDetails'),
  resetUserDetails: createEvent('resetUserDetails'),
}

const initialState: MyVisitsState = {
  userDetail: undefined,
}

export const store = createStore<MyVisitsState>(initialState, { name: 'guest' })
  .on(actions.setUserDetails, (s, userDetail) => ({ ...s, userDetail }))
  .on(actions.resetUserDetails, () => initialState)
