import { createEvent, createStore } from 'effector'

export const actions = {
  setInfos: createEvent<PracticalInformationSimple[]>('setInfos'),
  reset: createEvent('reset'),
}

const initialState: PracticalInformationState = {
  infos: [],
}

export const store = createStore<PracticalInformationState>(initialState, { name: 'practicalinformation' })
  .on(actions.setInfos, (s, infos) => ({ ...s, infos }))
  .on(actions.reset, () => ({ ...initialState }))
