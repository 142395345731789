import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import { Link } from 'react-router-dom'

import Icon from 'components/icons/Icon'

import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'
import * as UserStore from 'store/user/user'
import * as RestaurantStore from 'restaurant/store'
import * as SurveyStore from 'survey/store'
import * as RegisterStore from 'register/store'
import * as SafeAtWorkStore from 'safeatwork/store'
import * as EditoStore from 'practicalinformation/store'

import RestaurantApi from 'restaurant/api'
import SurveyApi from 'survey/api'
import RegisterApi from 'register/api'
import InfoApi from 'practicalinformation/api'

import Logger from 'utils/Logger'
import { filterSurveys } from 'survey/utils'
import { filterAnimations } from 'register/utils'
import { capitalize } from 'utils/stringUtils'
import { isAfter, isToday, isTomorrow, compareDesc } from 'date-fns'
import { breakpoints } from 'utils/breakpoints'
import useWebsocket from 'utils/useWebsocket'

import config from 'core/src/config'

interface Props {
  siteId: number
  onHeightChange: (height: number) => void
}

type FeatureType = 'restaurant' | 'survey' | 'register' | 'info'

const MAX_RESTAURANT_HOUR = 14

const HomeHeader = ({ siteId, onHeightChange }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const user = useReducer(UserStore.store, (s) => s.user)
  const site = useReducer(SiteStore.store, (s) => s.site)
  const restaurants = useReducer(RestaurantStore.store, (s) => s.restaurants)
  const restaurantsAttendances = useReducer(RestaurantStore.store, (s) => s.attendances)
  const surveys = useReducer(SurveyStore.store, (s) => s.surveys)
  const allAnimations = useReducer(RegisterStore.store, (s) => s.animations)
  const safeInfos = useReducer(SafeAtWorkStore.store, (s) => s.infos)
  const practicalInfos = useReducer(EditoStore.store, (s) => s.infos)

  const [status, setStatus] = React.useState<ScreenStatus>('loading')
  const [animations, setAnimations] = React.useState<AnimationDetail[]>([])
  const [infos, setInfos] = React.useState<PracticalInformationDetails[]>([])

  const onOccupancy = (d: { type: string; data: RestaurantAttendanceData[]; delta?: boolean }) => {
    if (d.type === 'influx') {
      // On ne traite que les changements d'occupation du site (si précisé)
      const siteData = !!site?.name
        ? d.data.find((occ) => occ.siteName === site.name)?.restaurants
        : d.data.map((s) => s.restaurants).flat()

      if (!!siteData && siteData.length > 0) {
        // delta=true : on ajoute les nouvelles données sans écraser les anciennes
        // delta=false : on écrase les anciennes données avec les nouvelles
        const lastData = d.delta ? RestaurantStore.store.getState().attendances : {}
        const newData = siteData.reduce(
          (acc, cur) => ({ ...acc, [cur.restaurantID]: cur }),
          {} as RestaurantsAttendances
        )
        RestaurantStore.actions.setAttendances({ ...lastData, ...newData })
      }
    }
  }

  const [, , connect, disconnect] = useWebsocket<RestaurantAttendanceData[]>(config.WSS_URL, onOccupancy)

  const isMorning = new Date().getHours() < MAX_RESTAURANT_HOUR

  const name = React.useMemo(() => (!!user ? user.givenName : ''), [user])

  const citation = React.useMemo(
    () => (!!site ? site.quotes[Math.floor(Math.random() * Math.floor(site.quotes.length))] : undefined),
    [site, i18n.lang]
  )

  const animationsToDisplay = React.useMemo(
    () =>
      allAnimations
        .filter((anim) => anim.status === 'CREATED')
        .sort((a, b) => a.slotFirstDate.localeCompare(b.slotFirstDate))
        .slice(0, 2),
    [allAnimations]
  )

  const infosToDisplay = React.useMemo(
    () =>
      [...safeInfos, ...practicalInfos]
        .filter((info) => !!info.creationDate)
        .sort((a, b) => compareDesc(new Date(a.creationDate), new Date(b.creationDate)))
        .slice(0, 2),
    [safeInfos, practicalInfos]
  )

  const feature: FeatureType | undefined = React.useMemo(
    () =>
      isMorning
        ? 'restaurant'
        : surveys.length > 0
        ? 'survey'
        : animations.length > 0
        ? 'register'
        : infos.length > 0
        ? 'info'
        : undefined,
    [isMorning, infos]
  )

  const featureIcon: IconName | undefined = React.useMemo(() => {
    switch (feature) {
      case 'restaurant':
        return 'restaurant_alt'
      case 'survey':
        return 'survey'
      case 'register':
        return 'register'
      case 'info':
        return 'info_alt'
      default:
        return undefined
    }
  }, [feature])

  React.useEffect(() => {
    if (feature === 'restaurant') {
      // start websocket
      connect()

      // stop websocket
      return () => disconnect()
    }
  }, [feature])

  React.useEffect(() => {
    Promise.all([
      RestaurantApi.all(siteId),
      SurveyApi.surveysList(siteId),
      RegisterApi.getAll(siteId, 'CREATED'),
      InfoApi.all(siteId, 'SAFE@WORK'),
      InfoApi.all(siteId, 'PRACTICAL_INFORMATION'),
    ])
      .then(([restaurants, { surveys }, { animations }, safeInfosList, practicalInfosList]) => {
        RestaurantStore.actions.setRestaurants(restaurants)
        SurveyStore.actions.setSurveys(filterSurveys(surveys))
        RegisterStore.actions.setAnimations(filterAnimations(animations))
        SafeAtWorkStore.actions.setInfos(safeInfosList.practicalInformations)
        EditoStore.actions.setInfos(practicalInfosList.practicalInformations)
        setStatus('ok')
      })
      .catch((err) => {
        Logger.error(err)
        setStatus('error')
      })
  }, [site])

  React.useEffect(() => {
    if (!!animationsToDisplay) {
      Promise.all(animationsToDisplay.map(({ id }) => RegisterApi.getDetail(id)))
        .then((res) => setAnimations(res.filter((animation) => !animation.isFull)))
        .catch(Logger.error)
    }
  }, [animationsToDisplay])

  React.useEffect(() => {
    if (!!infosToDisplay) {
      Promise.all(infosToDisplay.map(({ id, type }) => InfoApi.get(id, type)))
        .then(setInfos)
        .catch(Logger.error)
    }
  }, [infosToDisplay])

  React.useEffect(() => {
    // On récupère la hauteur du composant
    const height = document.getElementById('homeHeaderContainer')?.offsetHeight || 0
    onHeightChange(height)
  }, [status, feature])

  const renderRestaurant = (item: RestaurantV3) => {
    const attendance = restaurantsAttendances[item.id]

    return (
      <FeatureItem key={item.id} to={`restaurants/${item.id}`} width={380}>
        <ColumnContainer>
          <ItemTitle>{item.name}</ItemTitle>
          <ItemSubtitle>{i18n.t('screens.home.featureDetail.openingHour', { hour: MAX_RESTAURANT_HOUR })}</ItemSubtitle>
        </ColumnContainer>

        {!!attendance ? (
          <DetailContainer>
            <DetailLabel fontSize={15}>
              {attendance.waitingTimes !== undefined
                ? i18n.t('screens.home.featureDetail.waitingTime', { count: attendance.waitingTimes })
                : i18n.t('screens.home.featureDetail.attendance', { count: attendance.attendanceRate })}
            </DetailLabel>
            <Icon name="rate" size={20} cursor="pointer" color={Theme.colors.primaryDark} />
          </DetailContainer>
        ) : (
          <Icon name="restaurant" size={20} cursor="pointer" color={Theme.colors.primaryDark} />
        )}
      </FeatureItem>
    )
  }
  const renderSurvey = (item: SurveyWS) => (
    <FeatureItem key={item.id} to={`survey/${item.id}`} width={300}>
      <ColumnContainer>
        {!!item.category && <ItemSubtitle>{i18n.t(`screens.survey.tags.${item.category}`)}</ItemSubtitle>}
        <ItemTitle>{item.title}</ItemTitle>
      </ColumnContainer>
    </FeatureItem>
  )

  const renderAnimation = (item: AnimationDetail) => {
    const nextSlot = item.slots
      .filter((slot) => !slot.limitUser || !slot.numberUser || slot.numberUser < slot.limitUser)
      .sort((a, b) => a.startDate.localeCompare(b.startDate))
      .find((slot) => isAfter(new Date(slot.startDate), new Date()))

    if (item.isFull || !nextSlot) {
      return <></>
    }

    const start = new Date(nextSlot.startDate)

    return (
      <FeatureItem key={item.id} to={`register/${item.id}`} width={450}>
        <ColumnContainer>
          <ItemSubtitle isDark>{`${capitalize(
            i18n.t(`common.day.${isToday(start) ? 'today' : isTomorrow(start) ? 'tomorrow' : 'other'}`, { date: start })
          )} - ${item.place}`}</ItemSubtitle>
          <ItemTitle>{item.name}</ItemTitle>
        </ColumnContainer>

        <DetailContainer>
          <DetailLabel fontSize={16}>
            {i18n.t('screens.home.featureDetail.timeSlot', { start, end: new Date(nextSlot.endDate) })}
          </DetailLabel>
        </DetailContainer>
      </FeatureItem>
    )
  }

  const renderInfoContent = (title: string, infoFeature: 'practicalinformation' | 'safeAtWork') => (
    <ColumnContainer>
      <ItemSubtitle>{i18n.t(`screens.${infoFeature}.title`)}</ItemSubtitle>
      <ItemTitle>{title}</ItemTitle>
    </ColumnContainer>
  )

  const renderInfo = (item: PracticalInformationDetails) => {
    const infoFeature: ScreenName = ['safetynews', 'security', 'training'].includes(item.folder)
      ? 'safeAtWork'
      : 'practicalinformation'

    if (item.type === 'CONTENT') {
      return (
        <FeatureItem key={item.id} width={230} to={`${infoFeature}/${item.id}`}>
          {renderInfoContent(item.title, infoFeature)}
        </FeatureItem>
      )
    }
    return (
      <FeatureItemA key={item.id} width={230} href={item.link || item.pdf || ''} target="_blank" rel="noreferrer">
        {renderInfoContent(item.title, infoFeature)}
      </FeatureItemA>
    )
  }

  const renderFeature = () => {
    switch (feature) {
      case 'restaurant':
        return restaurants.slice(0, 2).map(renderRestaurant)
      case 'survey':
        return surveys.slice(0, 2).map(renderSurvey)
      case 'register':
        return animations.slice(0, 2).map(renderAnimation)
      case 'info':
        return infos.slice(0, 2).map(renderInfo)
      default:
        return <></>
    }
  }

  return (
    <MainContainer id="homeHeaderContainer">
      <HelloTitle>{i18n.t('screens.home.hello', { name })}</HelloTitle>

      {!!citation && (
        <>
          <Citation>{`“${citation.quote}”`}</Citation>
          <Author>{citation.author}</Author>
        </>
      )}

      {status === 'ok' && !!feature && (
        <FeatureContainer>
          <FeatureTitleContainer>
            {!!featureIcon && <Icon name={featureIcon} size={24} color={Theme.colors.homeFeature} />}
            <FeatureTitle>{i18n.t(`screens.home.feature.${feature}`)}</FeatureTitle>
          </FeatureTitleContainer>

          <FeatureList>{renderFeature()}</FeatureList>
        </FeatureContainer>
      )}
    </MainContainer>
  )
}

export default HomeHeader

const MainContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  box-shadow: 0px 2px 108px 0px ${(props) => props.theme.colors.transparentMiddleGrey};

  background-color: ${(props) => props.theme.colors.transparentWhite};
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px);
`

// Header texts

const HelloTitle = styled('p')`
  ${(props) => props.theme.fonts.h2Bold};
`

const Citation = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  font-style: italic;
  margin: 24px 0px 8px;
`

const Author = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
`

// Feature content

const FeatureContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const FeatureTitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 36px 0 22px;
`

const FeatureTitle = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  margin-left: 8px;
`

const FeatureList = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 12px;
`

const itemStyle = `
  display: flex;
  flex: 1;
  overflow: hidden;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  min-width: 0;
  border-radius: 12px;
  transition: all 0.1s ease-in-out;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`

const FeatureItem = styled(Link)<{ width?: number }>`
  ${itemStyle};
  ${(props) => props.width && `max-width: ${props.width}px`};
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  background: ${(props) => props.theme.colors.background};

  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.contentBackground};
  }
`

const FeatureItemA = styled('a')<{ width?: number }>`
  ${itemStyle};
  ${(props) => props.width && `max-width: ${props.width}px`};
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  background: ${(props) => props.theme.colors.background};

  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.contentBackground};
  }
`

const ColumnContainer = styled('div')`
  flex-direction: column;
  overflow: hidden;
  gap: 8px;
`

const OneLineText = styled('p')`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-wrap: break-word;
`

const ItemTitle = styled(OneLineText)`
  ${(props) => props.theme.fonts.bodyBold};
`

const ItemSubtitle = styled(OneLineText)<{ isDark?: boolean }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => (props.isDark ? props.theme.colors.primaryDark : props.theme.colors.iconicGrey)};
  font-size: 15px;
`

// Detail (restaurant attendance / animation hours)

const DetailContainer = styled('div')`
  display: flex;
  flex-direction: row;
  min-width: 100px;
  padding: 16px;
  gap: 8px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.contentBackground};
`

const DetailLabel = styled('p')<{ fontSize: number }>`
  ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.fontSize}px;
`
