import Theme from 'theme/Theme'

const capitalize = (value: string) =>
  value.length > 0 ? value[0].toLocaleUpperCase() + value.slice(1).toLocaleLowerCase() : ''

const clearHtmlStyle = (html: string) =>
  html.replace(new RegExp(' style="[^><]+"'), '').replace(new RegExp('<font [^><]+>'), '')

const getHtmlStyle = (font?: Font) =>
  `<style>
    .bold {
      font-family: ${(font || Theme.fonts.bodyBold).fontFamily};
      font-weight: ${(font || Theme.fonts.bodyBold).fontWeight};
    }
    .small { font-size: 13px }
    .italic { font-style: italic }
  </style>`

export { capitalize, clearHtmlStyle, getHtmlStyle }
