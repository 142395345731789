import { createEvent, createStore } from 'effector'

export const actions = {
  setSites: createEvent<SiteV4[]>('setSites'),
  resetSites: createEvent('resetSites'),
  setSite: createEvent<SiteV4>('setSite'),
  resetSite: createEvent('resetSite'),
  reset: createEvent('reset'),
}

interface SiteState {
  sites: SiteV4[]
  site?: SiteV4
}

const initialState: SiteState = {
  sites: [],
  site: undefined,
}

let loadedSiteValue: SiteV4 | undefined
export const loadedSite = {
  get: () => loadedSiteValue,
}

export const store = createStore<SiteState>(initialState, { name: 'site' })
  .on(actions.setSites, (s, sites) => ({ ...s, sites }))
  .on(actions.resetSites, (s) => ({ ...s, sites: initialState.sites }))
  .on(actions.setSite, (s, site) => {
    loadedSiteValue = site
    return { ...s, site }
  })
  .on(actions.resetSite, (s) => ({ ...s, site: initialState.site }))
  .on(actions.reset, () => ({ ...initialState }))
