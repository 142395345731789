import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'

interface Props {
  onChange: (note: number) => void
  editable?: boolean
  note?: number
}

const renderArray = [...Array(5)]

const Notation = ({ onChange, editable = true, note }: Props) => {
  const [theme] = useTheme()

  const renderStar = (index: number) => (
    <StarContainer key={`star_${index + 1}`} onClick={editable ? () => onChange(index + 1) : undefined}>
      <Icon
        name={!!note && index < note ? 'star_filled' : 'star'}
        size={32}
        color={!!note && index < note ? theme.colors.functionalities.survey : theme.colors.middleGrey}
        cursor="pointer"
      />
    </StarContainer>
  )

  return <StarsContainer>{renderArray.map((_, index) => renderStar(index))}</StarsContainer>
}

export default Notation

const StarsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 240px;
`

const StarContainer = styled('div')`
  cursor: pointer;
`
