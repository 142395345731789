import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

import { breakpoints } from 'utils/breakpoints'

interface Props {
  screenName: ScreenName
  filters: FilterTransport[]
  onClick: (f: FilterTransport) => void
}

const FiltersList = ({ screenName, filters, onClick }: Props) => {
  const i18n = useI18n()

  const [filter, setFilter] = React.useState(filters[0])

  const renderFilter = (f: FilterTransport) => {
    const active = filter === f
    const nameFilter = i18n.t(`screens.${screenName}.filter.${f}`)

    return (
      <li key={f}>
        <FilterButton
          active={active}
          onClick={() => {
            onClick(f)
            setFilter(f)
          }}
          aria-label={i18n.t(`accessibility.ariaLabels.${screenName}.filterButton${active ? 'Activated' : ''}`, {
            filter: nameFilter,
          })}>
          <FilterText active={active}>{nameFilter}</FilterText>
        </FilterButton>
      </li>
    )
  }

  return <Filters>{filters.map(renderFilter)}</Filters>
}

export default FiltersList

const Filters = styled('ul')`
  display: flex;
  list-style: none;
  padding-left: 20px;
  flex-direction: row;
`

const FilterButton = styled('button')<{ active: boolean }>`
  margin-right: 10px;

  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.background};
  border: ${(props) => (props.active ? '2px' : '1px')} solid ${(props) => props.theme.colors.primaryDark};
  border-radius: 20px;
  padding: 8px 20px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    margin-bottom: 10px;
  }
`

const FilterText = styled('p')<{ active: boolean }>`
  ${(props) => (props.active ? props.theme.fonts.bodyBold : props.theme.fonts.body)};
`
