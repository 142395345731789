import * as React from 'react'
import styled from 'theme/styled-components'
import { UserSelf } from 'store/user/user'

import msal from './azure/MSALAuth'
import { blobToB64 } from './utils'

interface Props {
  user: UserSelf
  size: number
}

// Returns first letter of the name in upper case when this is an alphabetical char, empty string otherwise
const getNameInitial = (name: string | null): string =>
  name && name.length > 0 && /^[a-zA-Z]/.test(name[0]) ? name[0].toUpperCase() : ''

const AzureImage = ({ user, size }: Props) => {
  const [src, setSrc] = React.useState('')
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    msal
      .executeGraphRequest<Blob>(`/users/${user.id}/photos/96x96/$value`, 'GET', undefined, 'blob')
      .then(blobToB64)
      .then(setSrc)
      .finally(() => setLoading(false))
  }, [])

  const initials = getNameInitial(user.surname) + getNameInitial(user.givenName)

  return (
    <Container>
      <Holder size={size}>{!loading && <HolderText size={size}>{initials}</HolderText>}</Holder>
      {!!src && <Img size={size} src={src} alt="" />}
    </Container>
  )
}

export default AzureImage

const Container = styled('div')`
  position: relative;
`

const Img = styled('img')<{ size: number }>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  border-radius: ${(props) => props.size}px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`

const Holder = styled('div')<{ size: number }>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  border-radius: ${(props) => props.size}px;
  background-color: ${(props) => props.theme.colors.functionalities.phonebook};
  justify-content: center;
  align-items: center;
`

const HolderText = styled('p')<{ size: number }>`
  ${(props) => props.theme.fonts.h1};
  font-size: ${(props) => props.size / 2}px;
  color: ${(props) => props.theme.colors.white};
`
