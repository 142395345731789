import ws from 'utils/Webservice'

const api = {
  all: (siteId: number, type?: 'PRACTICAL_INFORMATION' | 'SAFE@WORK') => {
    const typeQuery = !!type ? `&typeOfInformation=${type}` : ''
    return ws<PracticalInformationList>(`/practical-informations?siteId=${siteId}${typeQuery}`, 'GET')
  },

  get: (id: string, type: string) =>
    ws<PracticalInformationDetails>(`/practical-informations/${id}`, 'GET', {}, { type }),
}

export default api
