import * as React from 'react'

import ListItemLink from 'components/list/ListItemLink'

interface Props {
  edito: PracticalInformationSimple
  isImageIcon?: boolean
}

const ListItem = ({ edito, isImageIcon }: Props) => {
  return (
    <ListItemLink
      key={edito.id}
      isInternalLink
      imageSource={edito.photo ? edito.photo : require('core/src/assets/news_default.jpeg').default}
      link={`practicalinformation/${edito.id}`}
      isImageIcon={isImageIcon}
      title={edito.title}
      description={edito.description}
    />
  )
}

export default ListItem
