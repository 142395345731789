import firebase from 'firebase/app'

const ENV: Env = (global as any).ENV || 'dev'

const initializeFirebase = () => {
  if (firebase.apps.length === 0) {
    switch (ENV) {
      case 'dev':
        firebase.initializeApp({
          apiKey: 'AIzaSyAyej4cua8-Ikl4XYguYwEtXQBMpDdvvhg',
          authDomain: 'loreal-smart-campus-re7.firebaseapp.com',
          projectId: 'loreal-smart-campus-re7',
          storageBucket: 'loreal-smart-campus-re7.appspot.com',
          messagingSenderId: '518372034824',
          appId: '1:518372034824:web:344742a2fc77ad813104bd',
          measurementId: 'G-1284H69G7G',
        })
        break
      case 're7':
        firebase.initializeApp({
          apiKey: 'AIzaSyAyej4cua8-Ikl4XYguYwEtXQBMpDdvvhg',
          authDomain: 'loreal-smart-campus-re7.firebaseapp.com',
          projectId: 'loreal-smart-campus-re7',
          storageBucket: 'loreal-smart-campus-re7.appspot.com',
          messagingSenderId: '518372034824',
          appId: '1:518372034824:web:242c09d1218cde873104bd',
          measurementId: 'G-C8CBL031M3',
        })
        break
      case 'preprod':
        firebase.initializeApp({
          apiKey: 'AIzaSyCwcT5N4CS91FzKDOvCiqeLLbWttfzfJOs',
          authDomain: 'loreal-smart-campys.firebaseapp.com',
          databaseURL: 'https://loreal-smart-campys.firebaseio.com',
          projectId: 'loreal-smart-campys',
          storageBucket: 'loreal-smart-campys.appspot.com',
          messagingSenderId: '502598230121',
          appId: '1:502598230121:web:fa681cb6e519ab8d5e02f8',
          measurementId: 'G-C9D7MYY8C5',
        })
        break
      case 'release':
        firebase.initializeApp({
          apiKey: 'AIzaSyASWFAPB3QDidXvzl-t8LIq2ZWT2lGo8_E',
          authDomain: 'loreal-smartcampus-prod.firebaseapp.com',
          projectId: 'loreal-smartcampus-prod',
          storageBucket: 'loreal-smartcampus-prod.appspot.com',
          messagingSenderId: '705830857916',
          appId: '1:705830857916:web:a997e989ac64b61f6ab4b9',
          measurementId: 'G-J6K0HTS5BM',
        })
        break
    }
  }
}

export { initializeFirebase }
