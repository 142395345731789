import ws from 'utils/Webservice'

const api = {
  arrivals: (siteId: number) => {
    return ws<TransportStations>(`/transports/${siteId}`, 'GET')
  },

  bikes: (siteId: number) => {
    return ws<BikeStations>(`/bikes/${siteId}`, 'GET')
  },
}

export default api
