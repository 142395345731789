import * as React from 'react'
import styled from 'styled-components'
import { I18n } from 'i18n/i18n'
import { SetFieldValueType } from 'guest/GuestScreen'
import { PotentialVisitor } from 'guest/types'

import useI18n from 'i18n/useI18n'
import useTheme from 'theme/useTheme'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { breakpoints } from 'utils/breakpoints'

import Icon from 'components/icons/Icon'
import Modal from 'components/modal/Modal'
import Button from 'components/button/Button'

const PHONE_REG_EXP = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/ //(0|\\+33|0033)[1-9][0-9]{8}

const formValidationSchema = (i18n: I18n) =>
  Yup.object().shape({
    firstName: Yup.string()
      .min(2, i18n.t('errors.form.minChar', { count: 2 }))
      .required(i18n.t('errors.form.mandatory', { field: i18n.t('screens.guest.visitors.form.firstName') })),
    lastName: Yup.string()
      .min(2, i18n.t('errors.form.minChar', { count: 2 }))
      .required(i18n.t('errors.form.mandatory', { field: i18n.t('screens.guest.visitors.form.lastName') })),
    email: Yup.string()
      .email(i18n.t('errors.form.format'))
      .required(i18n.t('errors.form.mandatory', { field: i18n.t('screens.guest.visitors.form.email') })),
    phoneNumber: Yup.string().matches(PHONE_REG_EXP, i18n.t('errors.form.format')),
  })

interface Props {
  visitors: PotentialVisitor[]
  setFieldValue: SetFieldValueType
  editedVisitor?: PotentialVisitor
}

const VisitorForm = ({ visitors, setFieldValue, editedVisitor }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const submitFunc = (values: Visitor) => {
    if (editedVisitor) {
      const visitorsFiltered = visitors.filter((v) => v.id !== editedVisitor.id)
      setFieldValue('visitors', [...visitorsFiltered, { ...values, id: editedVisitor.id }])
    } else {
      const visitorId = visitors.length > 0 ? Math.max(...visitors.map((v) => v.id)) + 1 : 0
      setFieldValue('visitors', [...visitors, { ...values, id: visitorId }])
    }
    Modal.close()
  }

  const handleDelete = () => {
    if (editedVisitor) {
      const visitorsFiltered = visitors.filter((v) => v.id !== editedVisitor.id)
      setFieldValue('visitors', [...visitorsFiltered])
      Modal.close()
    }
  }

  const { values, handleChange, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      firstName: editedVisitor ? editedVisitor.firstName : '',
      lastName: editedVisitor ? editedVisitor.lastName : '',
      company: editedVisitor ? editedVisitor.company : '',
      email: editedVisitor ? editedVisitor.email : '',
      phoneNumber: editedVisitor ? editedVisitor.phoneNumber : '',
    },
    onSubmit: submitFunc,
    validationSchema: formValidationSchema(i18n),
  })

  return (
    <MainContainer
      id="VisitorFormDialog"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modalVisitorFormHeading"
      tabIndex={-1}>
      <CrossButton onClick={Modal.close} aria-label={i18n.t(`accessibility.ariaLabels.closeModal`)}>
        <Icon name="cross" size={30} cursor="pointer" />
      </CrossButton>

      <FormContainer>
        <Title id="modalVisitorFormHeading">{i18n.t('screens.guest.visitors.form.title')}</Title>

        {/* Nom de famille */}
        <InputLabel error={touched.lastName && !!errors.lastName}>
          <label htmlFor="visitorLastName">{i18n.t('screens.guest.visitors.form.lastName')}</label>
        </InputLabel>
        <InputText
          name="lastName"
          id="visitorLastName"
          autoComplete="family-name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.lastName}
          placeholder={i18n.t('screens.guest.visitors.form.lastNamePlaceholder')}
          autoFocus
          title={i18n.t('accessibility.titleInputText.visitorForm.lastName')}
          type="text"
          required
        />
        <ErrorMessage>{touched.lastName && errors.lastName}</ErrorMessage>

        {/* Prénom */}
        <InputLabel error={touched.firstName && !!errors.firstName}>
          <label htmlFor="visitorFirstName">{i18n.t('screens.guest.visitors.form.firstName')} </label>
        </InputLabel>
        <InputText
          name="firstName"
          id="visitorFirstName"
          autoComplete="given-name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.firstName}
          placeholder={i18n.t('screens.guest.visitors.form.firstNamePlaceholder')}
          title={i18n.t('accessibility.titleInputText.visitorForm.firstName')}
          type="text"
          required
        />
        <ErrorMessage>{touched.firstName && errors.firstName}</ErrorMessage>

        {/* Société */}
        <InputLabel>
          <label htmlFor="visitorCompany">{i18n.t('screens.guest.visitors.form.company')}</label>
        </InputLabel>
        <CompanyInputText
          name="company"
          id="visitorCompany"
          autoComplete="organization"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.company}
          placeholder={i18n.t('screens.guest.visitors.form.companyPlaceholder')}
          title={i18n.t('accessibility.titleInputText.visitorForm.company')}
          type="text"
        />

        {/* Email */}
        <InputLabel error={touched.email && !!errors.email}>
          <label htmlFor="visitorEmail">{i18n.t('screens.guest.visitors.form.email')}</label>
        </InputLabel>
        <InputText
          name="email"
          id="visitorEmail"
          autoComplete="email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          placeholder={i18n.t('screens.guest.visitors.form.emailPlaceholder')}
          title={i18n.t('accessibility.titleInputText.visitorForm.email')}
          type="text"
          required
        />
        <ErrorMessage>{touched.email && errors.email}</ErrorMessage>

        {/* Téléphone */}
        <InputLabel error={touched.phoneNumber && !!errors.phoneNumber}>
          <label htmlFor="visitorPhone">{i18n.t('screens.guest.visitors.form.phoneNumber')}</label>
        </InputLabel>
        <InputText
          name="phoneNumber"
          id="visitorPhone"
          autoComplete="tel"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phoneNumber}
          placeholder={i18n.t('screens.guest.visitors.form.phoneNumberPlaceholder')}
          title={i18n.t('accessibility.titleInputText.visitorForm.phoneNumber')}
          type="text"
        />
        <ErrorMessage>{touched.phoneNumber && errors.phoneNumber}</ErrorMessage>
      </FormContainer>

      {editedVisitor && (
        <DeleteButtonContainer>
          <Button label={i18n.t('common.delete')} onClick={handleDelete} color={Theme.colors.background} />
        </DeleteButtonContainer>
      )}

      <ButtonContainer>
        <Button label={i18n.t('common.validate')} onClick={handleSubmit} />
      </ButtonContainer>
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  position: relative;
  background-color: ${(props) => props.theme.colors.background};
  width: 516px;
  border-radius: 15px;
  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: calc(100vw - 10px);
    margin: 0px 5px;
  }
`

const FormContainer = styled('div')`
  margin: 37px 35px 0px;
  @media only screen and (max-width: ${breakpoints.phone}px) {
    margin: 37px 25px 0px;
  }
`
const ButtonContainer = styled('div')`
  margin: 6px 21px 37px 22px;
`
const DeleteButtonContainer = styled('div')`
  margin: 6px 21px 11px 22px;
`

// TEXTES

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px 0px 20px 0px;
`
const InputLabel = styled('h2')<{ error?: boolean }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => (!props.error ? props.theme.colors.secondary : props.theme.colors.error)};
  margin: 0px 0px 5px 0px;
`
const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.error};
  min-height: 10px;
  margin: 0px 0px 15px 0px;
`

// AUTRES

const CrossButton = styled('button')`
  position: absolute;
  top: 15px;
  right: 11px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.iconicGrey};
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
`

const InputText = styled('input')`
  border: 0;
  outline: 0;
  background-color: ${(props) => props.theme.colors.background};
  ${(props) => props.theme.fonts.body};
  ::placeholder {
    color: ${(props) => props.theme.colors.middleGrey};
  }
`
const CompanyInputText = styled('input')`
  border: 0;
  outline: 0;
  margin-bottom: 25px;
  background-color: ${(props) => props.theme.colors.background};
  ${(props) => props.theme.fonts.body};
  ::placeholder {
    color: ${(props) => props.theme.colors.middleGrey};
  }
`

export default VisitorForm
