import * as React from 'react'

import styled from 'theme/styled-components'
import Provider from 'theme/Provider'
import useTheme from 'theme/useTheme'

import Button from 'components/button/Button'
import CloseModalButton from 'components/button/CloseModalButton'
import Icon from '../icons/Icon'

import useI18n from 'i18n/useI18n'

import { breakpoints } from 'utils/breakpoints'
import useEscKeyPressed from 'utils/useEscKeyPressed'

import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'

interface Option<T extends string> {
  label: string
  description: string
  value: T
  initialQuantity?: number
  minQuantity?: number
}

interface Props<T extends string> {
  title: string
  data: Option<T>[]
  onValidate: (quantities: Record<T, number>) => void
}

const PickerQuantityModal = <T extends string>({ title, data, onValidate }: Props<T>) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const [quantities, setQuantities] = React.useState(
    data.reduce((prev, curr) => ({ ...prev, [curr.value]: curr.initialQuantity || 0 }), {}) as Record<T, number>
  )

  useEscKeyPressed(PickerQuantityPortal.close)

  const save = () => {
    onValidate(quantities)
    PickerQuantityPortal.close()
  }

  const renderItem = (item: Option<T>) => {
    const currentValue = quantities[item.value]
    const addValue = Math.max(currentValue + 1, item.minQuantity || 0)
    const subValue = currentValue - 1 < (item.minQuantity || 0) ? 0 : currentValue - 1
    return (
      <li key={item.value}>
        <ItemContainer>
          <ItemInfosContainer>
            <ItemTitle>{item.label}</ItemTitle>
            <ItemDescription>{item.description}</ItemDescription>
          </ItemInfosContainer>
          <ItemQuantityContainer>
            <ItemQuantityButton onClick={() => setQuantities((prev) => ({ ...prev, [item.value]: subValue }))}>
              <Icon name="less" cursor="pointer" />
            </ItemQuantityButton>
            <ItemQuantityText value={quantities[item.value]} disabled />
            <ItemQuantityButton onClick={() => setQuantities((prev) => ({ ...prev, [item.value]: addValue }))}>
              <Icon name="plus" cursor="pointer" />
            </ItemQuantityButton>
          </ItemQuantityContainer>
        </ItemContainer>
      </li>
    )
  }

  return (
    <FocusLock autoFocus={true} returnFocus>
      <MainContainer>
        <Back />

        <Container
          id="PickerQuantityModalDialog"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modalPickerQuantityHeading"
          tabIndex={-1}>
          <HeaderContainer>
            <Title id="modalPickerQuantityHeading">{title}</Title>
            <CloseModalButton nameIcon="cross" sizeIcon={30} onClick={PickerQuantityPortal.close} />
          </HeaderContainer>

          <List>{data.map(renderItem)}</List>

          <ButtonContainer>
            <Button color={theme.colors.primaryDark} label={i18n.t('common.validate')} onClick={save} />
          </ButtonContainer>
        </Container>
      </MainContainer>
    </FocusLock>
  )
}

const MainContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  opacity: 1;

  -webkit-animation: fadeIn 0.3s linear;
  animation: fadeIn 0.3s linear;
  z-index: 1;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Back = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.backgroundModal};
  border: 0px;
`

const Container = styled('div')`
  display: flex;
  margin: 20px;
  width: 520px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  padding: 30px;
  border-radius: 15px;
  gap: 30px;
  overflow: hidden;
  @media only screen and (max-width: ${breakpoints.small}px) {
    width: calc(100vw - 80px);
    border-radius: 10px;
    gap: 20px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
`

const ButtonContainer = styled('div')`
  align-items: center;
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`

const ItemInfosContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`

const ItemTitle = styled.h5`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 0;
`

const ItemDescription = styled.div`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.iconicGrey};
`

const ItemQuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding-bottom: 24px;
`

const ItemQuantityButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`

const ItemQuantityText = styled.input`
  width: 44px;
  height: 32px;
  background-color: ${(props) => props.theme.colors.contentBackground};
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  border-radius: 8px;
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryDark};
  text-align: center;
`

const List = styled('ul')`
  display: flex;
  flex-direction: column;
  flex: 1;
  list-style: none;
  padding: 0;
  overflow-y: auto;
  gap: 24px;
`

let modalRoot: HTMLElement | null

const PickerQuantityPortal = {
  open: <T extends string>(props: Props<T>) => {
    modalRoot = document.getElementById('portal_root')

    if (modalRoot) {
      ReactDOM.render(
        <Provider>
          <PickerQuantityModal {...props} />
        </Provider>,
        modalRoot
      )
    }
  },
  close: () => {
    if (modalRoot) {
      ReactDOM.unmountComponentAtNode(modalRoot)
    }
  },
}

export default PickerQuantityPortal
