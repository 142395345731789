import * as React from 'react'
import styled from 'styled-components'
import { Values } from './GuestScreen'

import useI18n from 'i18n/useI18n'
import { FormikErrors } from 'formik'

import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'
import CompanionSearch from './modal/CompanionSearch'

interface Props {
  userDetails: User
  companion?: Companion
  isSelfSelected: boolean
  isEditing?: boolean
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<Values>>
}

const CompanionPicker = ({ userDetails, companion, isSelfSelected, isEditing = false, setFieldValue }: Props) => {
  const i18n = useI18n()

  const isCompanion = !!userDetails.isAccompanying
  const [isMyselfSelected, setIsMyselfSelected] = React.useState(!isEditing && isCompanion)

  React.useEffect(() => {
    if (isEditing && !!companion) {
      companionIsUser(userDetails, companion)
        ? setIsMyselfSelected(true)
        : setFieldValue('companion', {
            id: companion.id,
            firstName: companion.firstName,
            lastName: companion.lastName,
            email: companion.email,
          })
    } else {
      if (isCompanion) {
        setFieldValue('companion', {
          id: userDetails.id,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          email: userDetails.email,
        })
      }
    }
  }, [])

  React.useEffect(() => {
    // Is used when the form is reset and the current companion isn't myself
    // Reset to "isMyselfSelected" to true
    if (!isEditing) {
      setIsMyselfSelected(isCompanion ? isSelfSelected : false)
    }
  }, [isSelfSelected])

  const companionIsUser = (user: User, companion: Companion) =>
    user.email === companion.email && user.firstName === companion.firstName && user.lastName === companion.lastName

  const selectMyself = () => {
    setIsMyselfSelected(true)
    setFieldValue('companion', {
      id: userDetails.id,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
    })
    setFieldValue('isSelfSelected', true)
  }

  const selectOther = () => {
    setIsMyselfSelected(false)
    setFieldValue('companion', undefined)
    setFieldValue('isSelfSelected', false)
    openModalSelectCompanion()
  }

  const openModalSelectCompanion = () =>
    Modal.open({ Content: () => <CompanionSearch setFieldValue={setFieldValue} /> })

  return (
    <React.Fragment>
      <Title>{i18n.t('screens.guest.companion.title')}</Title>

      <ButtonAlignContainer>
        {isCompanion && (
          <MyselfButtonContainer>
            <CompanionButton
              selected={isMyselfSelected}
              onClick={selectMyself}
              aria-label={i18n.t(
                `accessibility.ariaLabels.guest.companionIsMyself${isMyselfSelected ? 'Selected' : ''}`
              )}>
              <CompanionButtonLabel selected={isMyselfSelected}>
                {i18n.t('screens.guest.companion.myself')}
              </CompanionButtonLabel>
            </CompanionButton>
          </MyselfButtonContainer>
        )}

        <CompanionButton
          selected={!isMyselfSelected}
          onClick={selectOther}
          aria-label={i18n.t(
            `accessibility.ariaLabels.guest.companionIsAnother${!isMyselfSelected ? 'Selected' : ''}`
          )}>
          <CompanionButtonLabel selected={!isMyselfSelected}>
            {i18n.t('screens.guest.companion.other')}
          </CompanionButtonLabel>
        </CompanionButton>
      </ButtonAlignContainer>

      {!isMyselfSelected && (
        <>
          {companion && (
            <React.Fragment>
              <TopRowInfos>
                <InfosContainer>
                  <InfoLabel>{i18n.t('screens.guest.visitors.form.lastName')}</InfoLabel>
                  <InfoText>{companion.lastName}</InfoText>
                </InfosContainer>

                <InfosContainer>
                  <InfoLabel>{i18n.t('screens.guest.visitors.form.firstName')}</InfoLabel>
                  <InfoText>{companion.firstName}</InfoText>
                </InfosContainer>
              </TopRowInfos>

              <InfosContainer>
                <InfoLabel>{i18n.t('screens.guest.visitors.form.email')}</InfoLabel>
                <InfoText>{companion.email}</InfoText>
              </InfosContainer>
            </React.Fragment>
          )}
          <ButtonContainer>
            <Button
              label={i18n.t('screens.guest.companion.editCompanion')}
              onClick={openModalSelectCompanion}
              style="secondary"
            />
          </ButtonContainer>
        </>
      )}
    </React.Fragment>
  )
}

// CONTAINERS

const ButtonAlignContainer = styled('div')`
  flex-direction: row;
  margin-bottom: 20px;
`
const CompanionButton = styled('button')<{ selected: boolean }>`
  cursor: pointer;
  border-radius: 7px;
  padding: 10px;
  background-color: ${(props) => (props.selected ? props.theme.colors.secondary : props.theme.colors.background)};
  border: ${(props) =>
    props.selected ? `0px solid ${props.theme.colors.middleGrey}` : `1px solid ${props.theme.colors.middleGrey}`};
`
const MyselfButtonContainer = styled('div')`
  margin-right: 10px;
`
const ButtonContainer = styled('div')`
  width: 327px;
`
const TopRowInfos = styled('div')`
  flex-direction: row;
`
const InfosContainer = styled('div')`
  width: 192px;
  flex-direction: column;
`

// TEXTES

const Title = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px 0px 20px 0px;
`
const CompanionButtonLabel = styled('p')<{ selected: boolean }>`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => (props.selected ? props.theme.colors.background : props.theme.colors.middleGrey)};
  margin: 0px;
`
const InfoLabel = styled('h3')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.secondary};
  margin: 0px 0px 10px 0px;
`
const InfoText = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0px 0px 25px 0px;
`

export default CompanionPicker
