import * as React from 'react'
import ReactDOM from 'react-dom'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import Provider from 'theme/Provider'

import { ALERT_WIDTH } from 'components/alert/Alert'
import Icon from 'components/icons/Icon'

import FocusLock from 'react-focus-lock'
import useEscKeyPressed from 'utils/useEscKeyPressed'
import { breakpoints } from 'utils/breakpoints'

interface Props {
  Content: () => JSX.Element
  justify?: 'flex-start' | 'center' | 'flex-end'
  closeButton?: boolean
}

const Modal = ({ Content, justify = 'center', closeButton = false }: Props) => {
  const [Theme] = useTheme()

  useEscKeyPressed(ModalPortal.close)

  return (
    <MainContainer justify={justify}>
      <Back />
      <JustifiedContainer justify={justify}>
        <FocusLock autoFocus={true} returnFocus>
          {closeButton && (
            <IconContainer onClick={ModalPortal.close}>
              <Icon name="cross" size={24} color={Theme.colors.iconicGrey} cursor="pointer" />
            </IconContainer>
          )}
          <Content />
        </FocusLock>
      </JustifiedContainer>
    </MainContainer>
  )
}

const MainContainer = styled('div')<{ justify: 'flex-start' | 'center' | 'flex-end' }>`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: ${(props) => props.justify};
  opacity: 1;

  -webkit-animation: fadeIn 0.2s linear;
  animation: fadeIn 0.2s linear;
  z-index: 1;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Back = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.backgroundModal};
  border: 0px;
`

const JustifiedContainer = styled('div')<{ justify: 'flex-start' | 'center' | 'flex-end' }>`
  position: relative;
  padding: ${(props) => (props.justify !== 'center' ? 64 : 0)}px 0px;
  border-radius: 12px;
  width: ${ALERT_WIDTH}px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 15px ${(props) => props.theme.colors.primaryDark}21;
  padding: 40px 32px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    width: auto;
    margin: 0px 24px;
  }
`

const IconContainer = styled('button')`
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: ${(props) => props.theme.colors.background};
  align-self: flex-end;
  margin: 0px;
  padding: 0px;
  border: 0px;
  cursor: pointer;
`

let modalRoot: HTMLElement | null

const ModalPortal = {
  open: (props: Props) => {
    modalRoot = document.getElementById('portal_root')

    if (modalRoot) {
      ReactDOM.render(
        <Provider>
          <Modal {...props} />
        </Provider>,
        modalRoot
      )
    }
  },
  close: () => {
    if (modalRoot) {
      ReactDOM.unmountComponentAtNode(modalRoot)
    }
  },
}

export default ModalPortal
