const multiplyFloat = (float1: number, float2: number, precision = 2): number =>
  (float1 * Math.pow(10, precision) * (float2 * Math.pow(10, precision))) / Math.pow(10, precision * 2)

const addFloat = (float1: number, float2: number, precision = 2): number =>
  (float1 * Math.pow(10, precision) + float2 * Math.pow(10, precision)) / Math.pow(10, precision)

const subFloat = (float1: number, float2: number, precision = 2): number =>
  (float1 * Math.pow(10, precision) - float2 * Math.pow(10, precision)) / Math.pow(10, precision)

const divFloat = (float1: number, float2: number, precision = 2): number =>
  (float1 * Math.pow(10, precision)) / (float2 * Math.pow(10, precision))

export { multiplyFloat, addFloat, subFloat, divFloat }
