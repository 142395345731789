import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

import useNavigation from 'core/src/layout/useNavigation'

import Button from 'components/button/Button'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main, { HEADER_HEIGHT } from 'components/main/Main'
import MyInstantsList from './MyInstantsList'
import SectionsList, { Section } from 'components/list/SectionsList'

import { breakpoints } from 'utils/breakpoints'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  sectionIndex?: string
}

const CateringScreen = ({ sectionIndex = '0' }: Props) => {
  const i18n = useI18n()
  const navigation = useNavigation()

  const SECTIONS: Section[] = [
    { label: i18n.t('screens.meeting.catering.list.futureInstants'), id: 'FUTURE_INSTANTS' },
    { label: i18n.t('screens.meeting.catering.list.pastInstants'), id: 'PAST_INSTANTS' },
  ]
  const [showPastInstants, setShowPastInstants] = React.useState(false)
  const [selectedSection, setSelectedSection] = React.useState<Section>(SECTIONS[parseInt(sectionIndex)])

  React.useEffect(() => {
    if (selectedSection.id === 'FUTURE_INSTANTS') {
      setShowPastInstants(false)
    } else {
      setShowPastInstants(true)
    }
  }, [selectedSection])

  React.useEffect(() => {
    analytics.screen({
      screen_feature: values.screens.catering,
      screen_name: values.screens.cateringBooking,
    })
  }, [])

  return (
    <ScreenContainer>
      <TitleHelmet title={i18n.t('screens.meeting.catering.title')} />
      <Main>
        <MainContainer>
          <TitleContainer>
            <Title>{i18n.t('screens.meeting.catering.list.currentTitle')}</Title>
            <ButtonContainer>
              <Button
                label={i18n.t('screens.meeting.catering.title')}
                onClick={() => navigation.push('/meeting/cateringForm')}
              />
            </ButtonContainer>
          </TitleContainer>

          <Separator />

          <SectionContainer>
            <SectionsList sections={SECTIONS} selectedSection={selectedSection} onSectionClick={setSelectedSection} />
          </SectionContainer>

          <MyInstantsList showPastInstants={showPastInstants} />
        </MainContainer>
      </Main>
    </ScreenContainer>
  )
}

export default CateringScreen

const ScreenContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow: auto;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    overflow-x: hidden;
    position: relative;
    width: 100vw;
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 20px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
    align-items: center;
  }
`

const MainContainer = styled.div`
  padding: 50px 0;
  margin-right: 0;
  max-width: 1200px;
  align-self: center;
  width: 100%;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    margin-right: 0px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    margin-right: 0px;
  }
`

const Title = styled.h1`
  ${(props) => props.theme.fonts.h2Bold};
`

const Separator = styled.hr`
  height: 1px;
  background-color: ${(props) => props.theme.colors.lightGrey};
  border: 0;
  width: 100%;
`

const ButtonContainer = styled('div')`
  align-items: center;
`

const SectionContainer = styled('div')`
  margin-top: 20px;
  align-self: center;
`
