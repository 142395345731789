import * as React from 'react'
import styled from 'theme/styled-components'

import { Link } from 'react-router-dom'

import useI18n from 'i18n/useI18n'

interface Props {
  rowOrientation?: boolean
  textColor?: string
}

const FooterLinks = ({ rowOrientation = false, textColor }: Props) => {
  const i18n = useI18n()

  return (
    <>
      <StyledLink
        to={(location) => ({ ...location, pathname: `/${i18n.lang}/cgu` })}
        rowOrientation={rowOrientation}
        textColor={textColor}>
        {i18n.t('screens.cgu.title')}
      </StyledLink>
      {!rowOrientation && <>•</>}
      <StyledLink
        to={(location) => ({ ...location, pathname: `/${i18n.lang}/accessibility` })}
        rowOrientation={rowOrientation}
        textColor={textColor}>
        {i18n.t('screens.accessibility.complianceMention')}
      </StyledLink>
    </>
  )
}

export default FooterLinks

const StyledLink = styled(Link)<{ rowOrientation: boolean; textColor?: string }>`
  margin: ${(props) => (props.rowOrientation ? '5px' : '0px')} 5px;
  ${(props) => props.theme.fonts.link};
  color: ${(props) => (!!props.textColor ? props.textColor : 'inherit')};
`
