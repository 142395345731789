import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import Icon from 'components/icons/Icon'

import Screens from 'core/src/layout/Screens'

import { clearPath } from 'core/src/layout/utils'
import useBreakpoint from 'utils/useBreakpoints'
import { breakpoints } from 'utils/breakpoints'

interface Props {
  children: JSX.Element
  title?: string
  color?: string
}

export const HEADER_HEIGHT = 82

const Main = ({ children }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()
  const [, bp] = useBreakpoint()

  const splitPath = clearPath(window.location.pathname, i18n.lang).split('/')
  const featurePath = `/${splitPath.length > 1 ? splitPath[1] : splitPath[0]}`
  const screen = React.useMemo(
    () => (featurePath !== '/' ? Object.values(Screens).find((s) => s.path === featurePath) : undefined),
    [featurePath]
  )

  return (
    <MainContainer role="main">
      {!!screen && !!screen.feature ? (
        <>
          <ScreenHeader feature={screen.feature}>
            {!!screen && (
              <Icon
                name={screen.altIcon || screen.icon}
                size={bp === 'phone' ? 28 : 42}
                color={Theme.colors.background}
              />
            )}
            <ScreenTitle>{i18n.t(`screens.${screen.feature}.title_alt`)}</ScreenTitle>
          </ScreenHeader>
          {children}
        </>
      ) : (
        children
      )}
    </MainContainer>
  )
}

export default Main

const MainContainer = styled('main')`
  display: flex;
  flex-direction: inherit;
  z-index: inherit;
  padding: inherit;
`

const ScreenHeader = styled('div')<{ feature?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${HEADER_HEIGHT}px;
  padding: 0 70px;
  background-color: ${(props) =>
    !!props.feature
      ? props.theme.colors.functionalities[props.feature]
      : props.theme.colors.background || props.theme.colors.background};
  @media only screen and (max-width: ${breakpoints.phone}px) {
    justify-content: center;
  }
`

const ScreenTitle = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.white};
  font-weight: 900;
  line-height: 29px;
  margin: 0;
  padding: 0 0 0 12px;
  text-transform: uppercase;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    font-size: 20px;
    line-height: 23px;
    padding: 0 0 0 8px;
  }
`
