import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import ContentItem from 'webview/ContentItem'

import Loader from 'components/status/Loader'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main, { HEADER_HEIGHT } from 'components/main/Main'
import SectionsList, { Section } from 'components/list/SectionsList'
import EmptyLabel from 'components/label/EmptyLabel'
import ListItemRework, { MIN_WIDTH, WIDTH_TRESHOLD } from 'components/list/ListItemRework'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SafeAtWorkStore from './store'
import * as SiteStore from 'site/store'

import api from 'practicalinformation/api'

import { breakpoints } from 'utils/breakpoints'

import Logger from 'utils/Logger'
import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

const FILTERS: SafeAtWorkFolder[] = ['safetynews', 'security', 'training']

const SafeAtWorkScreen = () => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const infos = useReducer(SafeAtWorkStore.store, (s) => s.infos)
  const site = useReducer(SiteStore.store, (s) => s.site!)

  const SECTIONS: Section[] = FILTERS.map((filter) => ({
    label: i18n.t(`screens.safeAtWork.filter.${filter}`),
    id: filter,
  }))

  const [selectedSection, setSelectedSection] = React.useState<Section>(SECTIONS[0])
  const [loading, setLoading] = React.useState(false)

  const displayedInfos = React.useMemo(() => infos.filter((n) => n.folder === selectedSection.id), [
    infos,
    selectedSection,
  ])

  React.useEffect(() => {
    analytics.screen({
      screen_feature: values.screens.safeAtWork,
      screen_name: values.screens[selectedSection.id as SafeAtWorkFolder],
    })
  }, [selectedSection])

  React.useEffect(() => {
    setLoading(true)

    api
      .all(site.id, 'SAFE@WORK')
      .then((l) => SafeAtWorkStore.actions.setInfos(l.practicalInformations || []))
      .catch((err) => {
        Logger.error(err)
        SafeAtWorkStore.actions.reset()
      })
      .finally(() => setLoading(false))
  }, [site])

  const renderItem = (item: PracticalInformationSimple) => {
    // Ici, item peut être une page éditoriale interne ou un lien vers une webview
    if (item.type === 'CONTENT') {
      return (
        <ListItemRework
          key={item.id}
          link={`/safeAtWork/${item.id}`}
          imageUrl={item.photo}
          title={item.title}
          description={item.description}
          themeColor={theme.colors.primaryText}
        />
      )
    }
    return (
      <ContentItem
        key={item.id}
        content={item}
        feature="safeAtWork"
        folder={item.type as SafeAtWorkFolder}
        reworkItem
      />
    )
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={i18n.t('screens.safeAtWork.title')} />

        <Header>
          <SectionsList sections={SECTIONS} selectedSection={selectedSection} onSectionClick={setSelectedSection} />
        </Header>

        {displayedInfos.length === 0 ? (
          <EmptyLabel label={i18n.t('screens.safeAtWork.empty')} icon="safe" />
        ) : (
          <ListContainer>
            <StyledList>{displayedInfos.map(renderItem)}</StyledList>
          </ListContainer>
        )}
      </ScreenContainer>
    </Main>
  )
}

export default SafeAtWorkScreen

const ScreenContainer = styled('div')`
  display: flex;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
`

const StyledList = styled('ul')`
  display: grid;
  list-style: none;
  gap: 64px;
  padding: 0;
  margin: 0;
  grid-template-columns: repeat(auto-fit, minmax(${MIN_WIDTH}px, 1fr));

  @media only screen and (min-width: ${WIDTH_TRESHOLD}px) {
    grid-template-columns: repeat(3, minmax(${MIN_WIDTH}px, 1fr));
  }

  @media only screen and (max-width: ${breakpoints.small}px) {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }
`

const ListContainer = styled('div')`
  flex: 1;
  padding: 40px 124px;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 35px 90px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 35px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 35px 35px;
  }
`

const Header = styled('div')`
  padding-top: 52px;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 50px 90px 25px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px 25px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px 25px;
  }
`
