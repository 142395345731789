import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Loader from 'components/status/Loader'
import Tree from 'components/button/Tree'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'

import { breakpoints } from 'utils/breakpoints'

import useI18n from 'i18n/useI18n'

import api from './api'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  id: string
}

const IMAGE_CONTAINER_SIZE = 92
const IMAGE_SIZE = 52

const EditoDetailScreen = ({ id }: Props) => {
  const [theme] = useTheme()

  const i18n = useI18n()
  const [edito, setEdito] = React.useState<PracticalInformationDetails>()
  const [loading, setLoading] = React.useState(false)

  const photos = React.useMemo(
    () =>
      edito?.photos
        ?.filter((p) => !!p.url)
        .sort((a, b) => a.position - b.position)
        .map((p) => ({ key: p.url, url: p.url, description: p.photoDescription })),
    [edito]
  )

  const fetch = () => {
    api
      .get(id, 'CONTENT')
      .then(setEdito)
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    analytics.screen({
      screen_feature: values.screens.infos,
      screen_name: values.screens.infoDetail,
      screen_object_id: id,
    })

    setLoading(true)
    fetch()
  }, [])

  if (loading) {
    return <Loader />
  }

  if (!edito) {
    return null
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={`${i18n.t('screens.practicalinformation.titleDetailPage')}${edito.title}`} />

        <Tree
          previousPages={[{ url: 'practicalinformation', title: 'screens.practicalinformation.title' }]}
          currentPageTitle={edito.title}
        />

        <ContentContainer>
          <HeaderContainer>
            <ImageContainer>
              <ItemImage src={!!photos ? photos[0].url : require('core/src/assets/news_default.jpeg').default} />
            </ImageContainer>
            <ItemInfos>
              <ItemTitle>{edito.title}</ItemTitle>
            </ItemInfos>
          </HeaderContainer>

          <Content
            dangerouslySetInnerHTML={{
              __html: edito.description
                .replace(/href/g, "target='_blank' href")
                .replace(/<a/g, `<a style="color: ${theme.colors.secondary}; text-decoration: none"`),
            }}
          />
        </ContentContainer>
      </ScreenContainer>
    </Main>
  )
}

export default EditoDetailScreen

const ScreenContainer = styled('div')`
  display: flex;
  padding: 44px 80px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 44px 55px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 44px 35px;
  }
`

const ContentContainer = styled('div')`
  padding: 40px 200px;
  display: flex;
  flex: 1;
  justify-content: center;
  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 20px 0px;
    width: 100%;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 20px 0px;
    width: 100%;
  }
`

const Content = styled('p')`
  ${(props) => props.theme.fonts.h3};
  padding-top: 24px;
`

const ImageContainer = styled('div')`
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: ${IMAGE_CONTAINER_SIZE}px;
  width: ${IMAGE_CONTAINER_SIZE}px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.functionalities.practicalinformation}0D;
`

const ItemImage = styled('img')`
  object-fit: cover;
  user-select: none;
  aspect-ratio: 4/3;
  height: ${IMAGE_SIZE}px;
  width: ${IMAGE_SIZE}px;
  border-radius: 12px;
`

const ItemInfos = styled('div')`
  padding: 10px 24px;
  flex: 1;
  flex-direction: column;
`

const ItemTitle = styled('h2')`
  ${(props) => props.theme.fonts.h1Bold};
  margin: 0px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-wrap: break-word;
`

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
`
