import { DirectoryUser } from './types'
import sanitize from 'utils/Sanitizer'

const REGEX_ONLY_LETTERS = new RegExp(/^[a-zA-Z]/)

const specialCharLastSort = (a: string, b: string) => {
  if (!REGEX_ONLY_LETTERS.test(a)) {
    return 1
  }
  if (!REGEX_ONLY_LETTERS.test(b)) {
    return -1
  }
  return a.localeCompare(b)
}

const sortUsersByDisplayName = (users: DirectoryUser[]) =>
  users.sort((a, b) =>
    !!a.displayName && !!b.displayName && a.displayName !== b.displayName
      ? specialCharLastSort(a.displayName, b.displayName)
      : a.id.localeCompare(b.id)
  )

const getSanitizedSection = (user: DirectoryUser) =>
  sanitize(
    !!user.displayName ? user.displayName : !!user.surname ? user.surname : !!user.givenName ? user.givenName : '['
  )

const getSections = (array: DirectoryUser[]) =>
  Array.from(
    new Set(
      array.map((u) =>
        sanitize(!!u.displayName ? u.displayName[0] : !!u.surname ? u.surname[0] : !!u.givenName ? u.givenName[0] : '[')
      )
    )
  )
    .map((section) => (REGEX_ONLY_LETTERS.test(section) ? section : '#'))
    .sort(specialCharLastSort)

const getNameToDisplay = (user: DirectoryUser) => {
  if (!!user.displayName) {
    return user.displayName
  } else {
    const surname = user.surname || ''
    const givenName = user.givenName || ''
    const fullName = `${surname} ${givenName}`
    return fullName.trim()
  }
}

export { sortUsersByDisplayName, getSections, getSanitizedSection, getNameToDisplay }
