import { createEvent, createStore } from 'effector'

export const actions = {
  setInfos: createEvent<PracticalInformationSimple[]>('setSafeAtWorkInfos'),
  reset: createEvent('resetSafeAtWorkInfos'),
}

const initialState: PracticalInformationState = {
  infos: [],
}

export const store = createStore<PracticalInformationState>(initialState, { name: 'safeAtWork' })
  .on(actions.setInfos, (s, infos) => ({ ...s, infos }))
  .on(actions.reset, () => ({ ...initialState }))
