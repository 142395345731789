import { createEvent, createStore } from 'effector'
import { DirectoryState } from './types'

export const actions = {
  setFav: createEvent<string[]>('setDirectoryFavs'),
  resetFav: createEvent('resetDirectoryFavs'),
}

const initialState: DirectoryState = {
  favorites: ['6a365f11-fccf-4d2c-b8d2-42d9ebe5afc4', 'fc874bb9-db5b-417c-a44f-b46cf097568a'],
}

export const store = createStore<DirectoryState>(initialState, { name: 'directory' })
  .on(actions.setFav, (s, favorites) => ({ ...s, favorites }))
  .on(actions.resetFav, () => initialState)
