import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Loader from 'components/status/Loader'
import ListItemRework, { MIN_WIDTH, WIDTH_TRESHOLD } from 'components/list/ListItemRework'
import EmptyLabel from 'components/label/EmptyLabel'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as RegisterStore from './store'

import config from 'core/src/config'

import { getDateString } from './utils'
import { breakpoints } from 'utils/breakpoints'
import { addDays, isBefore, isAfter } from 'date-fns'

interface Props {
  selectedWeek: Date
  screenStatus: ScreenStatus
}

const RegisterList = ({ selectedWeek, screenStatus }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const animations = useReducer(RegisterStore.store, (s) => s.animations)

  const [status, setStatus] = React.useState<ScreenStatus>(screenStatus)

  const filteredAnimations = React.useMemo(
    () =>
      animations
        .filter(
          (anim) =>
            isBefore(new Date(anim.publicationDate), addDays(selectedWeek, 6)) &&
            isAfter(new Date(anim.slotLastDate), selectedWeek)
        )
        .sort((a, b) => a.slotFirstDate.localeCompare(b.slotFirstDate)),
    [animations, selectedWeek]
  )

  React.useEffect(() => {
    setStatus(screenStatus)
  }, [screenStatus])

  const renderItem = (item: AnimationSimple) => (
    <ListItemRework
      key={item.id}
      link={`/register/${item.id}`}
      ariaLabel={i18n.t('accessibility.ariaLabels.register.goToAnimation', { title: item.name })}
      imageUrl={config.SERVER_PREFIX + item.imageUrl}
      title={item.name}
      description={item.description}
      tag={i18n.t('screens.register.tag', {
        date: getDateString(i18n, item.slotFirstDate, item.slotLastDate),
        place: item.place,
      })}
      themeColor={theme.colors.functionalities.register}
    />
  )

  if (status === 'loading') {
    return <Loader />
  }

  if (status === 'error') {
    return <EmptyLabel label={i18n.t('screens.register.error')} />
  }

  if (!filteredAnimations || filteredAnimations.length === 0) {
    return <EmptyLabel label={i18n.t('screens.register.empty')} icon="register" />
  }

  return <StyledList>{filteredAnimations.map(renderItem)}</StyledList>
}

export default RegisterList

const StyledList = styled('ul')`
  display: grid;
  list-style: none;
  gap: 40px;
  padding: 0;
  margin: 0;
  grid-template-columns: repeat(auto-fit, minmax(${MIN_WIDTH}px, 1fr));

  @media only screen and (min-width: ${WIDTH_TRESHOLD}px) {
    grid-template-columns: repeat(3, minmax(${MIN_WIDTH}px, 1fr));
  }

  @media only screen and (max-width: ${breakpoints.small}px) {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }
`
