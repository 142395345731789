import { OFFICE_MAP } from 'site/utils'

import { differenceInMinutes, roundToNearestMinutes, addMinutes, isBefore, isWeekend, addDays } from 'date-fns'

export const userIsResident = (officeLocation: string, codeSite: string) => {
  const office = OFFICE_MAP[codeSite]
  return !!office && officeLocation.includes(office)
}

export const roundToUpperQuarter = () => {
  const roundTime = roundToNearestMinutes(new Date(), { nearestTo: 15 })
  if (isBefore(roundTime, new Date())) {
    // on arrondi au quart d'heure suivant et non au précédent
    const diff = differenceInMinutes(new Date(), roundTime)
    const minutesToAdd = 15 - diff
    const newStart = addMinutes(new Date(), minutesToAdd)
    return newStart
  } else {
    return roundTime
  }
}

/*
TODO MBA
const orderMeetingsByDate = (meetings: MeetingGraph[]) =>
  meetings
    .reduce((acc, cur) => {
      const foundIndex = acc.findIndex((d) => isSameDay(new Date(d.date), new Date(cur.start.dateTime)))
      if (foundIndex > -1) {
        acc[foundIndex].meetings.push(cur)
      } else {
        acc.push({ date: cur.start.dateTime, meetings: [cur] })
      }
      return acc
    }, [] as MeetingGraphByDate[])
    .sort((a, b) => {
      a.meetings.sort((c, d) => (isBefore(new Date(c.start.dateTime), new Date(d.start.dateTime)) ? -1 : 1))
      b.meetings.sort((c, d) => (isBefore(new Date(c.start.dateTime), new Date(d.start.dateTime)) ? -1 : 1))
      return isBefore(new Date(a.date), new Date(b.date)) ? -1 : 1
    })

export { userIsResident, roundToUpperQuarter, orderMeetingsByDate }
*/

export const notWeekend = (d: Date) => {
  while (isWeekend(d)) {
    d = addDays(d, 1)
  }
  return d
}
