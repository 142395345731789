import { createEvent, createStore } from 'effector'

export const actions = {
  setShortcuts: createEvent<string[]>('setShortcuts'),
  reset: createEvent('reset'),
}

const initialState: HomeState = {
  shortcuts: ['restaurant', 'booking', 'phonebook'],
}

export const store = createStore<HomeState>(initialState, { name: 'home' })
  .on(actions.setShortcuts, (s, shortcuts) => ({ ...s, shortcuts }))
  .reset(actions.reset)
