import * as React from 'react'

const useEscKeyPressed = (onEscPressed: () => void) => {
  const escKeyPressed = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onEscPressed()
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', escKeyPressed)

    return () => document.removeEventListener('keydown', escKeyPressed)
  }, [onEscPressed])
}

export default useEscKeyPressed
