import * as React from 'react'
import styled from 'theme/styled-components'

import Button from 'components/button/Button'

import useI18n from 'i18n/useI18n'
import { I18n } from 'i18n/i18n'

import api from './api'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import { breakpoints } from 'utils/breakpoints'
import Logger from 'utils/Logger'

interface Props {
  setEmailData: (emailData?: AnimationEmailUser) => void
  animationId: string
}

const validationSchema = (i18n: I18n) =>
  Yup.object().shape({
    email: Yup.string().email(i18n.t('errors.form.format')),
  })

const RegisterEmailInput = ({ setEmailData, animationId }: Props) => {
  const i18n = useI18n()

  const [status, setStatus] = React.useState<'editing' | 'loading' | 'uneditable'>('editing')

  const onSubmit = () => {
    setStatus('loading')
    api
      .getUserInfo(animationId, values.email)
      .then((data) => setEmailData({ email: values.email, data }))
      .catch((err) => {
        Logger.error(err)
        setEmailData({ email: values.email })
      })
      .finally(() => setStatus('uneditable'))
  }

  const { handleSubmit, setValues, values, isValid } = useFormik({
    initialValues: { email: '' },
    onSubmit,
    validateOnChange: true,
    validationSchema: validationSchema(i18n),
  })

  return (
    <>
      <EmailTitle>{i18n.t('screens.register.detail.collaborator.emailTitle')}</EmailTitle>
      <EmailContainer>
        {status !== 'uneditable' ? (
          <EmailInput
            id="collaboratorEmailInput"
            value={values.email}
            onChange={(evt) => setValues({ email: evt.target.value })}
            placeholder={i18n.t('screens.register.detail.collaborator.emailPlaceholder')}
            title={i18n.t('accessibility.titleInputText.searchBar')}
            type="text"
          />
        ) : (
          <EmailText>{values.email}</EmailText>
        )}
        {status !== 'uneditable' ? (
          <Button
            type="submit"
            isLoading={status === 'loading'}
            disabled={!values.email || !isValid || status === 'loading'}
            label={i18n.t('screens.register.detail.collaborator.verify')}
            onClick={handleSubmit}
            width={160}
          />
        ) : (
          <Button
            style="secondary"
            label={i18n.t('common.modify')}
            onClick={() => {
              setStatus('editing')
              setEmailData(undefined)
            }}
            width={100}
          />
        )}
      </EmailContainer>
    </>
  )
}

export default RegisterEmailInput

const EmailTitle = styled('p')`
  margin: 44px 0 8px;
  ${(props) => props.theme.fonts.subtitle};
`

const EmailContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const EmailInput = styled('input')`
  display: flex;
  flex: 1;
  outline: 0;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.middleGrey};
  background-color: ${(props) => props.theme.colors.background};
  ${(props) => props.theme.fonts.subtitleBold};

  ::placeholder {
    ${(props) => props.theme.fonts.subtitle};
    color: ${(props) => props.theme.colors.iconicGrey};
  }

  @media only screen and (max-width: ${breakpoints.small}px) {
    width: calc(100% - 26px); // 26 = padding horizontal + largeur bordure
  }
`

const EmailText = styled('p')`
  display: flex;
  flex: 1;
  padding: 13px 0;
  ${(props) => props.theme.fonts.subtitleBold};
`
