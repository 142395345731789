import * as React from 'react'
import styled from 'theme/styled-components'

import Button from 'components/button/Button'

import useEscKeyPressed from 'utils/useEscKeyPressed'

import useI18n from 'i18n/useI18n'

interface Props {
  news: ArticleDetailV4
  onClose: () => void
}

const Share = ({ news, onClose }: Props) => {
  const i18n = useI18n()

  useEscKeyPressed(onClose)

  const onCopy = () => {
    navigator.clipboard.writeText(window.location.origin + '/fonctionnalite?page=actuDetail&articleId=' + news.id)
    onClose()
  }

  return (
    <Container id="ShareDialog" role="dialog" aria-modal="true" aria-labelledby="modalShareNewsHeading" tabIndex={-1}>
      <Title id="modalShareNewsHeading">{i18n.t('screens.news.share.title')}</Title>

      <Content>
        {i18n.t('screens.news.share.article', {
          name: news.title,
          link: `${window.location.origin}/fonctionnalite?page=actuDetail&articleId=${news.id}`,
        })}
      </Content>

      <ButtonContainer>
        <Button label={i18n.t('screens.news.share.copy')} onClick={onCopy} />
      </ButtonContainer>
    </Container>
  )
}

const Container = styled('div')`
  background-color: ${(props) => props.theme.colors.background};
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  font-weight: 700;
  margin: 0px 0px 20px;
`

const Content = styled('p')`
  ${(props) => props.theme.fonts.body};
  padding: 15px;
  border: 1px solid ${(props) => props.theme.colors.middleGrey};
  border-radius: 2px;
  -webkit-user-select: text; /* Safari */
  -ms-user-select: text; /* IE 10 and IE 11 */
  user-select: text; /* Standard syntax */
`

const ButtonContainer = styled('div')`
  margin-top: 24px;
  align-items: center;
`

export default Share
