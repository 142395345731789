import { FormValues } from './CateringForm'
import { isAfter, setMinutes, setHours, subBusinessDays, format } from 'date-fns'
import { addFloat, multiplyFloat } from 'utils/math'

const MAX_HOUR = 14
const MAX_MINUTES = 0

const formatDateString = (date: Date) => date.toLocaleDateString().replaceAll('/', '-') // dd-MM-YYYY

const formatTimeString = (date: Date) => date.toLocaleTimeString().slice(0, 5)

const formatDate = (date: string, time: string) => new Date(`${date}T${time}`)

const formatInstantRequest = (
  siteId: number,
  detail: InstantDetailed,
  values: Partial<FormValues>
): InstantToCreate => {
  const {
    startingTime,
    endingTime,
    instantType,
    cateringPlace,
    numberGuests,
    comment,
    costCenter,
    costCenterId,
    additionalArticles,
  } = values

  return {
    siteId: siteId.toString(),
    date: startingTime ? formatDateString(startingTime) : detail.date,
    startingTime: startingTime ? formatTimeString(startingTime) : detail.startingTime,
    endingTime: endingTime ? formatTimeString(endingTime) : detail.endingTime,
    instantTypeId: instantType?.id || '',
    referentielReference: cateringPlace?.referentielReference || '',
    numberGuests: numberGuests || detail.numberGuests,
    comment,
    costCenterId: costCenter?.id || costCenterId || detail.costCenter?.id || detail.customCostCenter || '',
    additionalArticles,
  }
}

const getHourFromString = (time: string) => {
  if (time.length > 2) {
    return parseInt(time.slice(0, 2))
  }
  return 0
}

const getArticlesPrice = (foods: Food[], articles?: (FoodSimplified | FoodWanted)[]) => {
  if (foods.length > 0 && !!articles && articles.length > 0) {
    return articles
      .map((article) => ({
        quantity: article.quantity,
        price: foods.find((food) => food.id === article.id)?.price || 0,
      }))
      .reduce((acc, cur) => addFloat(acc, multiplyFloat(cur.quantity, cur.price)), 0)
  }
  return 0
}

const getDateFromHour = (time: string, date?: Date) => {
  if (time.length > 4) {
    return setMinutes(setHours(date || new Date(), getHourFromString(time)), parseInt(time.slice(3, 5), 0))
  }
}

const isInstantTooLate = (date: Date, timeAheadDay: number) => {
  if (!!timeAheadDay) {
    return isAfter(new Date(), subBusinessDays(setMinutes(setHours(date, MAX_HOUR), MAX_MINUTES), timeAheadDay))
  }
  return false
}

const isInvalidPeriod = (start: Date, instantType: InstantType) => {
  const allowedStart = getDateFromHour(instantType.allowedStartingTime, start)!
  const allowedEnd = getDateFromHour(instantType.allowedEndingTime, start)!
  return isAfter(allowedStart, start) || isAfter(start, allowedEnd)
}

const isInactivePeriod = (date: Date, inactivityPeriods: InactivityPeriod[]) => {
  const dateString = format(date, 'yyyy-MM-dd')
  const inactivePeriod = inactivityPeriods.find(
    (p) =>
      p.startDate.slice(0, 10).localeCompare(dateString) < 1 && p.endDate.slice(0, 10).localeCompare(dateString) > -1
  )
  return inactivePeriod
}

export {
  formatDateString,
  formatTimeString,
  formatDate,
  formatInstantRequest,
  getArticlesPrice,
  getHourFromString,
  getDateFromHour,
  isInstantTooLate,
  isInvalidPeriod,
  isInactivePeriod,
}
