import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'
import Alert from 'components/alert/Alert'
import SwitchSiteButton from './SwitchSiteButton'

import Screens from './Screens'

import { Link } from 'react-router-dom'
import FocusLock from 'react-focus-lock'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'
import * as ThemeStore from 'theme/store'

import useBreakpoints from 'utils/useBreakpoints'
import { breakpoints } from 'utils/breakpoints'
import { clearPath, getFilteredFunctionalities } from './utils'
import auth from 'core/src/utils/auth'

import useNavigation from './useNavigation'

import { drawer, mapping } from './common'

interface Props {
  children: JSX.Element
  hidden?: boolean
}

type DrawerState = 'OPENED' | 'HOVERED' | 'CLOSED'

const WEBVIEW_ICONS: { [key: string]: string } = {
  mobility: 'transport',
  practicallife: 'locker',
  service: 'service',
}

const ICON_SIZE = 18
export const OPENED_WIDTH = 280
export const CLOSED_WIDTH = 98

const Drawer = ({ children, hidden }: Props) => {
  const i18n = useI18n()
  const navigation = useNavigation()
  const [size, bp] = useBreakpoints()
  const [Theme, name] = useTheme()

  const site = useReducer(SiteStore.store, (s) => s.site)
  const drawerBehavior = useReducer(ThemeStore.store, (s) => s.drawer)

  const [location, setLocation] = React.useState(clearPath(window.location.pathname, i18n.lang))
  const [drawerState, setDrawerState] = React.useState<DrawerState>('OPENED')
  const [focusedItem, setFocusedItem] = React.useState<string>()

  const opened = React.useMemo(() => drawerState !== 'CLOSED', [drawerState])

  const functionalities = React.useMemo(
    () =>
      !!site
        ? (drawer
            .map((item) => site && site.functionalities.find((f) => f.mobileLibelle === item))
            .filter(Boolean) as FunctionalityV3[])
        : [],
    [site]
  )

  const fncs: FunctionalityV3[] = React.useMemo(
    () => [
      { activated: true, mobileLibelle: 'home', type: 'HOME' },
      ...getFilteredFunctionalities(functionalities),
      ...functionalities.filter((f) => f.activated).filter((f) => f.type === 'WEBPAGE'),
    ],

    [functionalities]
  )

  const isPhoneSize = bp === 'phone'
  const isMediumSize = size.width < breakpoints.medium

  const close = () => setDrawerState('CLOSED')

  const onHover = (hovered: boolean) => {
    // Si le menu est ouvert de base, on ne change pas son état
    // Sinon, on l'ouvre temporairement au survol de la souris
    if (hovered && drawerState === 'CLOSED') {
      setDrawerState('HOVERED')
    } else if (!hovered && drawerState === 'HOVERED') {
      close()
    }
  }

  const toggleDrawer = () => {
    if (isPhoneSize) {
      setDrawerState(drawerState === 'OPENED' ? 'CLOSED' : 'OPENED')
    } else {
      ThemeStore.actions.setDrawer(drawerState === 'OPENED' ? 'close' : 'open')
      if (drawerState === 'OPENED') {
        close()
      }
    }
  }

  React.useEffect(() => {
    return navigation.addListener((loc: HistoryLocation) => setLocation(clearPath(loc.pathname, i18n.lang)))
  }, [navigation, i18n.lang])

  React.useEffect(() => {
    if (!isMediumSize) {
      if (drawerBehavior === 'default') {
        // Par défaut, on ouvre le menu sur la Home, et on le ferme sur les autres pages
        const isHome = location === '' || location === '/'
        setDrawerState(isHome ? 'OPENED' : 'CLOSED')
      } else {
        setDrawerState(drawerBehavior === 'open' ? 'OPENED' : 'CLOSED')
      }
    }
  }, [isMediumSize, drawerBehavior, location])

  React.useEffect(() => {
    if (isMediumSize) {
      close()
    } else if (drawerState === 'CLOSED') {
      setDrawerState('OPENED')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMediumSize])

  const logout = () => {
    Alert.open({
      title: i18n.t('common.logout'),
      description: i18n.t('components.drawer.logout'),
      buttons: [
        { label: i18n.t('common.cancel'), onClick: Alert.close, style: 'secondary' },
        {
          label: i18n.t('common.logout'),
          onClick: () => {
            auth.logout()
            Alert.close()
          },
        },
      ],
    })
  }

  const renderWebFeature = (item: FunctionalityV3) => {
    if (!!item.mobileLibelle) {
      const path = `/content/${item.mobileLibelle}`
      const active = (location || '/') === path
      const focused = active || focusedItem === item.mobileLibelle

      return (
        <Item
          key={item.mobileLibelle}
          screenName={item.mobileLibelle}
          active={active}
          opened={opened}
          onMouseEnter={() => setFocusedItem(item.mobileLibelle)}
          onMouseLeave={() => setFocusedItem(undefined)}>
          <StyledLink to={path}>
            <IconContainer color={active ? Theme.colors.functionalities[item.mobileLibelle] : 'transparent'}>
              <Icon
                name={WEBVIEW_ICONS[item.mobileLibelle] as any}
                size={ICON_SIZE}
                color={focused ? Theme.colors.white : Theme.colors.primaryDark}
              />
            </IconContainer>
            <ItemText opened={opened}>{i18n.t(`screens.${item.mobileLibelle}.title`)}</ItemText>
          </StyledLink>
        </Item>
      )
    }
  }

  const renderFeature = (item: FunctionalityV3) => {
    if (!!item.mobileLibelle) {
      const s = Object.keys(mapping).find((k) => mapping[k as keyof typeof mapping] === item.type)
      const scr = s && Screens[s as ScreenName]

      if (!scr) {
        return null
      }

      if (item.type === 'WEBPAGE') {
        return renderWebFeature(item)
      }

      const active = scr.path === '/' ? (location || '/') === scr.path : location.startsWith(scr.path)
      const focused = active || focusedItem === item.mobileLibelle

      return (
        <Item
          key={item.mobileLibelle}
          screenName={item.mobileLibelle}
          active={active}
          opened={opened}
          onMouseEnter={() => setFocusedItem(item.mobileLibelle)}
          onMouseLeave={() => setFocusedItem(undefined)}>
          <StyledLink to={scr.path}>
            <IconContainer color={active ? Theme.colors.functionalities[item.mobileLibelle] : 'transparent'}>
              <Icon
                name={
                  (name !== 'accessible' && site && site.alternativeFunctionalityDesign && scr.altIcon) ||
                  scr.icon ||
                  'home'
                }
                size={ICON_SIZE}
                color={focused ? Theme.colors.white : Theme.colors.primaryDark}
              />
            </IconContainer>
            <ItemText opened={opened}>
              {i18n.t(
                `screens.${item.mobileLibelle}.title${site && site.alternativeFunctionalityDesign ? '_alt' : ''}`
              )}
            </ItemText>
          </StyledLink>
        </Item>
      )
    }
  }

  return (
    <MainContainer>
      {!hidden && (
        <>
          {isPhoneSize && (
            <BurgerButton
              onClick={toggleDrawer}
              aria-label={i18n.t('accessibility.ariaLabels.drawer.open')}
              disabled={opened}>
              <Icon name="burger_menu" size={25} color={Theme.colors.primaryDark} cursor="pointer" />
            </BurgerButton>
          )}
          <DrawerContainer
            onMouseOver={() => onHover(true)}
            onMouseLeave={() => onHover(false)}
            height={size.height}
            opened={opened}
            tabIndex={-1}>
            <BackImage src={require('assets/back_login.jpg').default} height={size.height} opened={opened} />

            {opened && (
              <ToggleButton onClick={toggleDrawer} aria-label={i18n.t('accessibility.ariaLabels.drawer.close')}>
                <Icon
                  name={`double_chevron_${drawerState === 'OPENED' ? 'left' : 'right'}`}
                  size={24}
                  color={Theme.colors.noInfo}
                  cursor="pointer"
                />
              </ToggleButton>
            )}

            <FocusLockContainer autoFocus={false} returnFocus disabled={!isPhoneSize}>
              <Header>
                <Logo
                  onClick={() => navigation.push('/')}
                  opened={opened}
                  src={require('core/src/assets/logo_short.png').default}
                  alt=""
                />
              </Header>

              <SwitchSiteButton opened={drawerState !== 'CLOSED'} />

              <nav role="navigation" aria-label={i18n.t('accessibility.ariaLabels.navigation.primaryDrawer')}>
                <List opened={opened}>{fncs.map(renderFeature)}</List>
              </nav>

              {opened && (
                <LinksContainer>
                  <LinkLabel onClick={() => navigation.push('/cgu')}>{i18n.t('components.drawer.cgu')}</LinkLabel>
                  <LinkLabel onClick={logout}>{i18n.t('common.logout')}</LinkLabel>
                </LinksContainer>
              )}
            </FocusLockContainer>
          </DrawerContainer>
        </>
      )}

      <ScreenContainer
        hidden={hidden}
        drawerState={drawerState}
        onClick={isPhoneSize ? () => close() : undefined}
        tabIndex={-1}
        id="screenContainer">
        <ScreenContent hidden={hidden}>{children}</ScreenContent>
      </ScreenContainer>
    </MainContainer>
  )
}

export default Drawer

const FocusLockContainer = styled(FocusLock)`
  display: flex;
  flex: 1;
`

const MainContainer = styled('div')`
  flex: 1;
  flex-direction: row;
`

const Header = styled('header')`
  display: flex;
  justify-content: center;
`

const ScreenContainer = styled('div')<{ hidden?: boolean; drawerState: DrawerState }>`
  flex-direction: column;
  flex: 1;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: ${(props) => (props.hidden ? 0 : props.drawerState !== 'OPENED' ? CLOSED_WIDTH : OPENED_WIDTH)}px;
  transition: padding-left 0.25s ease-in-out;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    padding-left: 0;
  }
`

const ScreenContent = styled('div')<{ hidden?: boolean }>`
  display: inherit;
  flex-direction: inherit;

  min-height: min-content;
  z-index: -1;
`

const DrawerContainer = styled('div')<{ height: number; opened: boolean }>`
  position: absolute;
  height: ${(props) => props.height}px;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background};
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: ${(props) => (props.opened ? 'auto' : 'hidden')};

  box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.1);
  z-index: 3;

  width: ${(props) => (props.opened ? OPENED_WIDTH : CLOSED_WIDTH)}px;
  transition: width 0.25s ease-in-out;

  @media only screen and (min-width: ${breakpoints.phone}px) {
    :hover ~ #overlay {
      opacity: 0.6;
    }
  }

  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: ${OPENED_WIDTH}px;
    position: absolute;
    top: 0;
    left: ${(props) => (props.opened ? 0 : -OPENED_WIDTH)}px;
    transition: left 0.25s ease-in-out;
    background-color: ${(props) => props.theme.colors.background};
    z-index: 2;

    display: ${(props) => (props.opened ? 'auto' : 'none')};
    visibility: ${(props) => (props.opened ? 'visible' : 'hidden')};
    ~ #overlay {
      opacity: ${(props) => (props.opened ? 0.6 : 0)};
    }
  }
`

const BackImage = styled('img')<{ height: number; opened: boolean }>`
  position: absolute;
  object-fit: cover;
  height: 100vh;
  width: ${(props) => (props.opened ? OPENED_WIDTH : CLOSED_WIDTH)}px;
  transition: width 0.25s ease-in-out;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: ${OPENED_WIDTH}px;
  }
`

const Logo = styled('img')<{ opened: boolean }>`
  height: 20px;
  width: 113px;
  margin: 70px 0px;
  align-self: center;
  opacity: ${(props) => (props.opened ? 1 : 0)};
  transition: opacity 0.25s ease-in-out;
  transition: transform 0.1s ease-in-out;
  cursor: pointer;

  :hover {
    transform: scale(1.05);
  }
`

const BurgerButton = styled('button')`
  display: flex;
  height: ${(props) => props.theme.constants.menuButtonSize}px;
  width: ${(props) => props.theme.constants.menuButtonSize}px;
  position: absolute;
  top: 22px;
  left: 16px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: rgba(248, 248, 248, 0.8);
  border: 0px;
  border-radius: 12px;
  z-index: 2;
`

const List = styled('ul')<{ opened: boolean }>`
  list-style: none;
  margin: 24px 0 24px 20px;
  padding: 0;
  transform: translate(${(props) => (props.opened ? 0 : 7)}px, 0px);
  transition: transform 0.25s ease-in-out;
`

const IconContainer = styled('div')<{ color: string }>`
  border-radius: 6px;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  transition: background-color 0.25s ease-in-out;
`

const Item = styled('li')<{ screenName: string; active: boolean; opened: boolean }>`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  max-width: ${(props) => (props.opened ? OPENED_WIDTH : 44)}px;
  border-radius: ${(props) => (props.opened ? '12px 0px 0px 12px' : '12px')};
  ${(props) => props.active && `background-color: ${props.theme.colors.lightFunctionalities[props.screenName]}`};
  transition: background-color 0.25s ease-in-out, max-width 0.25s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.lightFunctionalities[props.screenName]};
    transition: background-color 0s ease-in-out;
  }

  :hover ${IconContainer} {
    background-color: ${(props) => props.theme.colors.functionalities[props.screenName]};
  }
`

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  padding: 10px;
  width: 100%;
  min-width: 200px;
`

const ItemText = styled('p')<{ opened: boolean }>`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.primaryDark};
  text-transform: uppercase;

  margin: 0px 0px 0px 15px;
  align-self: center;

  opacity: ${(props) => (props.opened ? 1 : 0)};
  transition: opacity 0.25s ease-in-out;
`

const ToggleButton = styled('button')`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 8px;
  z-index: 5;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0px;
`

// CGU

const LinksContainer = styled('div')`
  padding-bottom: 24px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

const LinkLabel = styled('p')`
  ${(props) => props.theme.fonts.link};
  color: ${(props) => props.theme.colors.iconicGrey};
  margin: 0;
  padding: 2px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;

  :hover {
    transform: scale(1.02);
  }
`
